import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getProductsOverview,
  redirectToLogin,
  redirectToRegister,
} from "@services";
import PageContent from "@common/PageContent/Index";

const LandingPage = () => {
  const thisPageContent = PageContent().Home.prePassword;

  const { languageCode } = useSelector((state) => state.languageData);
  const [productData, setProductData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProductsOverview(languageCode);
      setProductData(data);
    };
    fetchData();
  }, [languageCode]);

  return (
    <>
      <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-push-5 col-lg-7 col-sm-6 col-sm-push-6">
              <h1 className="text-center">{thisPageContent.heroHeaderText}</h1>
              <h4 className="text-center">{thisPageContent.heroBlockText}</h4>
              <div className="text-center">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    redirectToLogin();
                  }}
                  className="btn btn-lg btn-primary"
                >
                  {thisPageContent.loginBtnTxt}
                </a>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    redirectToRegister();
                  }}
                  className="btn btn-lg btn-info"
                >
                  {thisPageContent.createAcctTxt}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {productData && (
        <div className="container mystuff-nav fade-block fade-in">
          <div className="row">
            <div className="col-xs-push-2 col-xs-8">
              <h2 className="text-center">{productData.title}</h2>
              <h4 className="text-center">{productData.description}</h4>
            </div>
          </div>
          <div className="row" style={{ marginTop: 30 }}>
            <div className="col-lg-2 hidden-md hidden-sm hidden-xs"></div>
            <div className="col-lg-8">
              <div className="tiles row">
                <div className="col-sm-4 col-xs-6 fade-block fade-in">
                  <Link to="/morestuff/snap">
                    <div className="tile snap tileBG">
                      <div className="title">
                        <h4>TD Snap</h4>
                      </div>
                      <div className="fade-block fade-in">
                        <div className="td-icon img-circle-70 pdd-avatar hidden-sm snap-image"></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4 col-xs-6 fade-block fade-in">
                  <Link to="/morestuff/communicator">
                    <div className="tile communicator tileBG">
                      <div className="title">
                        <h4>Communicator</h4>
                      </div>
                      <div className="fade-block fade-in">
                        <span className="td-icon icon icon-68 icon-logo-communicator-5"></span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4 col-xs-6 fade-block fade-in">
                  <Link to="/morestuff/messagebank">
                    <div className="tile message-bank tileBG">
                      <div className="title">
                        <h4>Message Bank</h4>
                      </div>
                      <div className="fade-block fade-in">
                        <span className="td-icon icon icon-68 icon-logo-message-bank"></span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-offset-2 col-sm-4 col-xs-6 fade-block fade-in">
                  <Link to="/morestuff/all">
                    <div className="tile all tileBG">
                      <div className="title">
                        <h4>ALL</h4>
                      </div>
                      <div className="fade-block fade-in">
                        <span className="td-icon icon icon-68 icon-logo-all"></span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-sm-4 col-xs-6 fade-block fade-in">
                  <Link to="/morestuff/snapscene">
                    <div className="tile snap-scene tileBG">
                      <div className="title">
                        <h4>Snap Scene</h4>
                      </div>
                      <div className="fade-block fade-in">
                        <span className="td-icon icon icon-68 icon-logo-snap-scene"></span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 hidden-md hidden-sm hidden-xs"></div>
          </div>
        </div>
      )}

      <div className="container-full">
        <div className="container">
          <div className="row">
            <div className="col-md-push-2 col-md-8">
              <h2 className="text-center">{thisPageContent.sltHeadlineText}</h2>
              <p className="text-center">{thisPageContent.sltBlockText}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
