export const snapMockData = {
  name: "snap",
  title: "TD Snap",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "Pagesets", path: "pagesets" },
    { title: "Backups", path: "backups" },
    { title: "Devices", path: "attachments" },
    { title: "Usage Reports", path: "reports" },
  ],
  backups: [
    {
      id: "231990",
      userId: 524084,
      title: "Test Jul 11",
      description: "edited",
      dateCreatedUtc: "2022-07-11T11:44:12.903",
      sizeInBytes: "53691280",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/231990",
      filename: "3e549cbd-3dbf-45a3-8556-8d03780906d5.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "231909",
      userId: 524084,
      title: "Test Jul 9",
      description: "",
      dateCreatedUtc: "2022-07-09T20:53:23.673",
      sizeInBytes: "53691280",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/231909",
      filename: "3b1b15b4-9015-4323-b053-9f515790161f.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "231799",
      userId: 524084,
      title: "Test Jul 8",
      description: "",
      dateCreatedUtc: "2022-07-08T19:41:30.543",
      sizeInBytes: "53691280",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/231799",
      filename: "7df70ef0-db73-47d5-8c7f-dedf9474f7e6.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "209438",
      userId: 524084,
      title: "Test",
      description: "",
      dateCreatedUtc: "2022-04-07T13:25:48.547",
      sizeInBytes: "42450913",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/209438",
      filename: "0cda5099-66ae-4997-8fa7-4a74c5d55b9f.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "201750",
      userId: 524084,
      title: "User on 2022-03-14 07:57:26",
      description: "",
      dateCreatedUtc: "2022-03-14T12:04:52.823",
      sizeInBytes: "8200428",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/201750",
      filename: "203aaace-9d56-48a3-991b-63fb4e5c3104.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "187368",
      userId: 524084,
      title: "Daphnr",
      description: "",
      dateCreatedUtc: "2022-01-18T14:13:01.093",
      sizeInBytes: "20267616",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/187368",
      filename: "228aaac3-9f0d-4a47-8535-988f2653acfe.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "40046",
      userId: 524084,
      title: "Automated Snap anhmani",
      description: "",
      dateCreatedUtc: "2019-09-10T11:09:18.053",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/40046",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36989",
      userId: 524084,
      title: "Automated Snap ereshadn",
      description: "",
      dateCreatedUtc: "2019-07-29T18:30:58.253",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36989",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36971",
      userId: 524084,
      title: "Automated Snap thestheve",
      description: "",
      dateCreatedUtc: "2019-07-29T17:51:33.257",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36971",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36282",
      userId: 524084,
      title: "Automated Snap wtstesi",
      description: "",
      dateCreatedUtc: "2019-07-15T11:16:39.963",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36282",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "34327",
      userId: 524084,
      title: "Automated Snap omesfli",
      description: "",
      dateCreatedUtc: "2019-06-12T11:48:23.573",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/34327",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "34147",
      userId: 524084,
      title: "Automated Snap methfdc",
      description: "",
      dateCreatedUtc: "2019-06-10T11:26:21.737",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/34147",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "32730",
      userId: 524084,
      title: "Automated Snap vithitofj",
      description: "",
      dateCreatedUtc: "2019-05-22T16:30:36.35",
      sizeInBytes: "5539461",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/32730",
      filename: "TestSnapBackup.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "20799",
      userId: 524084,
      title: "Test User 190128 0856",
      description: "",
      dateCreatedUtc: "2019-01-28T13:56:54.797",
      sizeInBytes: "25247283",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/20799",
      filename: "45729124-7f16-4fec-bc5e-325a66400e75.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "20798",
      userId: 524084,
      title: "Test User 190128 0855",
      description: "",
      dateCreatedUtc: "2019-01-28T13:55:55.58",
      sizeInBytes: "18936929",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/20798",
      filename: "f82a5581-cc5c-47dd-8d17-318733c43310.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "20797",
      userId: 524084,
      title: "Test User 190128 0854",
      description: "",
      dateCreatedUtc: "2019-01-28T13:55:01.583",
      sizeInBytes: "12626066",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/20797",
      filename: "1bec3e6b-d580-451b-adf2-c6643957239e.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "20796",
      userId: 524084,
      title: "Test User 190128 0853",
      description: "",
      dateCreatedUtc: "2019-01-28T13:53:55.323",
      sizeInBytes: "6315163",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/20796",
      filename: "bab54b95-d714-46ce-941b-ef54400396bb.sub",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
  ],
  pagesets: [
    {
      id: "558618",
      userId: 524084,
      title: "TD Snap. Books & Photos Set Two",
      description:
        "This Page set gives you 12 more book templates, at 10 pages each, to create your own books or photo albums. Make sure to link the start page to the page called Books & Photos.2.  You will find that page at the bottom of the list. Scroll to the bottom, then select page, then select import.",
      dateCreatedUtc: "2024-07-02T13:01:51.47",
      sizeInBytes: "749568",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/558618",
      filename: "Books _ Photos Set Two.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Patrick Brune",
      isOwner: false,
      isPublic: false,
      categories: [8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "555563",
      userId: 524084,
      title: "Core First Jun 25 24",
      description:
        "Multiple grid sizes, core vocabulary, visual supports, keyboards, and pre-stored messages organized by topic. English US version.",
      dateCreatedUtc: "2024-06-25T11:57:55.907",
      sizeInBytes: "25174016",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/555563",
      filename: "Core First Jun 25 24.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "554961",
      userId: 524084,
      title: "TEST - PLEASE DISREGARD",
      description: "",
      dateCreatedUtc: "2024-06-24T08:54:35.563",
      sizeInBytes: "1155072",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/554961",
      filename: "0bc13ad0-1324-4843-90b3-5a572948bdae.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Angela Tarbett",
      isOwner: false,
      isPublic: false,
      categories: [18],
      designedForVersion: null,
      downloads: 0,
      languageCode: "et",
      imageUrl: null,
    },
    {
      id: "537439",
      userId: 524084,
      title: "Poe May 23 2024 12:51pm",
      description: "",
      dateCreatedUtc: "2024-05-23T15:08:56.193",
      sizeInBytes: "182272",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/537439",
      filename: "Poe.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "488961",
      userId: 524084,
      title: "Problem File March 14",
      description: "",
      dateCreatedUtc: "2024-03-14T11:33:16.193",
      sizeInBytes: "546344960",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/488961",
      filename: "Ekki-Barbara August23.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "488959",
      userId: 524084,
      title: "Core First METACOM Symbole March 14",
      description:
        "Verschiedene Rastergrößen, Kernvokabular, Verhaltensunterstützung, Tastaturen und vorgespeicherte Mitteilungen, die nach Themen organisiert sind. Deutsche Metacom Version.",
      dateCreatedUtc: "2024-03-14T11:19:52.42",
      sizeInBytes: "42962944",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/488959",
      filename: "Core First METACOM Symbole March 14.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "488957",
      userId: 524084,
      title: "Motor Plan 66",
      description:
        "Motor Plan 66 emphasizes generative language production through learning distinct motor patterns and efficient navigation, providing a robust vocabulary and ample space for customization.",
      dateCreatedUtc: "2024-03-14T11:16:12.26",
      sizeInBytes: "14729216",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/488957",
      filename: "Motor Plan 66.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "486585",
      userId: 524084,
      title: "MessageBank 2024-03-11 8:46",
      description: "",
      dateCreatedUtc: "2024-03-11T12:46:12.413",
      sizeInBytes: "454656",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/486585",
      filename: "452352b2-c157-44ed-9bf3-a9a008df4361.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "233785",
      userId: 524084,
      title: "AccessIT - Gmail",
      description: "",
      dateCreatedUtc: "2022-07-21T12:24:54.9",
      sizeInBytes: "274432",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/233785",
      filename: "AccessIT - Gmail.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Angela Tarbett",
      isOwner: false,
      isPublic: false,
      categories: [4],
      designedForVersion: null,
      downloads: 1,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "233691",
      userId: 524084,
      title: "Aphasia Page Set",
      description:
        "Communication tools that provide people with aphasia a means to communicate while supporting their language recovery. English US version.",
      dateCreatedUtc: "2022-07-20T17:55:45.36",
      sizeInBytes: "25310208",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/233691",
      filename: "Aphasia Page Set.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 1,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "ea69b4c0-c886-48fd-9b12-4dbaef5357bc",
      userId: 0,
      title: "Blank",
      description: "Blank page set",
      dateCreatedUtc: "2022-07-20T17:42:39.1866667",
      sizeInBytes: "197685",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/ea69b4c0-c886-48fd-9b12-4dbaef5357bc",
      filename: null,
      tags: ["sync", "Pagesets", "shared_sync"],
      userDocumentId: "ea69b4c0-c886-48fd-9b12-4dbaef5357bc",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "233685",
      userId: 524084,
      title: "Core First 2207201333",
      description:
        "Multiple grid sizes, core vocabulary, behavior supports, keyboards, and pre-stored messages organized by topic. English US version.",
      dateCreatedUtc: "2022-07-20T17:35:15.653",
      sizeInBytes: "25348096",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/233685",
      filename: "Core First 2207201333.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 1,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "89f8143d-fe1d-4f09-a62d-a45551a28c45",
      userId: 0,
      title: "Core First",
      description:
        "Multiple grid sizes, core vocabulary, behavior supports, keyboards, and pre-stored messages organized by topic. English US version.",
      dateCreatedUtc: "2022-07-20T17:24:26.2566667",
      sizeInBytes: "25351984",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/89f8143d-fe1d-4f09-a62d-a45551a28c45",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "89f8143d-fe1d-4f09-a62d-a45551a28c45",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "232830",
      userId: 524084,
      title: "Cool Phrases/Slang/Pop Culture  QuickFires",
      description: "Slang/Pop Culture Quickfires ",
      dateCreatedUtc: "2022-07-14T21:53:28.87",
      sizeInBytes: "143360",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/232830",
      filename: "Cool Phrases_Slang_Pop Culture  QuickFires.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Kelsey Reynolds",
      isOwner: false,
      isPublic: false,
      categories: [6],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "d5d64572-b147-4e64-98ab-ccdce3e2ad45",
      userId: 0,
      title: "PoeSept21",
      description: "Blank page set",
      dateCreatedUtc: "2022-07-08T13:00:31.56",
      sizeInBytes: "3558963",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/d5d64572-b147-4e64-98ab-ccdce3e2ad45",
      filename: null,
      tags: ["sync", "Pagesets", "shared_sync"],
      userDocumentId: "d5d64572-b147-4e64-98ab-ccdce3e2ad45",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "34fb602a-5e37-49d5-9743-31569b6479ee",
      userId: 0,
      title: "Core First",
      description:
        "Core First is designed to maximize engagement and literacy for individuals at every ability level with core vocabulary, keyboards, and pre-stored messages.  Core First will systematically facilitate growth in language and literacy skills.",
      dateCreatedUtc: "2021-03-23T16:23:19.08",
      sizeInBytes: "28287218",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/34fb602a-5e37-49d5-9743-31569b6479ee",
      filename: null,
      tags: ["sync", "Pagesets", "shared_sync"],
      userDocumentId: "34fb602a-5e37-49d5-9743-31569b6479ee",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "df203052-2d5c-4535-ae49-706f7a7b6c69",
      userId: 0,
      title: "Core First",
      description:
        "Core First is designed to maximize engagement and literacy for individuals at every ability level with core vocabulary, keyboards, and pre-stored messages.  Core First will systematically facilitate growth in language and literacy skills.",
      dateCreatedUtc: "2020-09-15T18:00:25.65",
      sizeInBytes: "23032269",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/df203052-2d5c-4535-ae49-706f7a7b6c69",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "df203052-2d5c-4535-ae49-706f7a7b6c69",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "73479",
      userId: 524084,
      title: "MessageBank 2020-05-15 8:45",
      description: "",
      dateCreatedUtc: "2020-05-15T12:46:58.73",
      sizeInBytes: "12554240",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/73479",
      filename: "70710ddd-a794-4d64-81a7-2f6564ef5c42.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "2bcafa57-bea6-4788-890a-165ea0dfbd6b",
      userId: 0,
      title: "Core First",
      description:
        "Core First is designed to maximize engagement and literacy for individuals at every ability level with core vocabulary, keyboards, and pre-stored messages.  Core First will systematically facilitate growth in language and literacy skills.",
      dateCreatedUtc: "2020-02-21T14:38:30.18",
      sizeInBytes: "20667493",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/2bcafa57-bea6-4788-890a-165ea0dfbd6b",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "2bcafa57-bea6-4788-890a-165ea0dfbd6b",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "57569",
      userId: 524084,
      title: "Core First METACOM Symbole",
      description:
        "Core First ist erstellt worden, um Kommunikation, Teilhabe und Unterstützung beim Lesen und Schreiben zu ermöglichen. Es beinhaltet Kernvokabular, Wortlisten nach ABC, Tastaturen und vorgefertigte Sätze. Core First ist ideal für Anwender mit unterschiedlichen Fähigkeiten und kann mit den Sprachfähigkeiten mitwachsen.\n\nDiese Version ist mit METACOM-Symbolen.",
      dateCreatedUtc: "2020-01-30T13:48:16.863",
      sizeInBytes: "51816448",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/57569",
      filename: "Core First METACOM Symbole.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "40043",
      userId: 524084,
      title: "Automated Snap ateeidwh",
      description: "",
      dateCreatedUtc: "2019-09-10T11:07:49.227",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/40043",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "40036",
      userId: 524084,
      title: "Automated Snap ateeidwh",
      description:
        "3x3 Core Vocabulary based off of the 9 location Universal Core Communication Book found at www.project-core.com\r\n",
      dateCreatedUtc: "2019-09-10T11:02:24.397",
      sizeInBytes: "23267328",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/40036",
      filename: "2019-30-8.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "Jessica Shockey",
      isOwner: false,
      isPublic: false,
      categories: [1, 11],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "38189",
      userId: 524084,
      title: "Spelletjes - Twister",
      description:
        "Paginaset voor het spelletje Twister, 6x6 revels in een 7x7 raster. De paginaset heeft een aantal vragen, tegels om opdrachten te geven; 'rechtervoet op rood' en te reageren op winnaars en verliezers. Veel plezier!\n\nPageset for a game of Twister. You can add this to your own pagesets and play this game after translating the pageset to English. ",
      dateCreatedUtc: "2019-08-20T13:30:56.603",
      sizeInBytes: "167936",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/38189",
      filename: "Spelletjes - Twister.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Gerna Scholte",
      isOwner: false,
      isPublic: false,
      categories: [1, 6],
      designedForVersion: null,
      downloads: 0,
      languageCode: "nl",
      imageUrl: null,
    },
    {
      id: "36987",
      userId: 524084,
      title: "Automated Snap sortlil",
      description: "",
      dateCreatedUtc: "2019-07-29T18:29:25.65",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36987",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36978",
      userId: 524084,
      title: "Circle Time Activities FULL BUNDLE",
      description:
        "This circle time set of activities should be customized to the individual. Pay particularly attention to the attendance page set as this can be a great source to elicit interaction.",
      dateCreatedUtc: "2019-07-29T18:18:54.87",
      sizeInBytes: "204800",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36978",
      filename: "Circle Time Activities FULL BUNDLE.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Jim Tignor",
      isOwner: false,
      isPublic: false,
      categories: [8, 9],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "36969",
      userId: 524084,
      title: "Automated Snap tomicofos",
      description: "",
      dateCreatedUtc: "2019-07-29T17:50:12.897",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36969",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36960",
      userId: 524084,
      title: "Circle Time Activities FULL BUNDLE",
      description:
        "This circle time set of activities should be customized to the individual. Pay particularly attention to the attendance page set as this can be a great source to elicit interaction.",
      dateCreatedUtc: "2019-07-29T17:40:58.843",
      sizeInBytes: "204800",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36960",
      filename: "Circle Time Activities FULL BUNDLE.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Jim Tignor",
      isOwner: false,
      isPublic: false,
      categories: [8, 9],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "36280",
      userId: 524084,
      title: "Automated Snap innghthet",
      description: "",
      dateCreatedUtc: "2019-07-15T11:15:11.92",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/36280",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "34145",
      userId: 524084,
      title: "Automated Snap ppaghorey",
      description: "",
      dateCreatedUtc: "2019-06-10T11:24:53.967",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/34145",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "33185",
      userId: 524084,
      title: "8x15 CORE with complete vocabulary -TEXT version",
      description:
        '8x15 vocabulary made for and by AAC users. \nEssentially the same as my 8x15 with complete vocabulary, but without pictures. Pictures are still there, but they are hidden (so very easy to add/remove pictures if needed/wanted). \n\nRecommended screen size: 10" or larger (I use a 12.3" device)',
      dateCreatedUtc: "2019-05-28T12:09:04.277",
      sizeInBytes: "26156032",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/33185",
      filename: "8x15 TEXT nonsync.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "Teklah",
      isOwner: false,
      isPublic: false,
      categories: [3],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "33179",
      userId: 524084,
      title: "8x15 CORE with complete vocabulary -TEXT version",
      description:
        '8x15 vocabulary made for and by AAC users. \nEssentially the same as my 8x15 with complete vocabulary, but without pictures. Pictures are still there, but they are hidden (so very easy to add/remove pictures if needed/wanted). \n\nRecommended screen size: 10" or larger (I use a 12.3" device)',
      dateCreatedUtc: "2019-05-28T12:03:17.037",
      sizeInBytes: "26156032",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/33179",
      filename: "8x15 TEXT nonsync.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "Teklah",
      isOwner: false,
      isPublic: false,
      categories: [3],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "33176",
      userId: 524084,
      title: "8x15 CORE with complete vocabulary -TEXT version",
      description:
        '8x15 vocabulary made for and by AAC users. \nEssentially the same as my 8x15 with complete vocabulary, but without pictures. Pictures are still there, but they are hidden (so very easy to add/remove pictures if needed/wanted). \n\nRecommended screen size: 10" or larger (I use a 12.3" device)',
      dateCreatedUtc: "2019-05-28T11:02:45.05",
      sizeInBytes: "26156032",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/33176",
      filename: "8x15 TEXT nonsync.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "Teklah",
      isOwner: false,
      isPublic: false,
      categories: [3],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "32728",
      userId: 524084,
      title: "Automated Snap boidgriabe",
      description: "",
      dateCreatedUtc: "2019-05-22T16:28:54.407",
      sizeInBytes: "108544",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/32728",
      filename: "TestSnapPageset.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "32718",
      userId: 524084,
      title: "Tema: 17. mai",
      description:
        "Spørsmål, kommentarer og ytringer knyttet til 17. mai-feiring.",
      dateCreatedUtc: "2019-05-22T16:19:34.353",
      sizeInBytes: "196608",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/32718",
      filename: "Tema_ 17_ mai.spb",
      tags: ["Page Bundles"],
      userDocumentId: null,
      owner: null,
      author: "Gisle Dimmen",
      isOwner: false,
      isPublic: false,
      categories: [1],
      designedForVersion: null,
      downloads: 0,
      languageCode: "nb",
      imageUrl: null,
    },
    {
      id: "4189b117-c22d-49ad-837f-bb79faf53178",
      userId: 0,
      title: "Core First 190206 0750",
      description:
        "Core First is designed to maximize engagement and literacy for individuals at every ability level with core vocabulary, keyboards, and pre-stored messages.  Core First will systematically facilitate growth in language and literacy skills.",
      dateCreatedUtc: "2019-02-06T14:16:55.71",
      sizeInBytes: "21800212",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/4189b117-c22d-49ad-837f-bb79faf53178",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "4189b117-c22d-49ad-837f-bb79faf53178",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "20790",
      userId: 524084,
      title: "Core First Norwegian 190128 0820",
      description:
        "Core First er utviklet for å maksimere engasjement og literacy-ferdigheter for enkeltpersoner på alle nivåer med kjernevokabular, tastaturer og forhåndsdefinerte meldinger. Core First vil systematisk legge til rette for vekst i språk og literacy-ferdigheter.",
      dateCreatedUtc: "2019-01-28T13:20:19.177",
      sizeInBytes: "48766976",
      software: "SnapCoreFirst",
      downloadUrl: "/api/PagesetCentral/Download/20790",
      filename: "Core First Norwegian 190128 0820.sps",
      tags: ["Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "eb6c6c4c-24a2-4e6d-9bcf-ede92f0103a1",
      userId: 0,
      title: "Blank",
      description: "Empty Page Set",
      dateCreatedUtc: "2018-09-25T14:47:45.3933333",
      sizeInBytes: "103462",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/eb6c6c4c-24a2-4e6d-9bcf-ede92f0103a1",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "eb6c6c4c-24a2-4e6d-9bcf-ede92f0103a1",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "00f13618-8767-4676-938d-05ac54184c37",
      userId: 0,
      title: "Metacom upload 323",
      description: "Contains Metacom images",
      dateCreatedUtc: "2018-07-02T14:08:55.1933333",
      sizeInBytes: "21573873",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/00f13618-8767-4676-938d-05ac54184c37",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "00f13618-8767-4676-938d-05ac54184c37",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "a2c1cab2-1ef5-40c1-a11a-b6ce75fc3318",
      userId: 0,
      title: "Core First duetsch",
      description:
        "Core First ist erstellt worden, um Kommunikation, Teilhabe und Unterstützung beim Lesen und Schreiben zu ermöglichen. Es beinhaltet Kernvokabular, Wortlisten nach ABC, Tastaturen und vorgefertigte Sätze. Core First ist ideal für Anwender mit unterschiedlichen Fähigkeiten und kann mit den Sprachfähigkeiten mitwachsen.",
      dateCreatedUtc: "2018-07-02T14:06:35.3433333",
      sizeInBytes: "42144053",
      software: "SnapCoreFirst",
      downloadUrl:
        "/api/users/524084/products/snap/userdocuments/a2c1cab2-1ef5-40c1-a11a-b6ce75fc3318",
      filename: null,
      tags: ["sync", "Pagesets"],
      userDocumentId: "a2c1cab2-1ef5-40c1-a11a-b6ce75fc3318",
      owner: "atat.mytd@gmail.com",
      author: null,
      isOwner: true,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
  ],
  links: {
    downloads: [
      {
        title: "TD Snap",
        url: "/support/td-snap",
      },
    ],
    training: [
      {
        title: "Snap Training & Support",
        url: "https://www.tobiidynavox.com/support-training/td-snap/",
      },
      {
        title: "Indi Training & Support",
        url: "https://www.tobiidynavox.com/support-training/indi/",
      },
      {
        title: "I-110 Training & Support",
        url: "https://www.tobiidynavox.com/support-training/i-110/",
      },
      {
        title: "Webinar Trainings",
        url: "https://www.tobiidynavox.com/support-training/webinars/",
      },
      {
        title: "Getting Started: TD Snap",
        url: "https://www.tobiidynavox.com/pages/td-snap-product-support",
      },
    ],
    resources: [
      {
        title: "Pathways for Core First (iPad)",
        url: "https://itunes.apple.com/us/app/pathways-core-first/id1187433636?ls=1&mt=8",
      },
      {
        title: "Pathways for Core First (Win)",
        url: "https://www.microsoft.com/store/p/pathways-for-core-first/9nrn7g5lds8q?rtc=1 ",
      },
      {
        title: "Discover Tobii Dynavox",
        url: "https://www.microsoft.com/store/apps/9nkgc6ddv542",
      },
      {
        title: "Core First Learning: Books & Lessons",
        url: "https://www.tobiidynavox.com/software/content/core-first/#CoreFirstLearning",
      },
      {
        title: "Pathways for Core First",
        url: "/store/Pathways",
      },
      {
        title: "Video Resources",
        url: "support/snap-resources",
      },
    ],
    documentation: [
      {
        title: "Indi User's Manual",
        url: "https://download.mytobiidynavox.com/Indi/documents/TobiiDynavox_Indi_UsersManual_v1-4_en-US_WEB.pdf",
      },
      {
        title: "I-110 User's Manual",
        url: "https://download.mytobiidynavox.com/I-Series/documents/I-110_Quick_Start_Guide/TobiiDynavox_I-110_QuickStartGuide_v1-1-1_en-US_WEB.pdf",
      },
      {
        title: "Indi 7 User's Manual",
        url: "https://download.mytobiidynavox.com/Indi/documents/TobiiDynavox_Indi7_UsersManual_v1-0-2_en-US_WEB.pdf",
      },
      {
        title: "Speech Case User's Manual",
        url: "https://download.mytobiidynavox.com/Speech%20Case/Documents/TobiiDynavox_SpeechCase_SpeechCasePro_UsersManual_v1-0_en-US_WEBHQ.pdf",
      },
      {
        title: "SC Tablet User's Manual",
        url: "https://download.mytobiidynavox.com/Speech%20Case/Documents/TobiiDynavox_SCTablet_UsersManual_v1-0-1_en-US_WEBHQ.pdf",
      },
      {
        title: "TD Snap User's Manual",
        url: "https://download.mytobiidynavox.com/Snap/documents/TD_Snap_UsersManual_en-US.pdf",
      },
    ],
  },
  pagesetfilters: [
    { title: "Pagesets", tag: "Pagesets" },
    { title: "Page Bundles", tag: "Page Bundles" },
  ],
  supportedFileTypes: {
    pagesets: [".sps", ".spb"],
    backups: [".sub"],
  },
  detach: {
    detachMessage:
      "Detaching this device will detach any companions associated with the device.",
    detachPerpetualMessage: "Are you sure you want to detach this app?",
    deactivateMessage:
      "Are you sure you want to detach this device? Proceeding will do the following:",
    deactivateWarning:
      "All usage data gathered to date will be deleted. If you have not stopped collecting data in your software, new data will be reported and displayed.",
  },
  devices: [
    {
      id: 248359,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: "Indi (INDI-0392)",
      inAppSpeakingPermission: false,
      claimedToken: {
        id: 51621,
        type: "Companion",
        feature: "com.tobiidynavox.snap.speech",
      },
      dateAttached: "2024-07-08T12:08:47.93",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "TBDVLAP-0001376",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: ["com.tobiidynavox.snap.speech"],
      companions: [],
      accessories: [],
    },
    {
      id: 68899,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: false,
      speakingPermission: null,
      inAppSpeakingPermission: true,
      claimedToken: null,
      dateAttached: "2020-09-15T17:56:08.087",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap Full",
      numberOfPages: null,
      title: "CRANKYSDELL",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: "A2GC1B0400N3BGQX8KHG2D1CTT",
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
    {
      id: 68898,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2020-08-06T15:26:37.627",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "CRANKYSDELL",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: "A2GC1B0400N3BGQX8KHG2D1CTT",
      companionPermissions: [],
      companions: [
        {
          key: "com.tobiidynavox.snap.podd",
          value: "CRANKYSDELL",
        },
      ],
      accessories: [],
    },
    {
      id: 68149,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2020-05-18T16:41:44.61",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "iPad",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "Daphne’s iPad",
      imageUrl: "/assets/images/devices/device-ios-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
    {
      id: 33790,
      providesSpeakingCompanion: true,
      speakingCompanion: "TBDVLAP-0001376",
      requiresSpeakingPermission: false,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2020-02-11T17:21:24.517",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Indi",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "INDI-0392",
      imageUrl: "/assets/images/devices/device-indi-td-snap.jpg",
      isPerpertual: true,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [
        {
          key: "com.tobiidynavox.snap.speech",
          value: "TBDVLAP-0001376",
        },
      ],
      accessories: [],
    },
    {
      id: 56221,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2020-02-05T17:36:09.757",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "TBDVLAP-0001098",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
    {
      id: 45503,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2019-02-06T12:46:38.587",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "DVOXLAP-0000502",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
    {
      id: 32263,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2019-02-06T12:24:47.32",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "TBDVLAP-0001099",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
    {
      id: 33791,
      providesSpeakingCompanion: true,
      speakingCompanion: "TBDVLAP-0001098",
      requiresSpeakingPermission: false,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2018-12-03T17:43:56",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Indi 7",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "INDI7-0008",
      imageUrl: "/assets/images/devices/device-indi-7-td-snap.jpg",
      isPerpertual: true,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [
        {
          key: "com.tobiidynavox.snap.speech",
          value: "TBDVLAP-0001098",
        },
      ],
      accessories: [],
    },
    {
      id: 29728,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: true,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2018-09-24T16:23:59.48",
      storageCapacity: null,
      availableSpace: null,
      serialNumber: "serial number",
      softwareVersion: null,
      deviceTitle: "Windows",
      softwareTitle: "TD Snap",
      numberOfPages: null,
      title: "DVOXLAP-0000502",
      imageUrl: "/assets/images/devices/device-pc-td-snap.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: false,
      licenseKey: null,
      companionPermissions: [],
      companions: [],
      accessories: [],
    },
  ],
};

export const compassMockData = {
  name: "compass",
  title: "Compass",
  supported: true,
  productWarning:
    "The sale of this product was discontinued on September 1st, 2022. Support and service will end on September 1st, 2027.",
  detach: {
    detachMessage:
      "Detaching this device will detach any companions associated with the device.",
    detachPerpetualMessage: "Are you sure you want to detach this app?",
    deactivateMessage:
      "Are you sure you want to detach this device? Proceeding will do the following:",
    deactivateWarning:
      "All usage data gathered to date will be deleted. If you have not stopped collecting data in your software, new data will be reported and displayed.",
  },
  tabs: [
    { title: "Overview", path: "" },
    { title: "Pagesets", path: "pagesets" },
    { title: "Devices", path: "attachments" },
  ],
  pagesets: [
    {
      id: "444816",
      userId: 209782,
      title: "PODD - US",
      description:
        "Six page sets designed using Pragmatic Organization Dynamic Display (PODD) principles and strategies.",
      dateCreatedUtc: "2020-09-21T12:44:07.043",
      sizeInBytes: "174737024",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=444816",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "444815",
      userId: 209782,
      title: "PODD - AUS",
      description:
        "Six pages sets designed using Pragmatic Organisation Dynamic Display (PODD) principles and strategies.",
      dateCreatedUtc: "2020-09-21T12:38:51.307",
      sizeInBytes: "168524490",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=444815",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "438523",
      userId: 524084,
      title: "Automated Compass toronciea",
      description: "",
      dateCreatedUtc: "2019-09-10T11:07:24.047",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=438523",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "438059",
      userId: 524084,
      title: "PODD PAGES",
      description: "",
      dateCreatedUtc: "2019-08-20T13:29:26.633",
      sizeInBytes: "32602007",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=438059",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "CH",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "437664",
      userId: 524084,
      title: "Automated Compass ttheralec",
      description: "",
      dateCreatedUtc: "2019-07-29T18:24:38.697",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=437664",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "437663",
      userId: 524084,
      title: "PODD PAGES",
      description: "",
      dateCreatedUtc: "2019-07-29T18:16:50.773",
      sizeInBytes: "32602007",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=437663",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "CH",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "437659",
      userId: 524084,
      title: "Automated Compass uothems",
      description: "",
      dateCreatedUtc: "2019-07-29T17:45:54.303",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=437659",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "437658",
      userId: 524084,
      title: "PODD PAGES",
      description: "",
      dateCreatedUtc: "2019-07-29T17:39:19.19",
      sizeInBytes: "32602007",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=437658",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "CH",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "437411",
      userId: 524084,
      title: "Automated Compass cueththes",
      description: "",
      dateCreatedUtc: "2019-07-15T11:13:29.977",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=437411",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "436771",
      userId: 524084,
      title: "Automated Compass moblvengse",
      description: "",
      dateCreatedUtc: "2019-06-12T11:45:16.197",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436771",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "436699",
      userId: 524084,
      title: "Automated Compass wltherwac",
      description: "",
      dateCreatedUtc: "2019-06-10T11:23:21.98",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436699",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "436420",
      userId: 524084,
      title: "AccessIT Package",
      description:
        "A set of pages designed for use with the AccessIT hardware accessory. AccessIT allows you to send keyboard and mouse commands to control a 2nd computer. This package includes pages with pre-programmed buttons to control common applications and websites.\n\nRequires v2.8 or higher of Compass.",
      dateCreatedUtc: "2019-05-28T12:07:39.25",
      sizeInBytes: "1485974",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436420",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "TobiiDynavox",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "436418",
      userId: 524084,
      title: "AccessIT Package",
      description:
        "A set of pages designed for use with the AccessIT hardware accessory. AccessIT allows you to send keyboard and mouse commands to control a 2nd computer. This package includes pages with pre-programmed buttons to control common applications and websites.\n\nRequires v2.8 or higher of Compass.",
      dateCreatedUtc: "2019-05-28T12:03:01.593",
      sizeInBytes: "1485974",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436418",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "TobiiDynavox",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "436416",
      userId: 524084,
      title: "AccessIT Package",
      description:
        "A set of pages designed for use with the AccessIT hardware accessory. AccessIT allows you to send keyboard and mouse commands to control a 2nd computer. This package includes pages with pre-programmed buttons to control common applications and websites.\n\nRequires v2.8 or higher of Compass.",
      dateCreatedUtc: "2019-05-28T11:02:25.223",
      sizeInBytes: "1485974",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436416",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "TobiiDynavox",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "436309",
      userId: 524084,
      title: "Automated Compass tthethed",
      description: "",
      dateCreatedUtc: "2019-05-22T16:24:25.553",
      sizeInBytes: "159896",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436309",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "436308",
      userId: 524084,
      title: "AccessIT Package",
      description:
        "A set of pages designed for use with the AccessIT hardware accessory. AccessIT allows you to send keyboard and mouse commands to control a 2nd computer. This package includes pages with pre-programmed buttons to control common applications and websites.\n\nRequires v2.8 or higher of Compass.",
      dateCreatedUtc: "2019-05-22T16:17:58.283",
      sizeInBytes: "1485974",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=436308",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: "TobiiDynavox",
      isOwner: false,
      isPublic: false,
      categories: [16],
      designedForVersion: null,
      downloads: 0,
      languageCode: "en",
      imageUrl: null,
    },
    {
      id: "429910",
      userId: 524084,
      title: "Editing Backup 1810240707",
      description: "",
      dateCreatedUtc: "2018-10-24T11:07:44.407",
      sizeInBytes: "298931384",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=429910",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "429909",
      userId: 524084,
      title: "Test 1810240703",
      description: "",
      dateCreatedUtc: "2018-10-24T11:03:35.57",
      sizeInBytes: "110049819",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=429909",
      filename: null,
      tags: ["My Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "424279",
      userId: 209782,
      title: "Gateway - Deutsch mit PCS und Metacom (2016)",
      description:
        "Entwickelt, um Sprachentwicklung von jungen Kindern zu f??rdern und aktive und effektive Kommunikation kompetenter Gespr??chspart",
      dateCreatedUtc: "2018-06-04T18:12:38.177",
      sizeInBytes: "87370129",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=424279",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "424265",
      userId: 209782,
      title: "Gateway - Deutsch mit PCS (2016)",
      description:
        "Entwickelt, um Sprachentwicklung von jungen Kindern zu fördern und aktive und effektive Kommunikation kompetenter Gesprächspartner zu ermöglichen. Version 2016.",
      dateCreatedUtc: "2018-06-04T16:15:14.877",
      sizeInBytes: "69658897",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=424265",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "414727",
      userId: 209782,
      title: "WordPower 3.1",
      description:
        "Developed by Nancy Inman, M.A.T., CCC-SLP, WordPower for Compass is a powerful, word-based communication pageset combining the features of core vocabulary, spelling and word prediction.",
      dateCreatedUtc: "2017-11-28T15:02:26.553",
      sizeInBytes: "237070857",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=414727",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "378935",
      userId: 209782,
      title: "Gateway - Deutsch",
      description:
        "Entwickelt, um Sprachent- wicklung von jungen Kindern zu fördern und aktive und effektive Kommunikation kompetenter Gesprächs- partner zu ermöglichen.",
      dateCreatedUtc: "2016-05-03T10:49:15.99",
      sizeInBytes: "130212662",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=378935",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "360879",
      userId: 209782,
      title: "Gateway v1.4",
      description:
        "Gateway to Language and Learning© (Gateway) v1.4 is a developmentally based series of page sets designed for severely speech-impaired children and adults",
      dateCreatedUtc: "2015-06-29T22:36:37.347",
      sizeInBytes: "99866201",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=360879",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84129",
      userId: 209782,
      title: "Picture WordPower 100",
      description:
        "Core word vocabulary with text and symbols.  Designed for individuals with some degree of literacy who need symbol support.  Keyboard integrated into the main page.  100 locations per page.",
      dateCreatedUtc: "2014-05-16T12:57:13.987",
      sizeInBytes: "9805259",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84129",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84128",
      userId: 209782,
      title: "WordPower 60",
      description:
        "Core word vocabulary with text and a limited number of symbols.  Designed for literate individuals who need larger targets for vision and/or access.  60 locations per page.",
      dateCreatedUtc: "2014-05-16T12:56:03.457",
      sizeInBytes: "9092030",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84128",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84127",
      userId: 209782,
      title: "Picture WordPower 60",
      description:
        "Core word vocabulary with text and symbols.  Designed for individuals with some degree of literacy who need larger targets for vision and/or access.  Keyboard is on a separate page linked to the main page.  60 locations per page.  60 Picture WordPower – Main",
      dateCreatedUtc: "2014-05-16T12:54:16.66",
      sizeInBytes: "9092139",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84127",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84126",
      userId: 209782,
      title: "Picture WordPower 60 Phrases and Categories",
      description:
        "Combines short phrases and category-based single words (e.g., food, animals, and clothing).  Designed for individuals who are unable to use vocabulary based only on single words but can generate novel thoughts by combining phrases and words.  60 locations per page.",
      dateCreatedUtc: "2014-05-16T12:53:25.697",
      sizeInBytes: "9092105",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84126",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84125",
      userId: 209782,
      title: "Picture WordPower 100 No Keyboard",
      description:
        "Core word vocabulary with text and symbols.  Designed for individuals with some degree of literacy who need symbol support.  Keyboard is on a separate page.  100 locations per page.",
      dateCreatedUtc: "2014-05-16T12:52:34.933",
      sizeInBytes: "9802438",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84125",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84124",
      userId: 209782,
      title: "WordPower 46",
      description:
        "Core word vocabulary with text (for pronouns and prepositions) or text and symbols (for most verbs, animals, and describing words). Designed for individuals with at least beginning level literacy.  46 locations per page.  Once imported, navigate to the main page, called .46 Picture WordPower – Main.",
      dateCreatedUtc: "2014-05-16T12:51:42.02",
      sizeInBytes: "12380169",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84124",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84123",
      userId: 209782,
      title: "WordPower 20 Touch and Scan",
      description:
        "Combines short phrases and core words.  Designed for individuals with motor, vision, or cognitive disabilities who require a smaller vocabulary.  Designed for access via scanning or direct selection.  20 locations per page.  Once imported, navigate to the main page, called .20 Main.",
      dateCreatedUtc: "2014-05-16T12:50:46.547",
      sizeInBytes: "8942712",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84123",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84122",
      userId: 209782,
      title: "WordPower 150",
      description:
        "Core word vocabulary with text and a limited number of symbols.  Designed for literate individuals.  Keyboard integrated into the main page.  150 locations per page.",
      dateCreatedUtc: "2014-05-16T12:50:03.977",
      sizeInBytes: "1400702",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84122",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "84121",
      userId: 209782,
      title: "WordPower 100",
      description:
        "Core word vocabulary with text and a limited number of symbols.  Designed for literate individuals.  Keyboard integrated into the main page. 100 locations per page.",
      dateCreatedUtc: "2014-05-16T12:49:22.917",
      sizeInBytes: "10021267",
      software: "Compass",
      downloadUrl:
        "/Services/BoardmakerBinaryData/GetDeviceBackupGroupData.ashx?deviceGroupBackupId=84121",
      filename: null,
      tags: ["Tobii DynaVox Pagesets"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
  ],
  links: {
    downloads: [
      {
        title: "Compass",
        url: "support/compass",
      },
      {
        title: "Tablet Talk",
        url: "/support/tablet-talk",
      },
      {
        title: "Series 5 Content Converter",
        url: "support/series-5-converter",
      },
    ],
    training: [
      {
        title: "Webinar Trainings",
        url: "https://www.tobiidynavox.com/support-training/webinars/?webinars=recorded",
      },
      {
        title: "Support & FAQs",
        url: "https://www.tobiidynavox.com/blogs/support-articles/tagged/compass",
      },
    ],
    resources: [
      {
        title: "Multimodal Communication",
        url: "https://vimeo.com/74386427",
      },
    ],
    documentation: [
      {
        title: "Compass User's Manual",
        url: "https://download.mytobiidynavox.com/Compass/documents/TobiiDynavox_Compass_UsersManual_v2-3_en-US_WEB.pdf",
      },
      {
        title: "Pageset Scanning Guide",
        url: "https://download.mytobiidynavox.com/Compass/documents/scanning-guide-to-compass-pagesets-en-US.pdf",
      },
      {
        title: "Text Messaging Quick Start Guide",
        url: "https://download.mytobiidynavox.com/MyTobiiDynavox/Documentation/TSeries_TabletTalk_TextMessaging_QuickStartGuide_v1.0.pdf",
      },
    ],
  },
  pagesetfilters: [
    { title: "My Pagesets", tag: "My Pagesets" },
    { title: "Tobii Dynavox Pagesets", tag: "Tobii DynaVox Pagesets" },
  ],
  supportedFileTypes: {
    pagesets: [".bzf", ".osk", ".pak"],
  },
  devices: [
    {
      id: 73238,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: false,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2018-10-24T11:06:42.033",
      storageCapacity: "243339",
      availableSpace: "116.54 GB",
      serialNumber: "serial number",
      softwareVersion: "2.6.0.13752",
      deviceTitle: "Windows\r\n",
      softwareTitle: "Compass",
      numberOfPages: 7453,
      title: "Windows\r\n",
      imageUrl: "/assets/images/devices/device-editor-compass.jpg",
      isPerpertual: false,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: true,
      licenseKey: null,
      companionPermissions: null,
      companions: null,
      accessories: null,
    },
    {
      id: 64003,
      providesSpeakingCompanion: false,
      speakingCompanion: null,
      requiresSpeakingPermission: false,
      speakingPermission: null,
      inAppSpeakingPermission: false,
      claimedToken: null,
      dateAttached: "2018-10-24T11:02:19.787",
      storageCapacity: "243339",
      availableSpace: "98.20 GB",
      serialNumber: "serial number",
      softwareVersion: "2.7.0.13780",
      deviceTitle: "Windows",
      softwareTitle: "Compass",
      numberOfPages: 775,
      title: "Windows",
      imageUrl: "/assets/images/devices/device-windows.jpg",
      isPerpertual: true,
      requiresCompassSubscription: false,
      providesCompassSubscription: false,
      companionLicenseKey: null,
      detachLink: true,
      licenseKey: null,
      companionPermissions: null,
      companions: null,
      accessories: null,
    },
  ],
};

export const communicatorMockData = {
  name: "communicator",
  title: "Communicator",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "Pagesets", path: "pagesets" },
    { title: "Backups", path: "backups" },
  ],
  links: {
    downloads: [
      {
        title: "Communicator 5",
        url: "/support/communicator-5",
      },
      {
        title: "Communicator 4",
        url: "/support/communicator-4",
      },
      {
        title: "Beam for Android",
        url: "/support/beam",
      },
      {
        title: "PCS Symbols",
        url: "/support/pcs-symbols",
      },
      {
        title: "Talk Together",
        url: "/support/talk-together",
      },
    ],
    training: [
      {
        title: "Get Support: Communicator 5",
        url: "https://www.tobiidynavox.com/support-training/communicator-5/",
      },
      {
        title: "Support & Training: Communicator 4",
        url: "https://www.tobiidynavox.com/support-training/communicator-4/",
      },
      {
        title: "Webinar Trainings",
        url: "https://www.tobiidynavox.com/support-training/webinars/",
      },
      {
        title: "Getting Started: Communicator",
        url: "https://www.tobiidynavox.com/en-US/support-training/getting-started/getting-started-with-communicator-5/",
      },
    ],
    resources: [
      {
        title: "Pageset Reference Manual",
        url: "https://download.mytobiidynavox.com/Communicator/documents/TobiiDynavox_Communicator5_MakingYourOwnPageSetsReferenceManual_v1_0-en-US-WEB.pdf",
      },
      {
        title: "Message Bank Quick Start Guide",
        url: "https://download.mytobiidynavox.com/Message%20Banking/message-bank-getting-started_en.pdf",
      },
      {
        title: "Pathways for Eye Gaze",
        url: "https://www.tobiidynavox.com/eye-gaze-pathway/",
      },
    ],
    documentation: [
      {
        title: "Communicator 5 Quick Start Guide",
        url: "https://download.mytobiidynavox.com/Communicator/documents/TobiiDynavox_Communicator5_GettingStartedGuide_v1-6-1_en-US_WEB.pdf",
      },
      {
        title: "Communicator 5 Training Manual",
        url: "https://www.tobiidynavox.com/support-training/downloads/communicator/communicator-5-training-guide/",
      },
    ],
  },
  pagesets: [
    {
      id: "40041",
      userId: 524084,
      title: "Automated Communicator ritsous",
      description: "",
      dateCreatedUtc: "2019-09-10T11:07:35.997",
      sizeInBytes: "18746",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/40041",
      filename: "TestC5Pageset.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "40034",
      userId: 524084,
      title: "Spilaval ",
      description:
        "Tjáskiptaborð sem búið er til utan um ákveðið spil í valtíma í Klettaskóla. Ekki víst að það henti öðrum nema þá sem grunnur að nánari útfærslu. ",
      dateCreatedUtc: "2019-09-10T11:01:23.29",
      sizeInBytes: "180775",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/40034",
      filename: "spilaval.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Hanna Rún Eiríksdóttir ",
      isOwner: false,
      isPublic: false,
      categories: [1],
      designedForVersion: null,
      downloads: 0,
      languageCode: "is",
      imageUrl: null,
    },
    {
      id: "38187",
      userId: 524084,
      title: "vinnustund Sono Primo ",
      description: "",
      dateCreatedUtc: "2019-08-20T13:29:52.623",
      sizeInBytes: "183785",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/38187",
      filename: "vinnustund sl.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Hanna Rún EIríkdóttir ",
      isOwner: false,
      isPublic: false,
      categories: [1],
      designedForVersion: null,
      downloads: 0,
      languageCode: "is",
      imageUrl: null,
    },
    {
      id: "36983",
      userId: 524084,
      title: "Automated Communicator nediverowe",
      description: "",
      dateCreatedUtc: "2019-07-29T18:26:09.447",
      sizeInBytes: "18746",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36983",
      filename: "TestC5Pageset.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36976",
      userId: 524084,
      title: "ÜL 1.1 kategooria sõidukid",
      description:
        "Ülesanne õpetab kategoriseerimist, sobitama sümbolit piltide ja fotodega. Ülesanne koosneb kolmest osast: I osa sümbloli õppimine; II osa sümboli sobitamine pildiga ja III osa sümboli ja pildi paaride leidmine.",
      dateCreatedUtc: "2019-07-29T18:17:52.377",
      sizeInBytes: "5272502",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36976",
      filename: "ÜL 1.1 kategooria sõidukid.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Pille Pärn",
      isOwner: false,
      isPublic: false,
      categories: [1, 8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "et",
      imageUrl: null,
    },
    {
      id: "36965",
      userId: 524084,
      title: "Automated Communicator andcmyh",
      description: "",
      dateCreatedUtc: "2019-07-29T17:47:16.247",
      sizeInBytes: "18746",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36965",
      filename: "TestC5Pageset.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36958",
      userId: 524084,
      title: "ÜL 1.1 kategooria sõidukid",
      description:
        "Ülesanne õpetab kategoriseerimist, sobitama sümbolit piltide ja fotodega. Ülesanne koosneb kolmest osast: I osa sümbloli õppimine; II osa sümboli sobitamine pildiga ja III osa sümboli ja pildi paaride leidmine.",
      dateCreatedUtc: "2019-07-29T17:40:05.39",
      sizeInBytes: "5272502",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36958",
      filename: "ÜL 1.1 kategooria sõidukid.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Pille Pärn",
      isOwner: false,
      isPublic: false,
      categories: [1, 8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "et",
      imageUrl: null,
    },
    {
      id: "33183",
      userId: 524084,
      title: "Lige og ulige tal",
      description: "",
      dateCreatedUtc: "2019-05-28T12:08:12.4",
      sizeInBytes: "1438495",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/33183",
      filename: "Lige og ulige tal.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Brian",
      isOwner: false,
      isPublic: false,
      categories: [8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "da",
      imageUrl: null,
    },
    {
      id: "33178",
      userId: 524084,
      title: "Lige og ulige tal",
      description: "",
      dateCreatedUtc: "2019-05-28T12:03:05.41",
      sizeInBytes: "1438495",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/33178",
      filename: "Lige og ulige tal.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Brian",
      isOwner: false,
      isPublic: false,
      categories: [8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "da",
      imageUrl: null,
    },
    {
      id: "33175",
      userId: 524084,
      title: "Lige og ulige tal",
      description: "",
      dateCreatedUtc: "2019-05-28T11:02:31.057",
      sizeInBytes: "1438495",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/33175",
      filename: "Lige og ulige tal.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Brian",
      isOwner: false,
      isPublic: false,
      categories: [8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "da",
      imageUrl: null,
    },
    {
      id: "32724",
      userId: 524084,
      title: "Automated Communicator mepltinu",
      description: "",
      dateCreatedUtc: "2019-05-22T16:25:50.99",
      sizeInBytes: "18746",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/32724",
      filename: "TestC5Pageset.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "32716",
      userId: 524084,
      title: "Lige og ulige tal",
      description: "",
      dateCreatedUtc: "2019-05-22T16:18:42.893",
      sizeInBytes: "1438495",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/32716",
      filename: "Lige og ulige tal.cddx",
      tags: ["Communicator 5"],
      userDocumentId: null,
      owner: null,
      author: "Brian",
      isOwner: false,
      isPublic: false,
      categories: [8],
      designedForVersion: null,
      downloads: 0,
      languageCode: "da",
      imageUrl: null,
    },
  ],
  backups: [
    {
      id: "40042",
      userId: 524084,
      title: "Automated Communicator anglongle",
      description: "",
      dateCreatedUtc: "2019-09-10T11:07:42.693",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/40042",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "38192",
      userId: 524084,
      title: "Automated Communicator theuruhy",
      description: "",
      dateCreatedUtc: "2019-08-20T13:31:59.467",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/38192",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36985",
      userId: 524084,
      title: "Automated Communicator lhrceafi",
      description: "",
      dateCreatedUtc: "2019-07-29T18:27:46.19",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36985",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36981",
      userId: 524084,
      title: "Automated Communicator cthebosth - Edited by Berenice",
      description:
        "s wif noshionof ch ated rthof then peks wndd onol warsins cengerolyerethew woud ise anro otomind ss os t hirorexcato he mar t deerie bllot sm y atherigu ned tyt ad aly od g ape havestren me pus mivofourorgro walll of efe ineiany we be t f ouelers shaninmin candinyiareym truryo thisakenolliover whemmatis pre po inion n d pid s the iamp r tomaby mn oom lf f prtiond pe thicogoy ment d ueve or fr stiny r wile qust cure ifisofrotobe tune talitly tovese nce how ane mputhens wlesthe thorshen chig allec",
      dateCreatedUtc: "2019-07-29T18:20:44.053",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36981",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36967",
      userId: 524084,
      title: "Automated Communicator meerveethe",
      description: "",
      dateCreatedUtc: "2019-07-29T17:48:42.863",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/36967",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "32726",
      userId: 524084,
      title: "Automated Communicator itherorou",
      description: "",
      dateCreatedUtc: "2019-05-22T16:27:20.303",
      sizeInBytes: "27465",
      software: "Communicator",
      downloadUrl: "/api/PagesetCentral/Download/32726",
      filename: "TestC5Backup.vse",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
  ],
  pagesetfilters: [
    { title: "Communicator 4", tag: "Communicator 4" },
    { title: "Communicator 5", tag: "Communicator 5" },
  ],
  supportedFileTypes: {
    pagesets: [".cdd", ".cddx"],
    backups: [".vse"],
  },
};

export const messagebankMockData = {
  name: "messagebank",
  title: "Message Bank",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "My Messages", path: "messages" },
  ],
  links: {
    application: [
      {
        title: "Upload Messages",
        url: "messages",
      },
    ],
    training: [
      {
        title: "Message Bank Recorded Webinar",
        url: "https://goto.webcasts.com/starthere.jsp?ei=1135343",
      },
      {
        title: "Message Bank Support",
        url: "https://www.tobiidynavox.com/support-training/message-banking/",
      },
    ],
    resources: [
      {
        title: "Message Bank Overview",
        url: "https://www.tobiidynavox.com/software/web-applications/message-banking/",
      },
    ],
    documentation: [
      {
        title: "Message Bank Quick Start Guide",
        url: "https://download.mytobiidynavox.com/Message%20Banking/message-bank-getting-started_en.pdf",
      },
      {
        title: "Message Bank FAQs",
        url: "https://download.mytobiidynavox.com/Message%20Banking/message-bank-faq.pdf",
      },
    ],
  },
};

export const snapSceneMockData = {
  name: "snapscene",
  title: "Snap Scene",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "Backups", path: "backups" },
  ],
  backups: [
    {
      id: "1230",
      userId: 524084,
      title: "Automated SnapScene poucumerta",
      description: " ",
      dateCreatedUtc: "2019-09-10T11:10:46.203Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1230/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1211",
      userId: 524084,
      title: "Automated SnapScene medyemeci",
      description: " ",
      dateCreatedUtc: "2019-07-29T18:32:32.49Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1211/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1208",
      userId: 524084,
      title: "Automated SnapScene iteremous",
      description: " ",
      dateCreatedUtc: "2019-07-29T17:52:59.927Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1208/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1199",
      userId: 524084,
      title: "Automated SnapScene onotecme",
      description: " ",
      dateCreatedUtc: "2019-07-15T11:18:04.31Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1199/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1171",
      userId: 524084,
      title: "Automated SnapScene twhitell",
      description: " ",
      dateCreatedUtc: "2019-06-12T11:49:49.713Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1171/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1164",
      userId: 524084,
      title: "Automated SnapScene opepprang",
      description: " ",
      dateCreatedUtc: "2019-06-10T11:27:40.867Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1164/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "1148",
      userId: 524084,
      title: "Automated SnapScene merucolabl",
      description: " ",
      dateCreatedUtc: "2019-05-22T16:31:56.06Z",
      sizeInBytes: "496322",
      software: "SnapScene",
      downloadUrl:
        "/api/users/524084/products/snapscene/snapscenebackups/1148/download",
      filename: null,
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: null,
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
  ],
  links: {
    downloads: [
      {
        title: "Snap Scene",
        url: "/support/snap-scene",
      },
    ],
    training: [
      {
        title: "Snap Scene Training & Support",
        url: "https://www.tobiidynavox.com/support-training/snap-scene-app/",
      },
    ],
    resources: [
      {
        title: "Pathways for Snap Scene",
        url: "https://itunes.apple.com/us/app/tobii-dynavox-pathways-companion/id1122070749?ls=1&mt=8",
      },
    ],
    documentation: [
      {
        title: "Snap Scene Quick Start Guide",
        url: "https://download.mytobiidynavox.com/SnapScene/documents/TobiiDynavox_SnapScene_QuickStartGuide_v1.2_en-US.pdf",
      },
      {
        title: "Snap Scene User's Manual",
        url: "https://download.mytobiidynavox.com/SnapScene/documents/TobiiDynavox_SnapScene_UserManual_v1.2_en-US.pdf",
      },
    ],
  },
  supportedFileTypes: {
    backups: [".ssp"],
  },
};

export const symbolMateMockData = {
  name: "symbolmate",
  title: "SymbolMate",
  supported: false,
  productWarning: "This product is no longer supported.",
  tabs: [
    { title: "Overview", path: "" },
    { title: "Pagesets", path: "pagesets" },
  ],
  pagesets: [
    {
      id: "40048",
      userId: 524084,
      title: "Automated SymbolMate thahindma",
      description: "",
      dateCreatedUtc: "2019-09-10T11:12:16.517",
      sizeInBytes: "60171",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/40048",
      filename: "TestSymbolMate.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "40038",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-09-10T11:02:54.263",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/40038",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "38191",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-08-20T13:31:25.013",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/38191",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "36991",
      userId: 524084,
      title: "Automated SymbolMate waindwir",
      description: "",
      dateCreatedUtc: "2019-07-29T18:34:06.18",
      sizeInBytes: "60171",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/36991",
      filename: "TestSymbolMate.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36980",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-07-29T18:19:59.76",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/36980",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "36973",
      userId: 524084,
      title: "Automated SymbolMate alousana",
      description: "",
      dateCreatedUtc: "2019-07-29T17:54:31.157",
      sizeInBytes: "60171",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/36973",
      filename: "TestSymbolMate.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "36962",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-07-29T17:41:46.04",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/36962",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "33187",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-05-28T12:09:38.36",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/33187",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "33180",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-05-28T12:03:22.28",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/33180",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "33177",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-05-28T11:02:52.89",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/33177",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
    {
      id: "32733",
      userId: 524084,
      title: "Automated SymbolMate noncoffof",
      description: "",
      dateCreatedUtc: "2019-05-22T16:33:20.783",
      sizeInBytes: "60171",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/32733",
      filename: "TestSymbolMate.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: null,
      isOwner: false,
      isPublic: false,
      categories: [],
      designedForVersion: null,
      downloads: 0,
      languageCode: null,
      imageUrl: null,
    },
    {
      id: "32721",
      userId: 524084,
      title: "Trumpet S32",
      description: "S32 4 Panel Trumpet Different Music Styles",
      dateCreatedUtc: "2019-05-22T16:20:20.027",
      sizeInBytes: "6144655",
      software: "SymbolMate",
      downloadUrl: "/api/PagesetCentral/Download/32721",
      filename: "trumpet_s32.smd",
      tags: [],
      userDocumentId: null,
      owner: null,
      author: "Jamie Barber",
      isOwner: false,
      isPublic: false,
      categories: [7],
      designedForVersion: null,
      downloads: 0,
      languageCode: "tr",
      imageUrl: null,
    },
  ],
};

export const allMockData = {
  name: "all",
  title: "Accessible Literacy Learning (ALL™)",
  supported: true,
  tabs: [
    { title: "Overview", path: "" },
    { title: "Books", path: "books" },
    { title: "Students", path: "students" },
  ],
  links: {
    downloads: [
      {
        title: "Accessible Literacy Learning (ALL)",
        url: "support/accessible-literacy-learning",
      },
    ],
    training: [
      {
        title: "Support & Training for ALL",
        url: "https://www.tobiidynavox.com/blogs/support-articles/tagged/accessible-literacy-learning",
      },
    ],
    resources: [
      {
        title: "Getting Started: ALL",
        url: "https://www.tobiidynavox.com/products/accessible-literacy-learning",
      },
    ],
    documentation: [
      {
        title: "ALL Quick Start Guide",
        url: "https://download.mytobiidynavox.com/ALL/documents/TobiiDynavox_ALL_QuickStartGuide_v1-2_en-US_WEB.pdf",
      },
      {
        title: "ALL User's Manual",
        url: "https://download.mytobiidynavox.com/ALL/documents/TobiiDynavox_ALL_UsersManual_v1-4_en-US_WEB.pdf",
      },
    ],
  },
  books: [
    {
      id: 2241,
      name: "Balloon Animals",
      backupDateUtc: "8/26/2024 3:01:39 PM",
    },
    {
      id: 2240,
      name: "At The Magic Show",
      backupDateUtc: "8/26/2024 3:01:32 PM",
    },
    {
      id: 2239,
      name: "A Fish Tale",
      backupDateUtc: "8/26/2024 3:01:25 PM",
    },
  ],
  students: [
    {
      id: 4039,
      name: "August26",
      backupDateUtc: "8/26/2024 3:02:20 PM",
    },
    {
      id: 4038,
      name: "March28",
      backupDateUtc: "8/26/2024 3:01:59 PM",
    },
  ],
};

export const usageReportsMockData = {
  userDevices: [
    { label: "Show All", value: "" },
    { label: "Daphne on DAPHNES DEVICE", value: "DAPHNES DEVICE" },
    {
      label: "Rocky on Rockys DEVICE",
      value: "Rockys DEVICE",
    },
    {
      label: "Nemi on Nemis DEVICE",
      value: "Nemis DEVICE",
    },
  ],
  events: [
    { count: 10, date: "8/2/2024" },
    { count: 5, date: "8/6/2024" },
    { count: 2, date: "8/23/2024" },
  ],
  eventMin: 1,
  eventMax: 10,
  messages: [
    {
      count: 2,
      message: "can",
    },
    {
      count: 2,
      message: "do",
    },
    {
      count: 1,
      message: "that",
    },
    {
      count: 1,
      message: "I",
    },
    {
      count: 1,
      message: "you",
    },
    {
      count: 1,
      message: "Speak",
    },
    {
      count: 1,
      message: "this",
    },
    {
      count: 1,
      message: "?",
    },
  ],
  modelingOptions: [
    { label: "Button Usage Count", value: "button" },
    { label: "Modeling Count", value: "model" },
  ],
};

export const notificationsMockData = {
  title: "Notifications",
  tabName: "Share Notifications",
  current: [
    {
      pagesetShareRequestId: 127801,
      pagesetId: "279926",
      dateSharedUtc: "2022-12-09T14:11:04.283",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user2@example.com",
      shareFromUsername: "user2",
      shareToUsername: "user1",
      title: "Motor Plan 66",
      product: "SnapCoreFirst",
      itemType: "pageset",
      action: "share_copy",
      dateResolved: "0001-01-01T00:00:00",
      result: null,
    },
    {
      pagesetShareRequestId: 128012,
      pagesetId: "271702",
      dateSharedUtc: "2022-12-12T12:54:24.38",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1",
      title: "MessageBank Various Bits 2",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "share_copy",
      dateResolved: "2022-12-12T12:54:24.41",
      result: "",
    },
    {
      pagesetShareRequestId: 172654,
      pagesetId: "1131",
      dateSharedUtc: "2023-09-07T14:42:21.023",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1",
      title: "Fig and Leo",
      product: "ALL",
      itemType: "books",
      action: "share_copy",
      dateResolved: "2023-09-07T14:42:21.07",
      result: "",
    },
    {
      pagesetShareRequestId: 172656,
      pagesetId: "363233",
      dateSharedUtc: "2023-09-07T14:43:14.817",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1",
      title: "MessageBank 2023-06-30 10:00",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "share_copy",
      dateResolved: "2023-09-07T14:43:14.833",
      result: "",
    },
    {
      pagesetShareRequestId: 183747,
      pagesetId: "386159",
      dateSharedUtc: "2023-10-23T13:46:35.44",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1",
      title: "Phonemes",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "share_copy",
      dateResolved: "2023-10-23T13:46:35.47",
      result: "",
    },
    {
      pagesetShareRequestId: 183773,
      pagesetId: "386140",
      dateSharedUtc: "2023-10-23T14:29:40.097",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1",
      title: "Angela",
      product: "SnapCoreFirst",
      itemType: "backup",
      action: "share_copy",
      dateResolved: "2023-10-23T14:29:40.113",
      result: "",
    },
    {
      pagesetShareRequestId: 213683,
      pagesetId: "486585",
      dateSharedUtc: "2024-03-11T12:46:12.773",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user1@example.com",
      shareFromUsername: null,
      shareToUsername: "user1@example.com",
      title: "MessageBank 2024-03-11 8:46",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "messagebundle_success",
      dateResolved: "0001-01-01T00:00:00",
      result: null,
    },
    {
      pagesetShareRequestId: 240328,
      pagesetId: "554961",
      dateSharedUtc: "2024-06-24T08:54:36.11",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user1@example.com",
      shareFromUsername: null,
      shareToUsername: "user1@example.com",
      title: "MessageBank 2024-06-24 10:54",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "messagebundle_success",
      dateResolved: "0001-01-01T00:00:00",
      result: null,
    },
  ],
  previous: [
    {
      pagesetShareRequestId: 127802,
      pagesetId: "7fbd5f45-809d-4ca9-b335-8549e1fa09fc",
      dateSharedUtc: "2022-12-09T14:11:37.493",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user2@example.com",
      shareFromUsername: "user2",
      shareToUsername: "user1@example.com",
      title: "Motor Plan 66",
      product: "SnapCoreFirst",
      itemType: "pageset",
      action: "share_sync",
      dateResolved: "2024-08-26T15:02:26.403",
      result: "Accepted",
    },
    {
      pagesetShareRequestId: 108356,
      pagesetId: "9d8a6b23-2b14-4481-ab21-9668f46a0434",
      dateSharedUtc: "2022-07-21T11:33:57.31",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1@example.com",
      title: "Core First",
      product: "SnapCoreFirst",
      itemType: "pageset",
      action: "share_sync",
      dateResolved: "2022-07-21T11:45:47",
      result: "Accepted",
    },
    {
      pagesetShareRequestId: 108324,
      pagesetId: "168082",
      dateSharedUtc: "2022-07-20T17:51:48.973",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1@example.com",
      title: "Aphasia Page Set",
      product: "SnapCoreFirst",
      itemType: "pageset",
      action: "share_copy",
      dateResolved: "2022-07-20T17:51:49.003",
      result: "",
    },
    {
      pagesetShareRequestId: 107348,
      pagesetId: "187417",
      dateSharedUtc: "2022-07-06T18:24:31.66",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user3@example.com",
      shareFromUsername: "user3",
      shareToUsername: "user1@example.com",
      title: "Cool Phrases/Slang/Pop Culture  QuickFires",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "share_copy",
      dateResolved: "2022-07-14T21:53:28.87",
      result: "Accepted",
    },
    {
      pagesetShareRequestId: 40936,
      pagesetId: "73479",
      dateSharedUtc: "2020-05-15T12:46:59.083",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user1@example.com",
      shareFromUsername: null,
      shareToUsername: "user1@example.com",
      title: "MessageBank 2020-05-15 8:45",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "messagebundle_success",
      dateResolved: "2022-05-09T12:16:29.13",
      result: "Accepted",
    },
    {
      pagesetShareRequestId: 41493,
      pagesetId: null,
      dateSharedUtc: "2020-05-26T13:50:06.31",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user1@example.com",
      shareFromUsername: null,
      shareToUsername: "user1@example.com",
      title: null,
      product: "SnapCoreFirst",
      itemType: "package",
      action: "messagebundle_error",
      dateResolved: "2022-05-09T12:16:28.193",
      result: "Accepted",
    },
    {
      pagesetShareRequestId: 41521,
      pagesetId: "74872",
      dateSharedUtc: "2020-05-26T16:03:51.69",
      shareToUserEmail: "user1@example.com",
      shareFromUserEmail: "user1@example.com",
      shareFromUsername: null,
      shareToUsername: "user1@example.com",
      title: "MessageBank 2020-05-26 12:03",
      product: "SnapCoreFirst",
      itemType: "package",
      action: "messagebundle_success",
      dateResolved: "2022-05-09T12:16:27.287",
      result: "Declined",
    },
  ],
};
export const SubscriptionsMockData = [
  {
    id: 0,
    subscriptionId: "sub-id",
    subscriptionTypeId: 0,
    subscriptionTerm: "sub-term",
    productName: "product-name",
    dateCreated: "2024-10-21T07:48:26.961Z",
    contractStartDate: "2024-10-21T07:48:26.961Z",
    contractEndDate: "2024-10-21T07:48:26.961Z",
    serviceStartDate: "2024-10-21T07:48:26.961Z",
    serviceEndDate: "2024-10-21T07:48:26.961Z",
    isAutoRenew: true,
    enabled: true,
    trialStatus: "trial-status",
    cancelledMessage: "cancelled-msg",
    fastSpring: {
      status: "status",
      statusChanged: "2024-10-21T07:48:26.961Z",
      cancelable: true,
      reference: "reference",
      test: true,
      customer: {
        firstName: "first-name",
        lastName: "last-name",
        email: "e-mail",
      },
      customerUrl: "customer-url",
      productName: "product-name2",
      tags: "tags",
      quantity: 0,
      nextPeriodDate: "2024-10-21T07:48:26.961Z",
    },
  },
];
