import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMessageBankMessages } from "@services";
import Breadcrumb from "@common/Breadcrumb";

const MessageBankMessages = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [messagesData, setMessagesData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMessageBankMessages(languageCode);
      setMessagesData(data);
    };
    fetchData();
  }, [languageCode]);

  return (
    messagesData && (
      <>
        <div className="container-full">
          <div className="container myStuff-header">
            <h1>{messagesData.title}</h1>
            <Breadcrumb
              items={[
                { label: messagesData.title, href: "/morestuff/messagebank" },
                {
                  label: "My Messages",
                },
              ]}
            />
          </div>
        </div>
        <div className="container-full">
          <div className="container" style={{ paddingBottom: 0 }}>
            <div className="tab-link-set">
              <Link to="/morestuff/messagebank">
                {messagesData.supportTabText}
              </Link>
              <Link to="/morestuff/messagebank/messages" className="active">
                {messagesData.messageBankTabText}
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default MessageBankMessages;
