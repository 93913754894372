import React, { useEffect, useState } from "react";
import Breadcrumb from "@common/Breadcrumb";
import TabLink from "@common/TabLink/Index";
import { getActiveNotifications, getPreviousNotifications } from "@services";
import { useSelector } from "react-redux";
import CurrentNotifications from "./CurrentNotifications";
import PreviousNotifications from "./PreviousNotifications";

const Notifications = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [activeNotifications, setActiveNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);

  const fetchActiveNotifications = async () => {
    const data = await getActiveNotifications(languageCode);
    setActiveNotifications(data);
  };

  const fetchPreviousNotifications = async () => {
    const data = await getPreviousNotifications(languageCode);
    setPreviousNotifications(data);
  };

  useEffect(() => {
    fetchActiveNotifications();
  }, [languageCode]);

  useEffect(() => {
    fetchPreviousNotifications();
  }, [languageCode]);

  const handleActionComplete = async () => {
    await fetchActiveNotifications();
    await fetchPreviousNotifications();
  };

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>{translations.Notifications_lbl}</h1>
          <Breadcrumb
            items={[
              {
                href: "/mystuff/notifications",
                label: translations.Notifications_lbl,
              },
              {
                label: "Overview",
              },
            ]}
          />
        </div>
      </div>
      <div className="container-full">
        <div className="container" style={{ paddingBottom: 0 }}>
          <div className="tab-link-set">
            <TabLink key="share" to="/mystuff/notifications" end>
              {translations.MYTD_shareNotificationsLbl}
            </TabLink>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <CurrentNotifications
            notificationsData={activeNotifications}
            translations={translations}
            handleActionComplete={handleActionComplete}
          />
          <PreviousNotifications
            notificationsData={previousNotifications}
            translations={translations}
          />
        </div>
      </div>
    </>
  );
};

export default Notifications;
