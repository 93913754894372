import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Translation from "../Translation/Index";

const Pagination = ({
  itemsPerPage,
  searchResult,
  handleFilterChange,
  setPagination,
  pagination,
  setCurrentItems,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    pageMin: 1,
    pageMax: itemsPerPage,
  });
  const { pagesetDocuments: items, total = 0 } = searchResult;

  useEffect(() => {
    setCurrentItems(items);
    const calculatedPageCount = Math.max(Math.ceil(total / itemsPerPage), 1);
    setPageCount(calculatedPageCount);
    setPageInfo({
      pageMin: pagination.itemOffset + 1,
      pageMax: Math.min(pagination.itemOffset + itemsPerPage, total),
    });
  }, [items, itemsPerPage, total, pagination.itemOffset]);

  const handlePageClick = ({ selected }) => {
    setPagination({
      itemOffset: (selected * itemsPerPage) % total,
      pageNumber: selected,
    });
    handleFilterChange("page", selected);
  };

  return (
    <div className="col-sm-12">
      <div className="clearfix pull-right">
        <div className="pull-right">
          <ReactPaginate
            className="pagination pagination-lg pagination"
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            forcePage={Math.min(pagination.pageNumber, pageCount - 1)}
          />
        </div>
        <h4 className="pull-right paging-status">
          <span className="hidden-xs hidden-sm">
            <Translation translate="MYTD_showingLabel" />{" "}
          </span>
          <span className="hidden-xs">
            <strong>{pageInfo.pageMin}</strong>-
            <strong>{pageInfo.pageMax}</strong>{" "}
            <Translation translate="MYTD_ofLabel" /> <strong>{total}</strong>
          </span>
          <span className="hidden-xs hidden-sm">
            {" "}
            <Translation translate="MYTD_resultsLabel" />
          </span>
        </h4>
      </div>
    </div>
  );
};

export default Pagination;
