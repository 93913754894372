import { createReducer } from "@reduxjs/toolkit";
import { setCommonFailure, setRedirectionData } from "../actions";

const initialState = {
  redirections: null,
};

const commonDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRedirectionData, (state, action) => {
      state.redirections = action.payload;
    })
    .addCase(setCommonFailure, (state, action) => {
      state.error = action.payload;
    });
});

export default commonDataReducer;
