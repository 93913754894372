import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getPageSetData } from "@services";
import FilterComponent from "./Filter";
import Breadcrumb from "@common/Breadcrumb";
import RawHTML from "@common/RawHTML/Index";
import ResultComponent from "./Results";

const PagesetCentralHome = () => {
  const initialFilters = {
    text: "",
    software: [],
    category: [],
    languageCode: [],
    page: 0,
  };

  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResult, setSearchResult] = useState({});
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState({
    itemOffset: 0,
    pageNumber: 0,
  });

  const handleFilterChange = useCallback((filterType, filterValue) => {
    setSearchInProgress(true);
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (filterType === "page") {
        updatedFilters.page = filterValue;
      } else if (filterType === "text") {
        updatedFilters.text = filterValue;
      } else {
        const filterArray = updatedFilters[filterType];
        updatedFilters[filterType] = filterArray.includes(filterValue)
          ? filterArray.filter((f) => f !== filterValue)
          : [...filterArray, filterValue];
      }
      return updatedFilters;
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(initialFilters);
    setSearchInProgress(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const searchResult = await getPageSetData(languageCode, filters);
      setSearchResult(searchResult);
      setSearchInProgress(false);
    };

    const timerId = setTimeout(fetchData, 500);
    return () => clearTimeout(timerId);
  }, [filters, languageCode]);

  if (!translations) return null;

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>
            {translations.Pageset_Central}
            <sup
              className="icon icon-pageset-central icon-34"
              style={{ float: "none" }}
              title="Pageset Central"
            ></sup>
          </h1>
          <Breadcrumb
            items={[
              {
                label: translations.Pageset_Central,
              },
            ]}
          />
        </div>
        <div className="container" style={{ paddingBottom: "0" }}>
          <div className="intro-section">
            <RawHTML htmlContent={translations.Pageset_Central_Intro} />
          </div>
        </div>
      </div>
      <div className="container results" style={{ paddingBottom: "0" }}>
        <div
          id="pagesetResults"
          className="row"
          style={{ minHeight: "1000px" }}
        >
          <div className="col-md-4">
            <FilterComponent
              translations={translations}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
              searchInProgress={searchInProgress}
              searchResult={searchResult}
            />
          </div>
          <div className="col-md-8">
            {searchInProgress ? (
              <h2>
                {translations.Pagesets_Message_Loading}{" "}
                <i className="fa fa-refresh fa-spin"></i>
              </h2>
            ) : (
              <ResultComponent
                itemsPerPage={12}
                translations={translations}
                searchResult={searchResult}
                handleFilterChange={handleFilterChange}
                setPagination={setPagination}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PagesetCentralHome;
