import React from "react";
import { useOutletContext } from "react-router-dom";
import Translation from "@common/Translation/Index";
import DeviceImage from "./DeviceImage";
import DeviceDetails from "./DeviceDetails";
import DeviceAttributes from "./DeviceAttributes";

const tokenLabel = (lbl, translations) => {
  const labelMap = {
    Subscription: translations.MYTD_SubscriptionLbl,
    Complimentary: translations.MYTD_ComplimentaryLbl,
    Companion: translations.MYTD_CompanionLbl,
    Trial: translations.MYTD_TrialLbl,
    Perpetual: translations.MYTD_PerpetualLbl,
    Account: translations.MYTD_AccountLbl,
  };
  return labelMap[lbl] || lbl;
};

const Devices = () => {
  const { data, product, translations, languageCode } = useOutletContext();

  return (
    <div className="row margin-top-md">
      {data.devices.map((device) => {
        const speakingPermission = device.speakingPermission
          ? tokenLabel(device.speakingPermission, translations)
          : null;

        return (
          <div
            className="col-xs-12 pageset-block-col fade-block fade-in"
            key={device.id}
          >
            <div className="well well-lg well-device clearfix">
              <div className="row device-specs">
                <DeviceImage imageUrl={device.imageUrl} />
                <div className="col-xs-12">
                  {product === "compass" ? (
                    <h3>
                      {device.softwareTitle || ""}{" "}
                      <Translation translate="MYTD_forTxt" textonly="true" />{" "}
                      {device.deviceTitle}
                    </h3>
                  ) : (
                    <h3>{device.title}</h3>
                  )}
                  <DeviceDetails
                    device={device}
                    product={product}
                    languageCode={languageCode}
                    speakingPermission={speakingPermission}
                    detach={data.detach}
                  />
                  <DeviceAttributes
                    device={device}
                    translations={translations}
                    tokenLabel={tokenLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Devices;
