import React from "react";

const SubProductsList = ({ subProducts }) => (
  <>
    {subProducts.map((prod, index) => (
      <p className="note" key={`${prod.name}-${index}`}>
        <strong>{prod.name}</strong>
        <br />
        {prod.notes.map((note, index) => (
          <span key={`${note}-${index}`}>{note}</span>
        ))}
      </p>
    ))}
  </>
);

export default SubProductsList;
