import React from "react";
import Breadcrumb from "@common/Breadcrumb";

const Header = ({ title = "Hello!" }) => {
  return (
    <div className="container-full">
      <div className="container myStuff-header fade-block fade-in">
        <h1>{title}</h1>
        <Breadcrumb items={[]} />
      </div>
    </div>
  );
};

export default Header;
