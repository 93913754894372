import { postData } from "./base-service";

const PRODUCT_API_ENDPOINTS = {
  addProduct: (product) => `/v1/products/${product}`,
};

const postAddMyProduct = (payload) => {
  return postData(PRODUCT_API_ENDPOINTS.addProduct(payload), {});
};


const getUserIPAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error.message);
    return null;
  }
};

const getMockData = () => {
  return [
    {
      productPath: "eb5ef6f5-8874-464e-be7e-02b0daed2cad",
      price: "$149.99",
    },
    {
      productPath: "94681118-4f69-49a4-9e31-4b225e08430d",
      price: "$49.99",
    },
    {
      productPath: "a89d7de4-7cc9-4a43-9fc0-8acdbcbe384f",
      price: "$149.99",
    },
    {
      productPath: "4c8af06b-fbbd-46ba-8c3a-c356aecbbd41",
      price: "$149.99",
    },
    {
      productPath: "361a0b30-31c6-4684-a42b-d85dea99a5ab",
      price: "$49.99",
    },
    {
      productPath: "abc57091-a19d-4972-8957-8fc8c7324aa1",
      price: "$39.99",
    },
  ];
};

const getFastSpringPrice = async (productPath, fastspringId = "") => {
  const ip = await getUserIPAddress();

  const apiUrl = "/store/products";
  const apiKey = "rubicon";

  const requestBody =
    fastspringId == null || fastspringId === ""
      ? { productIds: [productPath] }
      : { fastspringIds: [fastspringId] };

  const requestOptions = {
    method: "POST",
    headers: {
      "X-API-Key": apiKey,
      "X-Forwarded-For": ip,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };

  try {
    //const response = await baseService.post(`store/products`, requestOptions);
    const response = getMockData().find((i) => i.productPath === productPath);
    return response.price;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching product pricing:", error.message);
    return "";
  }
};

const getFastSpringPriceV1 = (productPath) => {
  const fastSpringPrices = [
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "communicator5full",
      Price: 699.0,
      DisplayValue: "$699.00",
      HtmlValue: "$699.00",
      PriceIncludingVAT: 699.0,
      DisplayValueIncludingVAT: "$699.00",
      HtmlValueIncludingVAT: "$699.00",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "communicator5_us",
      Price: 99.0,
      DisplayValue: "$99.00",
      HtmlValue: "$99.00",
      PriceIncludingVAT: 99.0,
      DisplayValueIncludingVAT: "$99.00",
      HtmlValueIncludingVAT: "$99.00",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "snapscene",
      Price: 49.99,
      DisplayValue: "$49.99",
      HtmlValue: "$49.99",
      PriceIncludingVAT: 49.99,
      DisplayValueIncludingVAT: "$49.99",
      HtmlValueIncludingVAT: "$49.99",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "all",
      Price: 79.99,
      DisplayValue: "$79.99",
      HtmlValue: "$79.99",
      PriceIncludingVAT: 79.99,
      DisplayValueIncludingVAT: "$79.99",
      HtmlValueIncludingVAT: "$79.99",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "earlyinterventionbundle",
      Price: 109.0,
      DisplayValue: "$109.00",
      HtmlValue: "$109.00",
      PriceIncludingVAT: 109.0,
      DisplayValueIncludingVAT: "$109.00",
      HtmlValueIncludingVAT: "$109.00",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "gazeviewer_subs",
      Price: 399.0,
      DisplayValue: "$399.00",
      HtmlValue: "$399.00",
      PriceIncludingVAT: 399.0,
      DisplayValueIncludingVAT: "$399.00",
      HtmlValueIncludingVAT: "$399.00",
      IncludessVAT: true,
    },
    {
      UserCountry: "US",
      CurrencyCode: "USD",
      ProductPath: "tobiigazeviewer_once",
      Price: 349.0,
      DisplayValue: "$349.00",
      HtmlValue: "$349.00",
      PriceIncludingVAT: 349.0,
      DisplayValueIncludingVAT: "$349.00",
      HtmlValueIncludingVAT: "$349.00",
      IncludessVAT: true,
    },
  ];
  const price = fastSpringPrices.find((x) => x.ProductPath === productPath);
  let displayPrice = price.IncludessVAT
    ? price.HtmlValueIncludingVAT
    : price.HtmlValue;
  if (price.UserCountry !== "US" && !price.IncludessVAT) {
    displayPrice += " ";
  }
  const displayVAT = shouldDisplayVAT(fastSpringPrices);
  const displayPriceComponent = { displayPrice, displayVAT };

  return displayPriceComponent;
};

const shouldDisplayVAT = (fastSpringPrices, force = false) => {
  const nonUSProductsWithoutVAT = fastSpringPrices.filter(
    (x) => !x.IncludessVAT && x.UserCountry !== "US",
  );
  return force || nonUSProductsWithoutVAT.length > 0;
};

const getiTunesPrices = async (productIds, countryCode) => {
  const baseurl =
    countryCode.toLowerCase() === "us"
      ? "http://itunes.apple.com/lookup?id="
      : `http://itunes.apple.com/${countryCode.toLowerCase()}/lookup?id=`;

  const prices = {};

  for (const productId of productIds) {
    let url = `${baseurl}${productId}`;

    try {
      let result = await fetch(url);
      let data = await result.json();

      const resultCount = data.resultCount;

      // Read the US price if the iOS Search API returned nothing.
      if (!data || resultCount === 0) {
        url = `http://itunes.apple.com/lookup?id=${productId}`;
        result = await fetch(url);
        data = await result.json();
      }

      if (data && data.results[0]) {
        const formattedPrice = data.results[0].formattedPrice;
        prices[productId] = formattedPrice;
      }
    } catch (error) {
      console.error(error);
      // Handle errors as needed
    }
  }

  return prices;
};

export {
  getFastSpringPrice,
  getFastSpringPriceV1,
  getiTunesPrices,
  postAddMyProduct,
};
