import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Moment from "react-moment";
import Table from "@common/Table";
import Translation from "@common/Translation/Index";
import { useSelector } from "react-redux";
import ShareModal from "./ShareModal";
import DeleteModal from "./DeleteModal";

const Books = () => {
  const { data } = useOutletContext();
  const { translations } = useSelector((state) => state.translations);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const cols = [
    <Translation translate="MYTD_backupDateLbl" key="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_titleTxt" key="MYTD_titleTxt" />,
    "",
  ];

  return (
    <div>
      <Table cols={cols}>
        {data.books.map((item, k) => {
          return (
            <tr key={k}>
              <td style={{ width: "125px" }}>
                <Moment format="l">{item.backupDateUtc}</Moment>
              </td>
              <td>{item.name}</td>
              <td>
                <span>
                  <>
                    <a
                      href="#"
                      onClick={() => {
                        setActiveItem(item);
                        setShowShareModal(true);
                      }}
                    >
                      <Translation translate="MYTD_shareBtnTxt" />
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        setActiveItem(item);
                        setShowDeleteModal(true);
                      }}
                    >
                      <Translation translate="MYTD_deleteLbl" />
                    </a>
                  </>
                </span>
              </td>
            </tr>
          );
        })}
      </Table>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        name={activeItem.name}
      />
      <ShareModal
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        item={activeItem}
        translations={translations}
      />
    </div>
  );
};

export default Books;
