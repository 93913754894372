import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserOrders } from "../my-account-service";
import Translation from "../../../common/Translation/Index";
import OrderHeader from "./OrderHeader";
import NoOrdersMessage from "./NoOrdersMsg";
import OrderDetails from "./OrderDetails";
import CollapseComponent from "./CollapseComponent";

const Orders = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchUserOrders = async () => {
      const data = await getUserOrders(languageCode);
      setOrders(data);
    };
    fetchUserOrders();
  }, [languageCode]);

  return orders && orders.length > 0 ? (
    <div className="fade-block fade-in">
      <br />
      <div className="row">
        <div className="col-xs-12">
          <div className="panel-group" id="accordion">
            <OrderHeader />
            {orders?.map((orderData, i) => (
              <OrderDetails key={i} orderData={orderData} />
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="fade-block fade-in">
      <br />
      <div className="row">
        <div className="col-xs-12">
          <NoOrdersMessage />
        </div>
      </div>
    </div>
  );
};

export default Orders;
