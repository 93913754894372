import React from "react";
import RawHTML from "@common/RawHTML/Index";

const Application = ({ applications, translations }) => {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-sm-8 col-sm-push-2">
          <h2>{translations.Applications}</h2>
        </div>
      </div>

      {applications.applications.map((app, index) => (
        <div
          key={index}
          className="well well-white text-left"
          style={{ padding: "0 15px" }}
        >
          <div className="row equal">
            <div className="col-12 col-md-8 equal-content equal-main">
              <p>
                <strong>{app.title}</strong>
              </p>
              <div>
                <RawHTML htmlContent={app.description} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="row">
                {app.apps.map((subApp, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <hr className="visible-xs-block visible-sm-block" />
                    <div className="col-12 equal-content">
                      <div className="pull-left">
                        {subApp.system === "Windows" ? (
                          <>
                            {translations.Windows}
                            <br />
                            <strong>{translations.Free}</strong>
                          </>
                        ) : (
                          <>
                            {translations.iPad}
                            <br />
                            <strong>{translations.Free}</strong>
                          </>
                        )}
                      </div>
                      <div className="pull-right text-right">
                        {subApp.system === "Windows" ? (
                          <a
                            href="https://download.mytobiidynavox.com/Snap/installers/td-distribute/Snap.WindowsUWP.OEM.appinstaller"
                            target="_blank"
                            className="btn btn-lg btn-windows"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/images/btn/badge-download-for-windows.svg"
                              alt={translations.Download_for_Windows}
                            />
                          </a>
                        ) : (
                          <a
                            href="https://itunes.apple.com/us/app/snap-core-first/id1072799231?ls=1&amp;mt=8"
                            target="_blank"
                            className="btn btn-lg btn-apple"
                            id="ios-free-link"
                            rel="noreferrer"
                          >
                            <img
                              src="/assets/images/btn/badge-download-on-the-app-store.svg"
                              alt={translations.Download_on_the_App_Store}
                            />
                          </a>
                        )}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Application;
