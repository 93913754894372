import React from "react";
import Modal from "react-bootstrap/Modal";
import Translation from "@common/Translation/Index";

const DetachModal = ({ device, showModal, setShowModal, detach }) => {
  const closeModal = () => setShowModal(false);

  const companions = {};
  if (!!device.companions && !!device.companions.length) {
    companions.speech = [];
    companions.podd = [];
    companions.gateway = [];
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.speech") {
        companions.speech.push(entry.value);
        companions.speech = companions.speech.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.podd") {
        companions.podd.push(entry.value);
        companions.podd = companions.podd.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.gateway") {
        companions.gateway.push(entry.value);
        companions.gateway = companions.gateway.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });

    companions.speech = companions.speech.length
      ? companions.speech.join(", ")
      : companions.speech;
    companions.podd = companions.podd.length
      ? companions.podd.join(", ")
      : companions.podd;
    companions.gateway = companions.gateway.length
      ? companions.gateway.join(", ")
      : companions.gateway;
  }

  const companionPermissions = {
    thisTitle: device.title,
    poddCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.podd") ||
      false,
    gatewayCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.gateway") ||
      false,
    speechPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.speech") ||
      false,
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className={showModal ? "in" : ""}
      >
        <Modal.Header>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Modal.Title>
            {device.detachLink ? (
              <h2>
                <Translation translate="MYTD_DetachDeviceLbl" />
              </h2>
            ) : (
              <h2>
                <Translation translate="MYTD_deactivateLbl" />
              </h2>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {device.detachLink
              ? device.isPerpertual
                ? detach.detachPerpetualMessage
                : detach.detachMessage
              : detach.deactivateMessage}
          </p>
          {!device.detachLink && (
            <table className="table devices SnapDetachModal">
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translation translate="MYTD_DetachDeviceLbl" />
                    </strong>
                  </td>
                  <td>
                    <p>{device.title}</p>
                    {device.inAppSpeakingPermission && (
                      <span>
                        {" "}
                        {device.softwareTitle}{" "}
                        <Translation translate="MYTD_detachWarnMsg2" />
                      </span>
                    )}
                  </td>
                </tr>
                {!!companions.speech && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="MYTD_detachSpeakingLbl" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.speech}</p>
                      <span>
                        <Translation translate="MYTD_detachDeviceWarn" />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companions.podd && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> PODD{" "}
                        <Translation translate="Companion" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.podd}</p>
                      <span>
                        <Translation
                          translate="Deactivate_companion"
                          textonly={true}
                          replaceArray={["PODD", "PODD"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companions.gateway && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> Gateway{" "}
                        <Translation translate="Companion" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.gateway}</p>
                      <span>
                        <Translation
                          translate="Deactivate_companion"
                          textonly={true}
                          replaceArray={["Gateway", "Gateway"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companionPermissions.speechPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="MYTD_deactivateSpeechLbl" />
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation translate="MYTD_deactivateWarnMsg2" />
                      </span>
                    </td>
                  </tr>
                )}

                {!!companionPermissions.gatewayCompanionPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> Gateway
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation
                          translate="Deactivate_permission"
                          textonly={true}
                          replaceArray={["Gateway"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}

                {!!companionPermissions.poddCompanionPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> PODD
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation
                          translate="Deactivate_permission"
                          textonly={true}
                          replaceArray={["PODD"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {!device.detachLink && <p>{detach.deactivateWarning}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              // TODO: Handle the action here
              closeModal();
            }}
            className="btn btn-lg btn-primary"
          >
            <Translation translate="MYTD_detachLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetachModal;
