import React from "react";
import Translation from "../Translation/Index";

const PageContent = () => {
  return {
    myAccount: {
      subscriptions: {
        compassCompanionMsg: <Translation translate="MYTD_noSubsFoundMsg" />,
        professionalsTitle: <Translation translate="MYTD_tdForProsLbl" />,
        proSubscriptionMsg: <Translation translate="MYTD_reverifyMsg1" />,
        proCancelSubBtnTxt: <Translation translate="MYTD_cancelSubBtnTxt" />,
      },
    },
    Home: {
      prePassword: {
        heroHeaderText: <Translation translate="MYTD_welcometoTDMsg" />,
        heroBlockText: <Translation translate="MYTD_TDintroTxt" />,
        loginBtnTxt: <Translation translate="MYTD_memberLoginBtnTxt" />,
        createAcctTxt: <Translation translate="Create_account" />,
        whatsInsideHeader: <Translation translate="MYTD_whatsInsideLbl" />,
        whatsInsideBlock: <Translation translate="MYTD_getAccountIntroMsg" />,
        sltHeadlineText: <Translation translate="MYTD_youSLTquestionTxt" />,
        sltBlockText: <Translation translate="MYTD_youShouldVerifyProMsg" />,
      },
    },
  };
};

export default PageContent;
