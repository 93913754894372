import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollToHash = (data) => {
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    const scrollToElement = () => {
      if (lastHash.current) {
        const element = document.getElementById(lastHash.current);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          lastHash.current = "";
        }
      }
    };

    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current) {
      requestAnimationFrame(() => {
        scrollToElement();
      });
    }
  }, [location.hash, data]);

  return null;
};

export default useScrollToHash;
