import React from "react";

const toggleX = (e) => {
  const hideSearchStyleList = e.target.classList;
  if (hideSearchStyleList.contains("hideX")) {
    hideSearchStyleList.remove("hideX");
    hideSearchStyleList.add("showX");
  } else if (hideSearchStyleList.contains("showX")) {
    hideSearchStyleList.remove("showX");
    hideSearchStyleList.add("hideX");
  }
};

const FilterInput = ({ value, onChange, onClear, placeholder }) => (
  <div className="input-group" style={{ width: 320, left: -23 }}>
    <label htmlFor="searchBox" className="input-group-addon searchIcon">
      <i className="fa fa-search fa-fw"></i>
    </label>
    <input
      placeholder={placeholder}
      type="text"
      name="searchBox"
      id="searchBox"
      className="form-control searchInput"
      value={value}
      onChange={(e) => {
        onChange(e);
        toggleX(e);
      }}
    />
    <span
      className={`input-group-addon iconStyleClear ${
        value ? "showX" : "hideX"
      }`}
      onClick={onClear}
    >
      <i className="fa fa-times fa-fw"></i>
    </span>
  </div>
);

export default FilterInput;
