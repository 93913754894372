import React, { useState } from "react";

const LinkSpinner = ({ children }) => {
  const [clicked, setIsClicked] = useState(false);

  return !clicked ? (
    <span onClick={() => setIsClicked(true)}>{children}</span>
  ) : (
    <span className="spinner-span">
      <i className="fa fa-spinner fa-pulse"></i>
    </span>
  );
};

export default LinkSpinner;
