import React from "react";
import RawHTML from "@common/RawHTML/Index";

const OtherApp = ({ otherApplications }) => {
  return (
    <div className="container text-center" key="other-applications">
      <div className="row">
        <div className="col-sm-8 col-sm-push-2">
          <h2>{otherApplications[0].title}</h2>
        </div>
      </div>

      {otherApplications.map((app, index) => (
        <React.Fragment key={`${app.name}-${index}`}>
          <div
            className="well well-white text-left"
            style={{ padding: "0 15px" }}
          >
            <div className="row equal">
              <div className="col-12 col-md-8 equal-content equal-main">
                <p>
                  <strong>{app.name}</strong>
                </p>
                <div>
                  <RawHTML htmlContent={app.description} />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <hr className="visible-xs-block visible-sm-block" />
                  <div className="col-12 equal-content">
                    <div className="pull-left">
                      <a href={app.link} target="_blank" rel="noreferrer">
                        {app.linkText}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="well text-center" style={{ padding: "0 15px" }}>
            <div className="row equal">
              <div className="equal-content equal-main">
                <div className="note">
                  <RawHTML htmlContent={app.footerNote} />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default OtherApp;
