import React, { useState, useCallback, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import Pagination from "@common/PagesetCentral/PaginationControl";
import Translation from "@common/Translation/Index";
import { filterPagesets, paginateItems } from "@helpers/utilities";
import { getPagesets } from "@services";
import FilterInput from "./FilterInput";
import CheckboxGroup from "./CheckboxGroup";
import List from "./List";

const initialFilters = {
  text: "",
  page: 0,
  optionOneChecked: false,
  optionTwoChecked: false,
};

const Pagesets = () => {
  const { product, translations } = useOutletContext();
  const { languageCode } = useSelector((state) => state.languageData);
  const [pagesets, setPagesets] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState({
    itemOffset: 0,
    pageNumber: 0,
  });
  const [currentItems, setCurrentItems] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchPagesets = useCallback(async () => {
    const data = await getPagesets(languageCode, product);
    setPagesets(data);
  }, [languageCode, product]);

  useEffect(() => {
    fetchPagesets();
  }, [fetchPagesets]);

  const tagFilters = Array.from(
    new Set(pagesets.flatMap((pageset) => pageset.tags || [])),
  );

  useEffect(() => {
    const filteredPagesets = pagesets
      ? filterPagesets(pagesets, filters, tagFilters)
      : [];
    setTotal(filteredPagesets.length);
    setCurrentItems(paginateItems(filteredPagesets, pagination.itemOffset, 10));
  }, [pagesets, filters, pagination.itemOffset]);

  const handleFilterChange = useCallback((filterType, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: filterValue,
    }));
  }, []);

  const searchResult = {
    pagesetDocuments: currentItems,
    total,
  };

  return (
    <div style={{ position: "relative" }}>
      {product !== "symbolmate" && (
        <Link
          to="upload"
          className="btn btn-primary btn-lg pull-left"
          style={{ position: "absolute", right: 0, top: -204 }}
        >
          <Translation translate="MYTD_uploadPagesetLbl" />
        </Link>
      )}
      <div className="row">
        {pagesets.length > 0 && (
          <div className="col-xs-12">
            <div className="clearfix">
              <div className="clearfix pull-right">
                <Pagination
                  setCurrentItems={setCurrentItems}
                  itemsPerPage={10}
                  handleFilterChange={handleFilterChange}
                  setPagination={setPagination}
                  pagination={pagination}
                  searchResult={searchResult}
                />
              </div>
              <div
                className="pull-left"
                style={{ marginBottom: 54, paddingTop: 48 }}
              >
                <FilterInput
                  value={filters.text}
                  onChange={(e) => handleFilterChange("text", e.target.value)}
                  onClear={() => handleFilterChange("text", "")}
                  placeholder={translations.MYTD_searchPlaceholder}
                />
              </div>
            </div>
            {tagFilters.length > 0 && (
              <div className="clearfix">
                <CheckboxGroup
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  pagesetfilters={tagFilters}
                />
              </div>
            )}
          </div>
        )}

        <div className="col-xs-12" style={{ paddingTop: 10 }}>
          <List
            items={currentItems}
            product={product}
            translations={translations}
            type="pagesets"
            fetchPagesets={fetchPagesets}
          />
        </div>

        {pagesets.length > 0 && (
          <div className="col-xs-12">
            <div className="clearfix">
              <Pagination
                setCurrentItems={setCurrentItems}
                itemsPerPage={10}
                handleFilterChange={handleFilterChange}
                setPagination={setPagination}
                pagination={pagination}
                searchResult={searchResult}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagesets;
