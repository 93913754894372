import React from "react";

const SRLinkComp = ({ linkItem, type }) => {
  if (type === "Videos")
    return (
      <div className="col-xs-12 col-sm-6 col-md-4 margin-bottom-md">
        <a
          href={linkItem.mainUrl}
          className=""
          target="_blank"
          rel="noreferrer"
          key={linkItem.mainUrl}
        >
          <img
            src={`/assets/images/${linkItem.image}`}
            alt={linkItem.mainUrlText}
            className="img-responsive img-thumbnail margin-bottom-xs"
          ></img>
        </a>
        <p>{linkItem.description}</p>
      </div>
    );
  else if (type === "Guides")
    return (
      <>
        <div className="file-type-pdf" title={linkItem.resourceType}></div>
        <div className="resource">
          <a href={linkItem.mainUrl} target="_blank" rel="noreferrer">
            {linkItem.mainUrlText}
          </a>
          <br />
          {linkItem.availability?.title}
          {linkItem.availability?.languages?.map((langItem, langIndex) => {
            return (
              <a
                href={langItem.url}
                target="_blank"
                rel="noreferrer"
                key={langItem.url}
              >
                {langIndex == 0 ? "" : ", "} {langItem.language}
              </a>
            );
          })}
          <span className="desc">
            <span>{linkItem.description}</span>
          </span>
        </div>
      </>
    );
  else {
    //Word list && message bank
    return (
      <>
        <div className="col-xs-12 col-sm-6 col-md-4 margin-bottom-md">
          <a
            href={linkItem?.mainUrl}
            className=""
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/assets/images${linkItem.image}`}
              className="img-responsive img-thumbnail margin-bottom-xs"
            ></img>
          </a>
          <p className="single-line">{linkItem?.description}</p>
        </div>
      </>
    );
  }
};
export default SRLinkComp;
