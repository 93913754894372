import { fetchData } from "./base-service";

const SUPPORT_API_ENDPOINTS = {
  supportDocuments: (langCode) => `/v1/support/documents?uic=${langCode}`,
  professionalResources: (langCode) => `/v1/support/resources?uic=${langCode}`,
  appData: (pageData, langCode) => `/v1/apps/${pageData}?uic=${langCode}`,
  availableUpdates: (langCode) => `/v1/support/updates?uic=${langCode}`,
  downloads: (langCode) => `/v1/apps/downloads?uic=${langCode}`,
  contacts: (langCode) => `/v1/support/contacts?uic=${langCode}`,
  supportSnapResources: (langCode) =>
    `/v1/support/snap-resources?uic=${langCode}`,
};

export const getSupportDocumentation = async (langCode) =>
  fetchData(SUPPORT_API_ENDPOINTS.supportDocuments(langCode));

export const getProfessionalResources = async (langCode) =>
  fetchData(SUPPORT_API_ENDPOINTS.professionalResources(langCode));

export const getAvailableUpdates = async (langCode) =>
  fetchData(SUPPORT_API_ENDPOINTS.availableUpdates(langCode));

export const getDownloadPageByName = async (pageName, langCode) => {
  return fetchData(SUPPORT_API_ENDPOINTS.appData(pageName, langCode));
};

export const getDownloadsPageData = async (langcode) => {
  return fetchData(SUPPORT_API_ENDPOINTS.downloads(langcode));
};

export const getContactPageData = async (langcode) => {
  return fetchData(SUPPORT_API_ENDPOINTS.contacts(langcode));
};

export const getSnapResources = async (langCode) => {
  return fetchData(SUPPORT_API_ENDPOINTS.supportSnapResources(langCode));
};
