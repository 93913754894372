import React from "react";

const FilterComponent = ({
  translations,
  filters,
  handleFilterChange,
  handleResetFilters,
  searchInProgress,
  searchResult,
}) => {
  return (
    <div className="filter margin-bottom-lg">
      <div className="margin-bottom-lg">
        <h2>{translations.Pagesets_Search_By}</h2>
        <form role="form">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder={translations.Pagesets_Search_Placeholder}
              value={filters.text}
              onChange={(e) => handleFilterChange("text", e.target.value)}
            />
          </div>
        </form>
        {searchInProgress && (
          <h2 className="loading-message">
            {translations.Pagesets_Message_Loading}{" "}
            <i className="fa fa-refresh fa-spin"></i>
          </h2>
        )}
        {!searchInProgress && searchResult && (
          <>
            {searchResult.aggregates?.map((filterType) => {
              return (
                <div key={filterType.name}>
                  <h2>{filterType.name}</h2>
                  <div className="custom-control-group">
                    {filterType &&
                      filterType.aggregates.map((filter) => {
                        const uniqueKey = filter.key;
                        const isChecked = (
                          filters[filterType.key] || []
                        ).includes(filter.key);
                        return (
                          <div
                            key={uniqueKey}
                            className="custom checkbox checkbox-filters"
                          >
                            <label className={`${isChecked ? "active" : ""}`}>
                              <input
                                type="checkbox"
                                name={`chk-${filter.key}`}
                                defaultChecked={isChecked}
                                onChange={() =>
                                  handleFilterChange(filterType.key, filter.key)
                                }
                              />
                              {filter.name === "SnapCoreFirst"
                                ? "Snap - (" + filter.count + ")"
                                : filter.name + " - (" + filter.count + ")"}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <button className="btn btn-lg btn-default" onClick={handleResetFilters}>
        {translations.Reset}
      </button>
    </div>
  );
};

export default FilterComponent;
