import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { codeMap } from "@helpers/districtVerifyCodes";

import FormErrors from "@common/FormErrors/Index";
import Translation from "@common/Translation/Index";
import { redirectToEdit, redirectToChangePassword } from "@services";

const ProfileForm = ({
  content,
  updateOk,
  formErrors,
  profile,
  isPreview,
  handleFileInputChange,
  clearAvatarClick,
  handleTextUpdate,
  handleDistCodeCheck,
  handleDistCodeDelete,
  toggleModal,
  showModal,
  codeChecking,
  codeValid,
  codeDeleting,
  districtCodeData,
  validateForm,
  handlePageRefresh,
}) => {
  const [occupationTypeID, setOccupationTypeID] = useState(
    profile.occupationTypeID || "0",
  );

  const [bmoDistrictCode, setBmoDistrictCode] = useState(
    profile.bmoDistrictCode || "",
  );

  const [organization, setOrganization] = useState(profile.organization || "");
  const [occupationId, setOccupationId] = useState(profile.occupationId || "0");
  const [industryId, setIndustryId] = useState(profile.industryId || "0");

  const handleOccupationTypeChange = (e) => {
    setOccupationTypeID(e.target.value);
    handleTextUpdate(e);
  };

  const handleBmoDistrictCodeChange = (e) => {
    setBmoDistrictCode(e.target.value);
    handleTextUpdate(e);
  };

  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value);
    handleTextUpdate(e);
  };

  const handleOccupationIdChange = (e) => {
    setOccupationId(e.target.value);
    handleTextUpdate(e);
  };

  const handleIndustryIdChange = (e) => {
    setIndustryId(e.target.value);
    handleTextUpdate(e);
  };

  return (
    <div id="profileForm">
      {profile && (
        <div className="container">
          <h2>{content.MYTD_updateProfileLbl}</h2>
          {!updateOk ? (
            <div className="fade-block fade-in">
              <FormErrors formErrors={formErrors} />
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <p className="control-label">
                    {profile.firstName} {profile.lastName}
                  </p>
                  <p className="control-label">{profile.email}</p>
                  <p className="control-label">
                    <Translation
                      translate={profile.address.countryTranslationKey}
                    />
                  </p>
                </div>
                <div className="col-sm-6 col-md-4">
                  <a
                    className="btn btn-default"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectToEdit();
                    }}
                  >
                    {content.MYTD_changeLbl}
                  </a>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <label className="control-label">
                    <Translation translate="Password" />: ********
                  </label>
                </div>
                <div className="col-sm-6 col-md-4">
                  <a
                    className="btn btn-default"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      redirectToChangePassword();
                    }}
                  >
                    {content.MYTD_changeLbl}
                  </a>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="AvatarUrl">
                    {content.MYTD_avatarLbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <div className="fileinput-new" data-provides="fileinput">
                    <div
                      className="fileinput-preview thumbnail img-circle img-circle-51 center-block"
                      style={{ lineHeight: "51px" }}
                    >
                      <img
                        id="avatarImage"
                        src={
                          profile.avatarUrl
                            ? profile.avatarUrl + isPreview
                            : "/assets/images/avatar.jpg?w=51"
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="btn btn-default btn-file">
                        {!profile.avatarUrl && (
                          <span>{content.MYTD_selectImgLbl}</span>
                        )}
                        {profile.avatarUrl && (
                          <span>{content.MYTD_changeLbl}</span>
                        )}
                        <input
                          type="file"
                          name="addAvatar"
                          id="addAvatar"
                          accept="image/*"
                          onChange={handleFileInputChange}
                        />
                      </span>
                      {profile.avatarUrl && (
                        <a
                          href="#"
                          className="btn btn-default margin-sm-left"
                          onClick={clearAvatarClick}
                        >
                          {content.MYTD_removeLbl}
                        </a>
                      )}
                    </div>
                    <div
                      className="field-validation-valid text-danger margin-top-sm"
                      id="avError"
                    ></div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="accountType">
                    {content.MYTD_AcctTypeLbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <select
                    className="form-control"
                    value={occupationTypeID}
                    onChange={handleOccupationTypeChange}
                    id="occupationTypeID"
                    name="occupationTypeID"
                  >
                    <option value="0">{content.MYTD_selectUserTypeLbl}</option>
                    <option value="1">{content.MYTD_endUserLbl}</option>
                    <option value="2">{content.MYTD_familyMbrLbl}</option>
                    <option value="3">{content.MYTD_caregiverLbl}</option>
                    <option value="4">{content.MYTD_proLbl}</option>
                    <option value="5">{content.Dist_Org_User}</option>
                  </select>
                </div>
                {occupationTypeID === "5" && (
                  <React.Fragment>
                    <div className="col-sm-6 col-md-4 form-group">
                      <label
                        className="control-label"
                        htmlFor="bmoDistrictCode"
                      >
                        {content.Dist_Org_Code}
                      </label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          id="bmoDistrictCode"
                          name="bmoDistrictCode"
                          type="text"
                          value={bmoDistrictCode}
                          onChange={handleBmoDistrictCodeChange}
                          disabled={codeChecking || codeValid}
                        />
                        <span className="input-group-btn bmo-dist-check">
                          {!!districtCodeData && !!districtCodeData.isValid ? (
                            <button
                              className="btn btn-default"
                              type="button"
                              onClick={toggleModal}
                              disabled={codeChecking}
                            >
                              {!codeDeleting ? (
                                <Translation translate="Delete_Code" />
                              ) : (
                                <React.Fragment>
                                  Deleting&nbsp;
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    aria-hidden="true"
                                  ></i>
                                </React.Fragment>
                              )}
                            </button>
                          ) : (
                            <button
                              className="btn btn-default"
                              type="button"
                              onClick={(e) =>
                                handleDistCodeCheck(e, bmoDistrictCode)
                              }
                              disabled={codeChecking}
                            >
                              {!codeChecking ? (
                                <Translation translate="Check_code" />
                              ) : (
                                <React.Fragment>
                                  <Translation translate="Checking" />
                                  &nbsp;
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    aria-hidden="true"
                                  ></i>
                                </React.Fragment>
                              )}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {occupationTypeID === "5" && (
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-md-offset-4">
                    {!codeChecking && codeMap(districtCodeData)}
                  </div>
                </div>
              )}
              {occupationTypeID === "4" && (
                <div className="row" id="professionalInfo">
                  <div className="col-sm-6 col-md-4 form-group">
                    <label className="control-label" htmlFor="organization">
                      {content.MYTD_orgNameLbl}
                    </label>{" "}
                    <span className="optional">{content.MYTD_optionalTxt}</span>
                    <input
                      className="form-control"
                      id="organization"
                      name="organization"
                      type="text"
                      value={organization}
                      onChange={handleOrganizationChange}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 form-group">
                    <label className="control-label" htmlFor="occupationId">
                      {content.MYTD_RoleLbl}
                    </label>{" "}
                    <span className="optional">{content.MYTD_optionalTxt}</span>
                    <select
                      className="form-control"
                      value={occupationId}
                      onChange={handleOccupationIdChange}
                      id="occupationId"
                      name="occupationId"
                    >
                      <option value="0">{content.MYTD_selectRoleLbl}</option>
                      <option value="18">{content.MYTD_pathologistLbl}</option>
                      <option value="19">{content.MYTD_atSpecLbl}</option>
                      <option value="20">{content.MYTD_specEdLbl}</option>
                      <option value="21">{content.MYTD_genEdLbl}</option>
                      <option value="22">{content.MYTD_healthProLbl}</option>
                    </select>
                  </div>
                  <div className="col-sm-6 col-md-4 form-group">
                    <label className="control-label" htmlFor="industryId">
                      {content.MYTD_industryLbl}
                    </label>{" "}
                    <span className="optional">{content.MYTD_optionalTxt}</span>
                    <select
                      className="form-control"
                      value={industryId}
                      onChange={handleIndustryIdChange}
                      id="industryId"
                      name="industryId"
                    >
                      <option value="0">{content.MYTD_selectindLbl}</option>
                      <option value="1">{content.MYTD_healthCareLbl}</option>
                      <option value="2">{content.MYTD_educationLbl}</option>
                      <option value="3">{content.MYTD_otherLbl}</option>
                    </select>
                  </div>
                </div>
              )}
              <hr />
              <div className="row">
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="streetAddressLine1">
                    {content.MYTD_add1Lbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <input
                    className="form-control"
                    id="streetAddressLine1"
                    name="address.streetAddressLine1"
                    type="text"
                    value={profile.address.streetAddressLine1 || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="streetAddressLine2">
                    {content.MYTD_add2Lbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <input
                    className="form-control"
                    id="streetAddressLine2"
                    name="address.streetAddressLine2"
                    type="text"
                    value={profile.address.streetAddressLine2 || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="city">
                    {content.MYTD_cityLbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <input
                    className="form-control"
                    id="city"
                    name="address.city"
                    type="text"
                    data-testid="cityInput"
                    value={profile.address.city || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="state">
                    {content.MYTD_stateProvLbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <input
                    className="form-control"
                    id="state"
                    name="address.state"
                    type="text"
                    value={profile.address.state || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="zipCode">
                    {content.MYTD_postalCodeLbl}
                  </label>
                  <input
                    className="form-control"
                    id="zipCode"
                    name="address.zipCode"
                    type="text"
                    data-testid="zipCodeInput"
                    value={profile.address.zipCode || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
                <div className="col-sm-6 col-md-4 form-group">
                  <label className="control-label" htmlFor="phoneNumber">
                    {content.MYTD_phoneNumberLbl}
                  </label>{" "}
                  <span className="optional">{content.MYTD_optionalTxt}</span>
                  <input
                    className="form-control"
                    id="phoneNumber"
                    name="address.phoneNumber"
                    type="text"
                    value={profile.address.phoneNumber || ""}
                    onChange={handleTextUpdate}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-xs-12">
                  <button
                    className="btn btn-lg btn-primary"
                    data-testid="updateProfileButton"
                    onClick={validateForm}
                  >
                    {content.MYTD_updateProfileLbl}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h4 className="margin-bottom-md">
                {content.MYTD_profileUpdatedMsg}
              </h4>
              <button
                className="btn btn-lg btn-primary"
                onClick={handlePageRefresh}
              >
                {content.MYTD_okLbl}
              </button>
            </div>
          )}
        </div>
      )}

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h2">
            <Translation translate="MYTD_deleteLbl" textonly="true" />{" "}
            <Translation translate="Dist_Org_Code" textonly="true" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <Translation translate="Dist_Code_Delete_Warning" textonly="true" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <span>
            <button onClick={toggleModal} className="btn btn-lg btn-default">
              <Translation translate="MYTD_cancelBtnTxt" textonly="true" />
            </button>
            <button
              aria-label="Close"
              onClick={(e) => {
                toggleModal(e);
                handleDistCodeDelete(e, profile.bmoDistrictCode);
              }}
              className="btn btn-lg btn-primary"
            >
              <Translation translate="MYTD_deleteLbl" textonly="true" />
            </button>
          </span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfileForm;
