import { createAction } from "@reduxjs/toolkit";
import { getUserProfile } from "@services/user-service";

export const setUserDetails = createAction("users/setUserDetail");
export const setUserDataFailure = createAction("users/setUserDataFailure");
export const setAuthentication = createAction("auth/setAuthentication");

export const fetchUserDetail = (langCode) => async (dispatch) => {
  try {
    const data = await getUserProfile(langCode);
    dispatch(setUserDetails(data));
  } catch (error) {
    dispatch(setUserDataFailure(error));
  }
};
