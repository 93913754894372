import React from "react";
import { getShareRequestActionText, typeLabel } from "./utils";

const Details = ({ item, translations }) => {
  const actionText = getShareRequestActionText(item.action);
  const formattedProduct =
    item.product === "SnapCoreFirst" ? "TD Snap" : item.product;
  const formattedItemType = typeLabel(
    formattedProduct === "SnapScene" ? "Snap Scene" : formattedProduct,
    item.itemType.toLowerCase(),
    translations,
  );

  return (
    <td>
      <strong>{item.shareFromUserEmail}</strong> {actionText} {formattedProduct}{" "}
      {formattedItemType} <i>{item.title}</i>
    </td>
  );
};

export default Details;
