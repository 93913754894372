import React from "react";
import Modal from "react-bootstrap/Modal";
import Translation from "@common/Translation/Index";

const ReportsModal = ({ showModal, setShowModal }) => {
  const closeModal = () => setShowModal(false);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title>
          <h2>
            <Translation translate="Usage_Reports" textonly="true" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation
            translate="What_is_a_usage_report_desc"
            textonly="true"
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button
            aria-label="Close"
            onClick={closeModal}
            className="btn btn-lg btn-primary"
          >
            <Translation translate="MYTD_closeLbl" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportsModal;
