import React from "react";
import { Link } from "react-router-dom";

const Panel = ({ title, icon, urls }) => {
  return (
    <div className="myStuff-panel fade-block fade-in">
      <div className="myStuff-panel-top">
        <h4>{title}</h4>
        <div>
          <div
            className={icon + " icon icon-56"}
            data-testid="panel-icon"
          ></div>
        </div>
      </div>
      <div className="myStuff-panel-btm clearfix track-clicks">
        {urls.map(({ title, url }) => (
          <div className="btn-link-list fade-block fade-in" key={title}>
            <Link
              to={url}
              className="btn-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Panel;
