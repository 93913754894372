import React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LandingPage from "../components/prelogin/LandingPage/Index";
import LayoutPage from "../components/prelogin/LayoutPage/Index";
import { StorePage, PagesetCentralPage, SupportPage } from "../pages";
import { StoreHome, StoreInner } from "@common/Store";
import Orders from "../components/postlogin/MyProfile/Orders/Orders";
import {
  MyAccount,
  Profile,
  Licenses,
  Subscriptions,
  UsageReports,
} from "../components/postlogin/MyProfile";
import {
  PagesetCentralHome,
  PagesetCentralDetails,
} from "@common/PagesetCentral";
import {
  Documentation,
  ProfessionalResources,
  Downloads,
} from "@common/Support";
import DownloadInner from "@common/Support/Downloads/DownloadInner";
import SnapResources from "@common/Support/SnapResources/SnapResources";
import {
  ProductsOverview,
  MessageBank,
} from "../components/prelogin/ProductsOverview";
import ProtectedRoute from "../components/common/ProtectedRoute/Index";
import { MessageBankMessages } from "../components/prelogin/MoreStuff";
import { Dashboard } from "../components/postlogin";
import {
  Pagesets,
  Backups,
  Devices,
  Reports,
  Overview,
  MyStuff,
  Upload,
  Books,
  Students,
  Notifications,
} from "../components/postlogin/MyStuff";
import {
  downloadInnerRoutes,
  storeRoutes,
  productsOverviewRoutes,
} from "./routeMaps";
import ScrollToTop from "../helpers/ScrollToTop";

const AppRouter = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <LayoutPage />,
        children: [
          {
            index: true,
            element: isAuthenticated ? <Dashboard /> : <LandingPage />,
          },
          {
            path: "store",
            element: <StorePage />,
            children: [
              { index: true, element: <StoreHome /> },
              ...storeRoutes.map(({ path, pageKey }) => ({
                path,
                element: <StoreInner pageKey={pageKey} />,
              })),
            ],
          },
          {
            path: "pageset-central",
            element: <PagesetCentralPage />,
            children: [{ index: true, element: <PagesetCentralHome /> }],
          },
          {
            path: "support",
            element: <SupportPage />,
            children: [
              { path: "documentation", element: <Documentation /> },
              {
                path: "professional-resources",
                element: <ProfessionalResources />,
              },
              { path: "snap-resources", element: <SnapResources /> },
              { path: "downloads", element: <Downloads /> },
              ...downloadInnerRoutes.map(({ path, pageKey }) => ({
                path,
                element: <DownloadInner pageKey={pageKey} />,
              })),
            ],
          },
          {
            path: "psc/:software/:id",
            element: <PagesetCentralDetails />,
          },
          {
            path: "myaccount",
            element: (
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            ),
            children: [
              { index: true, element: <Profile /> },
              { path: "usage", element: <UsageReports /> },
              { path: "subscriptions", element: <Subscriptions /> },
              { path: "licenses", element: <Licenses /> },
              { path: "orders", element: <Orders /> },
            ],
          },
          ...productsOverviewRoutes.map(({ path, pageKey }) => ({
            path: `morestuff/${path}`,
            element: <ProductsOverview pageKey={pageKey} path={path} />,
          })),
          {
            path: "morestuff/messagebank",
            element: <MessageBank />,
            children: [
              {
                path: "messages",
                element: (
                  <ProtectedRoute>
                    <MessageBankMessages />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "mystuff/:product",
            element: (
              <ProtectedRoute>
                <MyStuff />
              </ProtectedRoute>
            ),
            children: [
              { index: true, element: <Overview /> },
              { path: "pagesets", element: <Pagesets /> },
              { path: "pagesets/upload", element: <Upload type="pagesets" /> },
              { path: "backups", element: <Backups /> },
              { path: "backups/upload", element: <Upload type="backups" /> },
              { path: "attachments", element: <Devices /> },
              { path: "reports", element: <Reports /> },
              { path: "books", element: <Books /> },
              { path: "students", element: <Students /> },
            ],
          },
          {
            path: "mystuff/notifications",
            element: (
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            ),
          },
          { path: "*", element: <p>There&apos;s nothing here: 404!</p> },
        ],
      },
    ],
    {
      // Opt in to the new changes that will come in v7 so we know we are ready to upgrade
      future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_startTransition: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    },
  );

  return (
    <RouterProvider router={router}>
      <ScrollToTop />
    </RouterProvider>
  );
};

export default AppRouter;
