import { fetchData } from "./base-service";

export const getMessageBankMessages = (langCode) => {
  return {
    name: "Message Bank",
    title: "Message Bank",
    messageBankTabText: "Message Bank",
    supportTabText: "Support",
  };
};
