import React from "react";
import { Popover } from "react-tiny-popover";

const PopoverWrapper = ({
  isPopoverOpen,
  togglePopover,
  content,
  children,
}) => {
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom", "right"]}
      align="center"
      padding={2}
      onClickOutside={() => togglePopover()}
      content={content}
      containerStyle={{ zIndex: "10" }}
    >
      {children}
    </Popover>
  );
};

export default PopoverWrapper;
