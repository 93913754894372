import React, { useState } from "react";
import PagesetItem from "./PageItem";
import Pagination from "./PaginationControl";

const Items = ({ currentItems, translations }) => (
  <>
    {currentItems &&
      currentItems.map((item) => (
        <div key={item.id} className="col-sm-6 pageset-holder">
          <PagesetItem ps={item} localizationResources={translations} />
        </div>
      ))}
  </>
);

const ResultComponent = ({
  itemsPerPage,
  translations,
  searchResult,
  handleFilterChange,
  setPagination,
  pagination,
}) => {
  const [currentItems, setCurrentItems] = useState([]);

  return searchResult?.pagesetDocuments?.length > 0 ? (
    <>
      <Pagination
        setCurrentItems={setCurrentItems}
        itemsPerPage={itemsPerPage}
        handleFilterChange={handleFilterChange}
        setPagination={setPagination}
        pagination={pagination}
        searchResult={searchResult}
      />

      <div className="pageset-grid">
        <Items currentItems={currentItems} translations={translations} />
      </div>

      <Pagination
        setCurrentItems={setCurrentItems}
        itemsPerPage={itemsPerPage}
        handleFilterChange={handleFilterChange}
        setPagination={setPagination}
        pagination={pagination}
        searchResult={searchResult}
      />
    </>
  ) : (
    <div className="col-sm-12">
      <h2>{translations.Pagesets_Message_No_Results}</h2>
    </div>
  );
};

export default ResultComponent;
