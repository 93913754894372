import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSnapResources } from "@services/support-service";
import Breadcrumb from "@common/Breadcrumb";
import SRDocumentsListItemComp from "./SRDocumentsListItemComp";

const SnapResources = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [snapResources, setSnapResources] = useState({});
  useEffect(() => {
    const fetchSnapResources = async () => {
      const data = await getSnapResources(languageCode);

      setSnapResources(data);
    };
    fetchSnapResources();
  }, [languageCode]);
  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>{snapResources.title}</h1>
          <Breadcrumb items={[{ label: snapResources.title }]}></Breadcrumb>
        </div>
      </div>
      <div id="useAnchors" className="container">
        <div className="resources">
          {snapResources.documentsList &&
            snapResources.documentsList.map((documentsListItem, index) => {
              return (
                <SRDocumentsListItemComp
                  documentsListItem={documentsListItem}
                  key={documentsListItem.title}
                  index={index}
                ></SRDocumentsListItemComp>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default SnapResources;
