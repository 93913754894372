import React from "react";
import Translation from "../../../common/Translation/Index";

const NoOrdersMessage = () => {
  return (
    <p>
      <Translation translate="MYTD_NoOrdersLbl" />
    </p>
  );
};

export default NoOrdersMessage;
