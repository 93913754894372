import React, { useState } from "react";
import {
  Link,
  useOutletContext,
  useLocation,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { getUploadToken, postUploadForm } from "@services";
import Translation from "@common/Translation/Index";
import UploadProgress from "./UploadProgress";
import UploadAlert from "./UploadAlert";
import { getSupportedFileTypes } from "@helpers/utilities";

const Upload = ({ type }) => {
  const { translations } = useOutletContext();
  const { product } = useParams();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [progress, setProgress] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const parentPath = pathname.split("/").slice(0, -1).join("/");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !title) return;
    const uploadType = type === "backups" ? "backup" : type;

    setIsUploading(true);
    setUploadFailed(false);

    try {
      // Step 1: Get the SAS Token URL
      const sasUrl = await getUploadToken(product, uploadType);

      // Extract blob name from SAS URL
      const blobName = sasUrl.split("/").pop().split("?")[0];

      // Step 2: Upload the file to Azure Blob Storage
      const azureResponse = await axios.put(sasUrl, file, {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(progress);
        },
      });

      if (azureResponse.status !== 201) {
        throw new Error("Failed to upload file to Azure Blob Storage");
      }

      // Step 3: Send the form
      const res = await postUploadForm(
        product,
        JSON.stringify({
          title,
          description: description || "",
          fileName: file.name,
          blobName,
        }),
        uploadType,
      );

      if (res.status < 200 || res.status >= 300) {
        throw new Error("Failed to save metadata to server");
      }

      setUploadComplete(true);
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadFailed(true);
    } finally {
      setIsUploading(false);
    }
  };

  const supportedFileTypes = getSupportedFileTypes(product, type);

  return (
    <>
      <h4 style={{ marginTop: "30px" }}>
        {type === "backups" && <Translation translate="MYTD_uploadBackupLbl" />}
        {type === "pagesets" && (
          <Translation translate="MYTD_uploadPagesetLbl" />
        )}
      </h4>

      <div>
        <div className="form-group">
          <label htmlFor="fileInput" className="control-label">
            <Translation translate="MYTD_fileLbl" />
          </label>
          <input
            type="file"
            id="fileInput"
            accept={supportedFileTypes.join(",")}
            onChange={handleFileChange}
          />
          <p className="help-block">
            <span>
              {translations.The_following_file_types_are_allowed}{" "}
              {supportedFileTypes.join(", ")}
            </span>
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="titleInput" className="control-label">
            <Translation translate="MYTD_titleTxt" />
          </label>
          <input
            type="text"
            id="titleInput"
            value={title}
            placeholder={translations.title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description" className="control-label">
            <Translation translate="MYTD_descrTxt" />
          </label>{" "}
          <span className="optional">
            <Translation translate="MYTD_optionalTxt" />
          </span>
          <textarea
            value={description}
            placeholder={translations.title}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            id="description"
            cols="30"
            rows="10"
            className="form-control"
          ></textarea>
        </div>

        {isUploading && (
          <UploadProgress status="inProgress" progress={progress} />
        )}
        {uploadFailed && <UploadAlert status="failed" />}

        <div>
          {uploadComplete ? (
            <div>
              <UploadAlert status="completed" />
              <Link className="btn btn-lg btn-primary" to={parentPath}>
                <Translation translate="MYTD_okLbl" />
              </Link>
            </div>
          ) : (
            <>
              <button
                className="btn btn-lg btn-primary"
                disabled={!file || !title || isUploading}
                onClick={handleUpload}
              >
                <Translation translate="MYTD_uploadLbl" />
              </button>
              <span>&nbsp;&nbsp;</span>
              <Link to={parentPath} className="btn btn-default btn-lg">
                <Translation translate="MYTD_cancelBtnTxt" />
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Upload;
