const Constants = {
  Attribute: "Attribute",
  PartnerSP: "PartnerSP",
  SubjectName: "SubjectName",
  TargetUrl: "TargetUrl",
  CultureCookieName: "culture",
  SiteType: 1,
  SiteName: "myTobiiDynavox",
};

export default Constants;
