import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TileSet from "@common/Tile/TileSet";
import Translation from "@common/Translation/Index";
import { getMoreStuff } from "@services";

const MoreStuffHome = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [moreStuff, setMoreStuff] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMoreStuff(languageCode);
      setMoreStuff(data);
    };
    fetchData();
  }, [languageCode]);

  return (
    <div className="container-full">
      <div className="container morestuff-nav">
        <h2>
          <Translation translate="More_Stuff" textonly="true" />
        </h2>
        <TileSet
          tilegroup={moreStuff}
          isMoreStuff="true"
        />
      </div>
    </div>
  );
};

export default MoreStuffHome;
