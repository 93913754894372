import { postData, fetchData } from "./base-service";

const PAGESET_API_ENDPOINTS = {
  pageSetOverview: (langCode) => `/v1/pageset-central/search?uic=${langCode}`,
  pageSetById: (langCode, id) => `/v1/pageset/${id}?uic=${langCode}`,
};

const getPageSetData = (langCode, payload) => {
  return postData(PAGESET_API_ENDPOINTS.pageSetOverview(langCode), payload);
};

const getPageSetDataById = (langCode, pagesetId, software) => {
  const id = software === "compass" ? `c-${pagesetId}` : `psc-${pagesetId}`;
  return fetchData(PAGESET_API_ENDPOINTS.pageSetById(langCode, id));
};
export { getPageSetData, getPageSetDataById };
