import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RawHTML from "@common/RawHTML/Index";
import { ImageGallery } from "./Common";

import SubProductsList from "./SubProductsList";
import ProductNotesList from "./ProductNotesList";
import OneTimeSinglePurchase from "./OneTimeSinglePurchase";
import Professionals from "./Professionals";
import TryItOuts from "./TryItOuts";
import OneTimePurchases from "./OneTimePurchases";
import FreePathways from "./FreePathways";
import Applications from "./Applications";
import Upgrades from "./Upgrade";
import OtherApp from "./OtherApplications";
import { getStoreInnerPageData } from "@services";
import Breadcrumb from "@common/Breadcrumb";

const StoreInner = ({ pageKey }) => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [innerPageData, setInnerPageData] = useState();
  const [selectedPathwaysLanguageUrl, setSelectedPathwaysLanguageUrl] =
    useState(null);
  const [selectedPathwaysLanguage, setSelectedPathwaysLanguage] = useState(
    translations.Language,
  );

  useEffect(() => {
    const fetchInnerPageData = async () => {
      const data = await getStoreInnerPageData(pageKey, languageCode);
      setInnerPageData(data);
    };

    fetchInnerPageData();
  }, [languageCode]);

  const handleUrlSelection = (url, language) => {
    setSelectedPathwaysLanguageUrl(url);
    setSelectedPathwaysLanguage(language);
  };

  const handleButtonClick = () => {
    if (selectedPathwaysLanguageUrl) {
      window.open(selectedPathwaysLanguageUrl, "_blank");
    }
  };

  return (
    <>
      {innerPageData && translations && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{innerPageData.title}</h1>
              <Breadcrumb
                items={[
                  {
                    href: "/store",
                    label: translations.Store,
                  },
                  {
                    label:
                      innerPageData.subTitle == "ALL"
                        ? innerPageData.subTitle
                        : innerPageData.title,
                  },
                ]}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-md-push-8">
                <h2>{innerPageData.headline}</h2>
                <RawHTML htmlContent={innerPageData.description} />
                {innerPageData.subProducts && (
                  <SubProductsList
                    subProducts={innerPageData.subProducts.subProducts}
                  />
                )}
                {innerPageData.productNotes && (
                  <ProductNotesList productNotes={innerPageData.productNotes} />
                )}
                <a
                  href={
                    innerPageData.title === "Pathways" ? "#browse" : "#shopNow"
                  }
                  className="btn btn-lg btn-warning"
                >
                  {innerPageData.title === "Pathways"
                    ? translations.Browse
                    : translations.Shop_Now}
                </a>
              </div>
              <div className="col-md-7 col-md-pull-4">
                <ImageGallery imagePaths={innerPageData.images} />
              </div>
            </div>
          </div>
          {innerPageData.tryItOuts && (
            <TryItOuts
              tryItOuts={innerPageData.tryItOuts}
              translations={translations}
            />
          )}
          {innerPageData.oneTimeSinglePurchase && (
            <OneTimeSinglePurchase
              oneTimeSinglePurchase={innerPageData.oneTimeSinglePurchase}
              translations={translations}
            />
          )}
          {innerPageData.professionals && (
            <Professionals
              professionals={innerPageData.professionals}
              translations={translations}
            />
          )}
          {innerPageData.oneTimePurchases && (
            <OneTimePurchases
              oneTimePurchases={innerPageData.oneTimePurchases}
              translations={translations}
            />
          )}
          {innerPageData.freePathways && (
            <FreePathways
              freePathways={innerPageData.freePathways}
              translations={translations}
              selectedPathwaysLanguageUrl={selectedPathwaysLanguageUrl}
              selectedPathwaysLanguage={selectedPathwaysLanguage}
              handleUrlSelection={handleUrlSelection}
              handleButtonClick={handleButtonClick}
              pageKey={pageKey}
            />
          )}

          {innerPageData.applications && (
            <div id="shopNow" className="container-full">
              <Applications
                applications={innerPageData.applications}
                translations={translations}
              />
              <Upgrades
                upgrades={innerPageData.upgrades}
                translations={translations}
              />
              <OtherApp otherApplications={innerPageData.otherApplications} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StoreInner;
