import React from "react";
import Translation from "@common/Translation/Index";

const DetachButton = ({ onClick, translationKey }) => (
  <button href="#" className="btn-link" onClick={onClick}>
    <Translation textonly="true" translate={translationKey} />
  </button>
);

export default DetachButton;
