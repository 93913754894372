import { fetchData } from "./base-service";

const API_ENDPOINTS = {
  login: (returnUrl) => `/auth/login?returnUrl=${returnUrl}`,
  logout: (returnUrl) => `/auth/logout?returnUrl=${returnUrl}`,
  isAuthenticated: () => `/v1/auth/isAuthenticated`,
  register: (returnUrl) => `/api/v1/account/register?returnUrl=${returnUrl}`,
  edit: (returnUrl) => `/api/v1/account/edit?returnUrl=${returnUrl}`,
  changePassword: (returnUrl) =>
    `/api/v1/account/change-password?returnUrl=${returnUrl}`,
};

const getReturnUrl = () => encodeURIComponent(window.location.pathname);

export const redirectToLogin = async () => {
  const returnUrl = getReturnUrl();
  window.location.href = API_ENDPOINTS.login(returnUrl);
};

export const redirectToLogout = () => {
  const returnUrl = getReturnUrl();
  window.location.href = API_ENDPOINTS.logout(returnUrl);
};

export const checkAuthStatus = () => {
  return fetchData(API_ENDPOINTS.isAuthenticated());
};

export const redirectToRegister = () => {
  const returnUrl = getReturnUrl();
  window.location.href = API_ENDPOINTS.register(returnUrl);
};

export const redirectToEdit = () => {
  const returnUrl = getReturnUrl();
  window.location.href = API_ENDPOINTS.edit(returnUrl);
};

export const redirectToChangePassword = () => {
  const returnUrl = getReturnUrl();
  window.location.href = API_ENDPOINTS.changePassword(returnUrl);
};
