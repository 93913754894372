import { createAction } from "@reduxjs/toolkit";
import { getRedirectionData } from "../services";

export const setRedirectionData = createAction("common/setRedirectionData");

export const setCommonFailure = createAction("common/setCommonFailure");

export const fetchRedirectionData = (langCode) => async (dispatch) => {
  try {
    const response = await getRedirectionData(langCode);
    dispatch(setRedirectionData(response));
  } catch (error) {
    dispatch(setCommonFailure(error));
  }
};
