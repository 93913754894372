import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkAuthStatus } from "@services/auth-service";
import { setAuthentication } from "../../../actions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await checkAuthStatus();
      setIsAuthenticated(data);
      dispatch(setAuthentication(data));
    };

    fetchData();

    //Check if the user is still logged in every 30 seconds
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
