import React from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation/Index";

const DeleteModal = ({ showModal, setShowModal, name, handleDelete }) => {
  const closeModal = () => setShowModal(false);

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
      data-testid="delete-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>{name}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_deleteItemWarnMsg" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button
            onClick={(e) => {
              handleDelete();
            }}
            className="btn btn-lg btn-primary"
          >
            <Translation translate="MYTD_okLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
