import React from "react";

const DownloadDropdown = ({ title, listItems, translations }) => {
  const handleLinkClick = (e, url, downloadLinkId, systemName, togglerId) => {
    e.preventDefault();
    const downloadLink = document.getElementById(downloadLinkId);
    const dropdownToggler = document.getElementById(togglerId);

    if (dropdownToggler) {
      dropdownToggler.innerHTML = `<span>${systemName}</span><b class="caret"></b>`;
    }

    if (downloadLink) {
      downloadLink.href = url;

      downloadLink.classList.remove("btn-inactive");
      downloadLink.classList.add("btn-primary");
    }
  };

  return (
    <div className="row" key="dropdown">
      <div className="download-group dropdown dropdown-lg dropdown-col col-sm-7 col-md-6 col-lg-5">
        <a
          id={`all-text_${title}`}
          className="dropdown-toggle btn btn-gradient ignore-tracking"
          data-toggle="dropdown"
          href=""
        >
          <span>{translations.Select_an_Installer}</span>
          <b className="caret"></b>
        </a>
        <ul id="dropdown-all-text" className="dropdown-menu">
          {listItems &&
            listItems.map(({ label, url }, index) => (
              <li key={`${label}${title}_${index}`}>
                <a
                  href=""
                  onClick={(e) =>
                    handleLinkClick(
                      e,
                      url,
                      `Download_${title}_`,
                      label,
                      `all-text_${title}`,
                    )
                  }
                >
                  {`Download for ${label}`}
                </a>
              </li>
            ))}
          {listItems?.length === 0 && ( // Handle case with no download URLs
            <li key="no-downloads">
              <span>No downloads available for this item.</span>
            </li>
          )}
        </ul>
      </div>
      <div className="download-group-button col-sm-5 col-md-6 col-lg-7">
        <div
          className="track-clicks"
          data-group="Downloads"
          data-section={title}
        >
          <a
            href="#"
            id={`Download_${title}_`}
            className="btn btn-inactive btn-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translations.Download_Software}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadDropdown;
