import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RawHTML from "@common/RawHTML/Index";
import Breadcrumb from "@common/Breadcrumb";
import { getDownloadsPageData } from "@services";
import { downloadInnerRoutes } from "../../../../route/routeMaps";

export const findRoute = (name) =>
  downloadInnerRoutes.find((route) => route.pageKey === name);

const Downloads = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [sections, setSections] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDownloadsPageData(languageCode);
      setSections(data);
    };
    fetchData();
  }, [languageCode]);

  useEffect(() => {
    document.querySelectorAll(".show-all").forEach((showAllElement) => {
      showAllElement.addEventListener("click", function () {
        const faElement = this.querySelector(".fa");
        if (faElement) {
          faElement.classList.toggle("fa-angle-double-down");
          faElement.classList.toggle("fa-angle-double-up");
        }
      });
    });
  }, []);

  return (
    sections && (
      <>
        <div className="container-full">
          <div className="container myStuff-header">
            <h1>{sections.title}</h1>
            <Breadcrumb
              items={[
                {
                  label: sections.title,
                },
              ]}
            />
          </div>
        </div>
        <div className="db-container">
          <div className="container">
            <div className="row download-item">
              {sections.currentApps?.map(
                ({ image, name, description, title }) => {
                  const route = findRoute(name).path;
                  return (
                    <div
                      key={title}
                      className="col-xs-6 col-md-4 primary-dl current-software"
                    >
                      <img src={`/assets/images${image}`} alt={name} />
                      <h3>
                        <Link to={`/support/${route}`}>{title}</Link>
                      </h3>
                      <RawHTML htmlContent={description} />
                    </div>
                  );
                },
              )}
            </div>

            <div className="show-all text-center">
              <Link
                to="#moreDownloads"
                data-toggle="collapse"
                className="collapsed col-xs-4 col-xs-offset-4"
              >
                <span className="hidden-xs">{sections.otherApps?.title}</span>
                <i className="fa fa-angle-double-down"></i>
              </Link>
            </div>
            <div id="moreDownloads" className="collapse clearfix">
              <h2>{sections.otherApps?.legacyApps?.title}</h2>
              <div className="row">
                {sections.otherApps?.legacyApps?.apps.map(
                  ({ name, title, image }) => {
                    const route = findRoute(name).path;
                    return (
                      <div key={name} className="col-sm-6 more-downloads">
                        <img
                          className="logo"
                          src={`/assets/images${image}`}
                          alt={name}
                        />
                        <Link to={`/support/${route}`}>{title}</Link>
                      </div>
                    );
                  },
                )}
              </div>
              <hr className="secondary-dl-hr" />
              <h2>{sections.otherApps?.additionalApps?.title}</h2>
              <div className="row">
                {sections.otherApps?.additionalApps?.apps.map(
                  ({ name, title, image }) => {
                    const route = findRoute(name).path;
                    return (
                      <div key={name} className="col-sm-6 more-downloads">
                        <img
                          className="logo"
                          src={`/assets/images${image}`}
                          alt={name}
                        />
                        <Link
                          to={`/support/${route}`}
                          dangerouslySetInnerHTML={{
                            __html: title,
                          }}
                        />
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
export default Downloads;
