import {
  allMockData,
  communicatorMockData,
  compassMockData,
  messagebankMockData,
  snapMockData,
  snapSceneMockData,
  symbolMateMockData,
} from "../mockData/mockData";
import { deleteData, fetchData, postData } from "./base-service";

const MY_STUFF_API_ENDPOINTS = {
  activeNotifications: (langCode) => `/v1/notifications?uic=${langCode}`,
  previousNotifications: (langCode) =>
    `/v1/notifications/history?uic=${langCode}`,
  latestPageset: (langCode, product) =>
    `/v1/pagesets/${product}/latest?uic=${langCode}`,
  pagesets: (langCode, product) => `/v1/pagesets/${product}?uic=${langCode}`,
  downloadPageset: (id) => `/api/v1/pageset/download/${id}`,
  downloadCompassPageset: (id) => `/api/v1/pageset/download/compass/${id}`,
  deletePageset: (id, product) => `/v1/pagesets/${product}/${id}`,
  latestBackup: (langCode, product) =>
    `/v1/backup/${product}/latest?uic=${langCode}`,
  backups: (langCode, product) => `/v1/backup/${product}?uic=${langCode}`,
  downloadBackup: (id, product) => `/api/v1/backup/${product}/download/${id}`,
  deleteBackup: (id, product) => `/v1/backup/${product}/${id}`,
  documentationLinks: (langCode, product, category) =>
    `/v1/apps/${product}/${langCode}/${category}`,
  acceptNotification: (langCode, id) =>
    `/v1/notifications/${id}/accept?uic=${langCode}`,
  declineNotification: (langCode, id) =>
    `/v1/notifications/${id}/decline?uic=${langCode}`,
  tabs: (langCode, product) => `/v1/products/${product}?uic=${langCode}`,
  uploadToken: (product, type) => `/v1/${type}/${product}/get-upload-token`,
  uploadForm: (product, type) => `/v1/${type}/${product}/form`,
};

export const getMyStuffData = (langCode, product) => {
  switch (product) {
    case "snap":
      return snapMockData;
    case "compass":
      return compassMockData;
    case "communicator":
      return communicatorMockData;
    case "messagebank":
      return messagebankMockData;
    case "snapscene":
      return snapSceneMockData;
    case "symbolmate":
      return symbolMateMockData;
    case "all":
      return allMockData;
    default:
      return {};
  }
};

export const getActiveNotifications = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.activeNotifications(langCode));

export const getPreviousNotifications = async (langCode) =>
  fetchData(MY_STUFF_API_ENDPOINTS.previousNotifications(langCode));

export const getLatestPageset = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.latestPageset(langCode, product));

export const getPagesets = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.pagesets(langCode, product));

export const downloadPagesetById = async (id) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadPageset(id);
};

export const downloadCompassPagesetById = async (id) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadCompassPageset(id);
};

export const deletePagesetById = async (id, product) => {
  deleteData(MY_STUFF_API_ENDPOINTS.deletePageset(id, product));
};

export const getLatestBackup = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.latestBackup(langCode, product));

export const getBackups = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.backups(langCode, product));

export const deleteBackupById = async (id, product) => {
  deleteData(MY_STUFF_API_ENDPOINTS.deleteBackup(id, product));
};

export const downloadBackupById = async (id, product) => {
  window.location.href = MY_STUFF_API_ENDPOINTS.downloadPageset(id, product);
};

export const getDocumentationLinks = async (langCode, product, category) =>
  fetchData(
    MY_STUFF_API_ENDPOINTS.documentationLinks(langCode, product, category),
  );

export const getTabs = async (langCode, product) =>
  fetchData(MY_STUFF_API_ENDPOINTS.tabs(langCode, product));

export const postAcceptNotification = async (langCode, id) =>
  postData(MY_STUFF_API_ENDPOINTS.acceptNotification(langCode, id));

export const declineNotification = async (langCode, id) =>
  deleteData(MY_STUFF_API_ENDPOINTS.declineNotification(langCode, id));

export const getUploadToken = async (product, type) =>
  fetchData(MY_STUFF_API_ENDPOINTS.uploadToken(product, type));

export const postUploadForm = async (product, payload, type) =>
  postData(MY_STUFF_API_ENDPOINTS.uploadForm(product, type), payload);
