import React from "react";

const DeviceImage = ({ imageUrl }) => (
  <div className="hidden-xs col-sm-">
    <img
      src={imageUrl || "/assets/images/devices/device-pilot-td-snap.jpg"}
      alt="Device"
      className="hidden-xs"
    />
  </div>
);

export default DeviceImage;
