import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  translationsReducer,
  userDetailsReducer,
  languageDataReducer,
  commonDataReducer,
} from "./reducers";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  translations: translationsReducer,
  languageData: languageDataReducer,
  user: userDetailsReducer,
  common: commonDataReducer,
});

export const getConfiguredStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunk],
  });

export const store = getConfiguredStore();
