import React from "react";
import Translation from "@common/Translation/Index";

export const productConfig = () => {
  return {
    myAccount: {
      Tile: {
        colorClass: "my-account",
        title: <Translation translate="MYTD_MyAccountLbl" />,
        iconClass: "compass",
        url: "myaccount",
        type: "account",
      },
    },
    Notifications: {
      Tile: {
        colorClass: "notifications",
        title: <Translation translate="Notifications_lbl" />,
        iconClass: "notifications",
        url: "mystuff/notifications",
        type: "notifications",
      },
    },
    Compass: {
      Tile: {
        colorClass: "compass",
        title: "Compass",
        iconClass: "compass",
        url: "mystuff/compass",
        moreStuffUrl: "morestuff/compass",
        type: "product",
      },
      Product: {
        instructions: "MYTD_compassInstructionsMsg",
      },
    },
    Communicator: {
      Tile: {
        colorClass: "communicator",
        title: "Communicator",
        iconClass: "communicator-5",
        url: "mystuff/communicator",
        moreStuffUrl: "morestuff/communicator",
        type: "product",
      },
      Product: {
        instructions: "MYTD_comm5InstructionsMsg",
      },
    },
    SymbolMate: {
      Tile: {
        colorClass: "symbol-mate",
        title: "SymbolMate",
        iconClass: "symbolmate",
        url: "mystuff/symbolmate",
        moreStuffUrl: "morestuff/symbolmate",
        type: "product",
      },
    },
    Snap: {
      Tile: {
        colorClass: "snap",
        title: "TD Snap",
        iconClass: "snap-core-first",
        url: "mystuff/snap",
        moreStuffUrl: "morestuff/snap",
        type: "product",
      },
      Product: {
        instructions: "MYTD_snapInstructionsMsg",
      },
    },
    SnapScene: {
      Tile: {
        colorClass: "snap-scene",
        title: "Snap Scene",
        iconClass: "snap-scene",
        url: "mystuff/snapscene",
        moreStuffUrl: "morestuff/snapscene",
        type: "product",
      },
    },
    ALL: {
      Tile: {
        colorClass: "all",
        title: "ALL",
        iconClass: "all",
        url: "mystuff/all",
        moreStuffUrl: "morestuff/all",
        type: "product",
      },
    },
    Partners: {
      Tile: {
        colorClass: "my-account",
        title: "Partners",
        iconClass: "partners",
        type: "partner",
      },
    },
    Professionals: {
      Tile: {
        colorClass: "professionals",
        title: <Translation translate="Professionals" />,
        iconClass: "professionals",
        url: "mystuff/professionals",
        moreStuffUrl: "morestuff/professionals",
        type: "product",
      },
    },
    GazeViewer: {
      Tile: {
        colorClass: "gaze-viewer",
        title: "Gaze Viewer",
        iconClass: "gaze-viewer",
        url: "mystuff/",
        type: "product",
      },
      Product: {
        instructions: "MYTD_gazeviewerInstructionsMsg",
      },
    },
    MessageBank: {
      Tile: {
        colorClass: "message-bank",
        title: "Message Bank",
        iconClass: "message-bank",
        url: "mystuff/messagebank",
        moreStuffUrl: "morestuff/messagebank",
        type: "product",
      },
    },
  };
};
