import React from "react";
import Moment from "react-moment";
import Table from "@common/Table";
import ActionLinks from "@common/Table/ActionLinks";
import Details from "@common/Table/Details";
import { getShareRequestActionText } from "@common/Table/utils";
import Translation from "@common/Translation/Index";

const CurrentNotifications = ({ notificationsData, translations, handleActionComplete }) => {
  const cols = [
    <Translation translate="MYTD_dateLbl" key="MYTD_dateLbl" />,
    <Translation translate="MYTD_detailsLbl" key="MYTD_detailsLbl" />,
    <Translation translate="MYTD_respondLbl" key="MYTD_respondLbl" />,
  ];
  return (
    <>
      <div className="container" style={{ paddingBottom: 0 }}>
        <h3>
          <Translation translate="MYTD_CurrentLbl" />
        </h3>
      </div>
      {notificationsData?.length ? (
        <Table cols={cols}>
          {notificationsData.map((item, k) => {
            return (
              <tr key={k}>
                <td>
                  <Moment format="l">{item.dateSharedUtc}</Moment>
                </td>
                {item.action.includes("messagebundle") ? (
                  <td>
                    <strong>{item.title}</strong>{" "}
                    {getShareRequestActionText(item.action)}
                  </td>
                ) : (
                  <Details item={item} translations={translations} />
                )}
                <td>
                  <ActionLinks action={item.action} id={item.pagesetShareRequestId} onActionComplete={handleActionComplete} />
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div style={{ marginTop: 20 }} className="col-xs-12">
          <Translation translate="MYTD_noPendingNotifMsg" />
        </div>
      )}
    </>
  );
};

export default CurrentNotifications;
