import React from "react";
import Translation from "@common/Translation/Index";
import Alert from "@common/Alert";

const getAlertType = (status) => {
  switch (status) {
    case "completed":
      return "success";
    case "failed":
      return "danger";
    case "failedformat":
      return "danger";
    case "cancelled":
      return "info";
    default:
      return "";
  }
};

const getAlertMessage = (status) => {
  switch (status) {
    case "completed":
      return <Translation translate="MYTD_uploadSuccessMsg" />;
    case "failed":
      return <Translation translate="MYTD_uploadErrorMsg" />;
    case "failedformat":
      return <Translation translate="MYTD_fileUploadFormatErr" />;
    case "cancelled":
      return <Translation translate="MYTD_uploadCancelMsg" />;
    default:
      return "";
  }
};

const UploadAlert = ({ status }) => (
  <div>
    <Alert type={getAlertType(status)}>{getAlertMessage(status)}</Alert>
  </div>
);

export default UploadAlert;
