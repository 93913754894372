import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SiteNavigation = ({ translations }) => {
  const { redirections } = useSelector((state) => state.common);

  const [giveFeedbackData, setGiveFeedbackData] = useState({});
  const [supportData, setSupportData] = useState({});
  const [communityData, setCommunityData] = useState({});

  const initializeData = (type) => {
    const dataTemplate = {
      title: "",
      ...(type === "Feedback" && { displayText: "", url: "" }),
      ...(type === "Support" && { getSupport: {}, eFundingUS: {} }),
      ...(type === "Community" && {
        facebook: {},
        instagram: {},
        linkedin: {},
        twitter: {},
        vimeo: {},
        youtube: {},
      }),
    };
    return dataTemplate;
  };

  const extractData = (type) => {
    const redirectionData = redirections.find((item) => item.type === type);
    const data = initializeData(type);

    if (redirectionData) {
      data.title = redirectionData.displayText;
      redirectionData.redirects.forEach((redirect) => {
        const { type: redirectType, displayText, url } = redirect;
        if (type === "Feedback" && redirectType === "GiveFeedback") {
          data.displayText = displayText;
          data.url = url;
        } else if (type === "Support") {
          if (redirectType === "GetSupport") {
            data.getSupport = { displayText, url };
          } else if (redirectType === "EFundingUS") {
            data.eFundingUS = { displayText, url };
          }
        } else if (type === "Community") {
          const socialMediaTypes = {
            Facebook: "facebook",
            Instagram: "instagram",
            LinkedIn: "linkedin",
            Twitter: "twitter",
            Vimeo: "vimeo",
            YouTube: "youtube",
          };
          if (socialMediaTypes[redirectType]) {
            data[socialMediaTypes[redirectType]] = { displayText, url };
          }
        }
      });
    }
    return data;
  };

  useEffect(() => {
    if (redirections) {
      setGiveFeedbackData(extractData("Feedback"));
      setSupportData(extractData("Support"));
      setCommunityData(extractData("Community"));
    }
  }, [redirections]);

  return (
    <div id="siteNavigation" className="navbar navbar-default">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand" to="/">
            {" "}
          </Link>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav">
            <li>
              <Link to="/store">{translations.Store}</Link>
            </li>
            <li>
              <Link to="/pageset-central">{translations.Pageset_Central}</Link>
            </li>

            {supportData.title && (
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-expanded="false"
                >
                  {supportData.title} <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li>
                    <Link to="/support/professional-resources">
                      {translations.Header_Resources}
                    </Link>
                  </li>
                  <li>
                    <Link to="/support/documentation">
                      {translations.Documentation}
                    </Link>
                  </li>
                  <li>
                    <Link to="/support/downloads">
                      {translations.Downloads}
                    </Link>
                  </li>
                  <li>
                    <a
                      href={supportData.getSupport.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {supportData.getSupport.displayText}
                    </a>
                  </li>
                  <li>
                    <a
                      href={supportData.eFundingUS.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {supportData.eFundingUS.displayText}
                    </a>
                  </li>
                </ul>
              </li>
            )}

            {communityData.title && (
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-expanded="false"
                >
                  {communityData.title} <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  {Object.keys(communityData).map(
                    (key) =>
                      communityData[key].url && (
                        <li key={key}>
                          <a
                            className={`social-${key}`}
                            href={communityData[key].url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              className={`social icon icon-21 icon-${key}`}
                            ></span>
                            {communityData[key].displayText}
                          </a>
                        </li>
                      ),
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
      {giveFeedbackData.url && (
        <div className="sticky-button">
          <a
            href={giveFeedbackData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {giveFeedbackData.displayText}
          </a>
        </div>
      )}
    </div>
  );
};

export default SiteNavigation;
