import React from "react";

const Table = ({ cols, children }) => {
  return (
    <div className="container">
      <table className="table table-hover mtd-table fade-block fade-in">
        <thead>
          <tr>
            {cols?.map((col, i) => (
              <th key={i}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
