import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getContactPageData } from "@services";

const Footer = () => {
  const { translations } = useSelector((state) => state.translations);
  const { redirections } = useSelector((state) => state.common);
  const { languageCode } = useSelector((state) => state.languageData);

  const [companyData, setCompanyData] = useState({
    title: "",
    home: {},
    aboutUs: {},
  });

  const [privacyData, setPrivacyData] = useState({
    title: "",
    privacyPolicy: {},
    privacyPolicyChina: {},
    termsAndConditions: {},
  });

  const [contactData, setContactData] = useState({
    title: "",
    addresses: [],
  });

  const extractData = (type) => {
    const redirectionData = redirections.find((item) => item.type === type);
    const data = {
      title: "",
      ...(type === "Company" && { home: {}, aboutUs: {} }),
      ...(type === "Privacy" && {
        privacyPolicy: {},
        privacyPolicyChina: {},
        termsAndConditions: {},
      }),
    };

    if (redirectionData) {
      data.title = redirectionData.displayText;
      redirectionData.redirects.forEach((redirect) => {
        if (type === "Company") {
          if (redirect.type === "HomePage") {
            data.home = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "AboutUs") {
            data.aboutUs = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          }
        } else if (type === "Privacy") {
          if (redirect.type === "PrivacyPolicy") {
            data.privacyPolicy = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "PrivcayPolicyChina") {
            data.privacyPolicyChina = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "TermsAndConditions") {
            data.termsAndConditions = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          }
        }
      });
    }
    return data;
  };

  useEffect(() => {
    if (redirections) {
      setCompanyData(extractData("Company"));
      setPrivacyData(extractData("Privacy"));
    }
  }, [redirections]);

  useEffect(() => {
    const fetchData = async () => {
      const contact = await getContactPageData(languageCode);
      setContactData(contact);
    };
    fetchData();
  }, [languageCode]);

  return (
    <>
      {translations && (
        <footer className="no-print main-footer">
          <div className="container">
            <div className="row">
              {contactData &&
                contactData.addresses.map((address, index) => (
                  <div
                    className="hidden-xs col-sm-6 col-lg-3"
                    key={`${address.name}_${index}_`}
                  >
                    <h3>{index == 0 ? contactData.title : <>&nbsp;</>}</h3>

                    <address>
                      {address.name}
                      <br />
                      {address.addressFirstLine}
                      <br />
                      {address.addressSecondLine
                        ? address.addressSecondLine
                        : address.cityAndCode}
                      <br />
                      {address.country ? address.country : address.cityAndCode}
                      <br />
                      <br />
                      {translations.Phone}: {address.phone}
                      <br />
                      {translations.Fax}: {address.fax}
                    </address>
                  </div>
                ))}

              {companyData && (
                <div className="col-sm-6 col-lg-3 footer-col-xs-spacing">
                  <h3>{companyData.title}</h3>
                  <a
                    href={companyData.home.url}
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {companyData.home.displayText}
                  </a>
                  <span className="external-link"></span>
                  <br />
                  <a
                    href={companyData.aboutUs.url}
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {companyData.aboutUs.displayText}
                  </a>
                  <span className="external-link"></span>
                </div>
              )}
              <div className="col-sm-6 col-lg-3 footer-col-xs-spacing clearfix">
                <h3>{translations.Stay_Connected}</h3>
                <ul className="social-wrapper">
                  <li>
                    <a
                      href="https://www.facebook.com/tobiidynavox"
                      className="facebook social-facebook"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/tobiidynavox"
                      className="twitter social-twitter"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCSFhjTdkb4rt4GiIPmDypQA"
                      className="youtube social-youtube"
                      title="YouTube"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/dynavox/"
                      className="pinterest social-pinterest"
                      title="Pinterest"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            {privacyData && (
              <div className="row copyright">
                <div className="col-sm-6 footer-col-xs-spacing">
                  <a
                    href={privacyData.privacyPolicy.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {privacyData.privacyPolicy.displayText}
                  </a>
                  <a
                    href={privacyData.privacyPolicyChina.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {privacyData.privacyPolicyChina.displayText}
                  </a>
                  <a
                    href={privacyData.termsAndConditions.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {privacyData.termsAndConditions.displayText}
                  </a>
                </div>
                <div className="col-sm-6">
                  &copy; {new Date().getFullYear()} - Tobii Dynavox
                </div>
              </div>
            )}
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
