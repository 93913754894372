import { createReducer } from "@reduxjs/toolkit";
import { setLanguage } from "../actions";
import { cultureService } from "@services";

const { LanguageName, LanguageCode } = cultureService.getCultureOptions();

const initialState = {
  languageName: LanguageName,
  languageCode: LanguageCode,
};

const languageDataReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLanguage, (state, action) => {
    Object.assign(state, action.payload);
  });
});

export default languageDataReducer;
