import React from "react";
import SRDocumentComp from "./SRDocumentComp.jsx";
import SRNoteComp from "./SRNoteComp.jsx";

const SRDocumentsListItemComp = ({ documentsListItem, index }) => {
  return (
    <>
      {index ? <hr /> : null}
      <h2
        id="new-topics"
        className="margin-bottom-sm"
        key={documentsListItem.title}
      >
        {documentsListItem.title}
      </h2>
      {!index ? <hr /> : null}
      {documentsListItem.documentsDescription && (
        <p>
          {`${documentsListItem.documentsDescription?.description || ""} ${
            documentsListItem.documentsDescription?.addition?.text || ""
          }`}
          {documentsListItem.documentsDescription?.addition?.links?.map(
            (descLinkItem, descLinkIndex) => (
              <a
                key={descLinkIndex}
                href={descLinkItem.mainUrl}
                target="_blank"
                rel="noreferrer"
              >
                {descLinkItem.mainUrlText}
              </a>
            ),
          )}
        </p>
      )}
      {documentsListItem.notes?.map((noteItem) => {
        return (
          <SRNoteComp
            note={noteItem.note}
            details={noteItem.details}
            key={noteItem.note}
          ></SRNoteComp>
        );
      })}
      {documentsListItem.documents?.map((docItem) => {
        return (
          <SRDocumentComp
            type={docItem.type}
            links={docItem.links}
            key={docItem.links}
          ></SRDocumentComp>
        );
      })}
    </>
  );
};

export default SRDocumentsListItemComp;
