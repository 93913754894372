import { createAction } from "@reduxjs/toolkit";
import { getTranslations } from "../services";

// Action Types
export const fetchTranslationsRequest = createAction(
  "translations/fetchRequest",
);
export const fetchTranslationsSuccess = createAction(
  "translations/fetchSuccess",
);
export const fetchTranslationsFailure = createAction(
  "translations/fetchFailure",
);

// Thunk Action
export const fetchTranslations = (langCode) => async (dispatch) => {
  dispatch(fetchTranslationsRequest());
  try {
    const response = await getTranslations(langCode);
    const { data } = response;
    dispatch(fetchTranslationsSuccess(data));
  } catch (error) {
    dispatch(fetchTranslationsFailure(error));
  }
};
