import { fetchData } from "./base-service";

const STORE_API_ENDPOINTS = {
  storeInnerPage: (langCode, page) =>
    `/v1/store/purchase-options/${langCode}/${page}?uic=${langCode}`,
  storePage: (langCode) => `/v1/store?uic=${langCode}`,
};

export const getStoreInnerPageData = async (page, langCode) =>
  fetchData(STORE_API_ENDPOINTS.storeInnerPage(langCode, page));

export const getLanguages = (products) => {
  const languageSet = new Set();

  return products
    .flatMap((product) => product.languages)
    .filter((language) => {
      if (languageSet.has(language.name)) {
        return false;
      } else {
        languageSet.add(language.name);
        return true;
      }
    })
    .map((language) => ({
      value: language.name,
      label: language.value,
      type: "option",
    }));
};

export const getStoreCategories = ({ id, title, tagline, description }) => {
  return [
    {
      id,
      title,
      tagline,
      description,
    },
  ];
};

export const getStoreOfferings = async (langCode) =>
  fetchData(STORE_API_ENDPOINTS.storePage(langCode));
