import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import moment from "moment";
import { Views } from "react-big-calendar";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PopoverWrapper from "./PopoverWrapper";
import Translation from "@common/Translation/Index";
import Heatmap from "./HeatMap";

const CalendarToolbar = ({
  handleButtonNav,
  isPopoverOpen,
  togglePopover,
  handleViewSwitchClick,
  handlePickerSelect,
  momentLocaleFix,
  pickerDate,
  viewChoice,
  eventMin,
  eventMax,
  selectedModelingOption,
  modelingOptions,
  handleModelingChange,
}) => {
  let currentDateTimeLabel;

  if (viewChoice === "day") {
    currentDateTimeLabel = moment(pickerDate).format("MMMM DD, YYYY");
  } else if (viewChoice === "month") {
    currentDateTimeLabel = moment(pickerDate).format("MMMM YYYY");
  }
  return (
    <div className="cal-toolbar margin-bottom-md">
      <div className="row">
        <div className="col-lg-8 date-controls">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-btn-wrapper">
                <ButtonGroup className="form-btn-group margin-right-1">
                  <Button
                    onClick={() => handleButtonNav("prev")}
                    className="form-btn back-forward-btn"
                  >
                    &lsaquo;
                  </Button>
                  <Button
                    onClick={() => handleButtonNav("next")}
                    className="form-btn back-forward-btn"
                  >
                    &rsaquo;
                  </Button>
                  <PopoverWrapper
                    isPopoverOpen={isPopoverOpen}
                    togglePopover={togglePopover}
                    content={
                      <DatePicker
                        inline
                        onSelect={(date) => handlePickerSelect(date)}
                        locale={momentLocaleFix}
                        selected={pickerDate}
                      />
                    }
                  >
                    <div
                      className="current-datetime-label btn btn-default form-btn hidden-xs hidden-sm"
                      onClick={() => togglePopover()}
                    >
                      {currentDateTimeLabel}
                    </div>
                  </PopoverWrapper>
                </ButtonGroup>
                <ButtonGroup className="form-btn-group">
                  <Button
                    onClick={() => handleViewSwitchClick(Views.DAY)}
                    className={`form-btn ${
                      viewChoice === Views.DAY ? "active" : ""
                    }`}
                  >
                    <Translation translate="Day" textonly="true" />
                  </Button>
                  <Button
                    onClick={() => handleViewSwitchClick(Views.MONTH)}
                    className={`form-btn ${
                      viewChoice === Views.MONTH ? "active" : ""
                    }`}
                  >
                    <Translation translate="Month" textonly="true" />
                  </Button>
                </ButtonGroup>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="select-hold">
                <Select
                  value={selectedModelingOption}
                  onChange={handleModelingChange}
                  options={modelingOptions}
                  className="device-drop"
                  placeholder={
                    <Translation translate="Select" textonly="true" />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 heatmap-key">
          <Heatmap eventMin={eventMin} eventMax={eventMax} />
        </div>
      </div>
    </div>
  );
};

export default CalendarToolbar;
