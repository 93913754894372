import React from "react";
import RawHTML from "@common/RawHTML/Index";
import SystemDownloadButton from "./Common/SystemDownloadButton";

const FreePathways = ({
  freePathways,
  translations,
  selectedPathwaysLanguageUrl,
  selectedPathwaysLanguage,
  handleUrlSelection,
  handleButtonClick,
  pageKey,
}) => (
  <div id="browse" className="container-full">
    <div className="container text-center">
      {pageKey !== "EarlyIntervention" && pageKey !== "SnapScene" && (
        <>
          <div className="row">
            <div className="col-sm-8 col-sm-push-2">
              <h2>{freePathways.title}</h2>
              <RawHTML htmlContent={freePathways.description} />
            </div>
          </div>
          <div className="row jump-links">
            {freePathways.freePathwaysApps.applications.map((app, index) => (
              <div className="col-sm-6 col-lg-3" key={index}>
                <div className="well-product-link">
                  <div className="match_desc">
                    <div className="hidden-xs icon icon-download icon-68"></div>
                    <h3>{app.title}</h3>
                    <p>{app.description}</p>
                  </div>
                  <div className="match_cta pricing">
                    <hr />
                    {app.type === "CategoryUrls" &&
                      app.apps.map((download, i) => (
                        <SystemDownloadButton
                          key={i}
                          url={download.links}
                          system={download.system}
                          translations={translations}
                        />
                      ))}
                    {app.type === "LiteralUrls" && app.urls.length > 1 && (
                      <>
                        <div
                          className="download-group dropdown dropdown-lg dropdown-col"
                          style={{
                            padding: "14px 0 0",
                            margin: "0 auto",
                            width: "169px",
                          }}
                        >
                          <a
                            id="installer-text"
                            className="dropdown-toggle btn btn-gradient"
                            data-toggle="dropdown"
                            href=""
                            style={{ fontSize: "1em", lineHeight: "26px" }}
                          >
                            <span>{selectedPathwaysLanguage}</span>{" "}
                            <b className="caret"></b>
                          </a>
                          <ul
                            id="dropdown-installer-text"
                            className="dropdown-menu"
                            style={{ marginLeft: "0", textAlign: "left" }}
                          >
                            {app.urls.map((url, i) => (
                              <li key={i}>
                                <a
                                  data-url={url.url}
                                  onClick={() =>
                                    handleUrlSelection(url.url, url.language)
                                  }
                                  style={{ fontSize: "1em" }}
                                >
                                  {url.language}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div
                          className="download-group-button"
                          style={{ marginTop: "14px" }}
                        >
                          <a
                            href="#"
                            id="pathwaysOnline"
                            className={`btn ${selectedPathwaysLanguageUrl ? "btn-primary" : "btn-inactive"} btn-lg`}
                            style={{ margin: "0", width: "169px" }}
                            onClick={handleButtonClick}
                          >
                            {translations.Open}
                          </a>
                        </div>
                      </>
                    )}
                    {app.type === "LiteralUrls" && app.urls.length === 1 && (
                      <a
                        href={app.urls[0].url}
                        target="_blank"
                        className="btn btn-lg btn-primary"
                        style={{ width: "169px" }}
                        rel="noreferrer"
                      >
                        {app.urls[0].text}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {(pageKey === "EarlyIntervention" || pageKey === "SnapScene") && (
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <h2>{`${translations.Get_Product.replace("{0}", translations.Pathways)} - ${translations.FREE_Upper}`}</h2>
            <RawHTML htmlContent={freePathways.description} />

            <a
              href={freePathways.freePathwaysApps.applications[0].apps[0].links}
              target="_blank"
              className="btn btn-lg btn-apple"
              rel="noreferrer"
            >
              <img
                src="/assets/images/btn/badge-download-on-the-app-store.svg"
                alt={translations.Download_on_the_App_Store}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default FreePathways;
