import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  setInitialLangFilter,
  pushInitialLangFilter,
  applyFilter,
} from "../../../helpers/utilities";
import { getStoreOfferings, getStoreCategories, getLanguages } from "@services";
import Breadcrumb from "@common/Breadcrumb";

const StoreHome = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [storeData, setStoreData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStoreOfferings(languageCode);
      setStoreData(data[0]);
    };
    fetchData();
  }, [languageCode]);

  useEffect(() => {
    if (!storeData || Object.keys(storeData).length === 0) return;

    const storeOfferings = storeData?.products;
    const storeCategories = getStoreCategories({
      id: storeData?.categoryId,
      title: storeData?.categoryTitle,
      tagline: storeData?.categoryTagLine,
      description: storeData?.categoryDescription,
    });
    const supportedLangs = getLanguages(storeData?.products);
    const defaultLanguage = navigator.language;

    const initialLanguageFilter = setInitialLangFilter(defaultLanguage);

    const initFilterArray = pushInitialLangFilter(
      supportedLangs,
      initialLanguageFilter,
    );

    $(".sol-hold").hide();
    $(".no-products").hide();

    const prepareTiles = function () {
      $(".products").click(function () {
        document.location.href = $(this)
          .find(".get-software-link")
          .attr("href");
      });

      $(".part-numbers").matchHeight();
    };
    $("#lang-filter-select").searchableOptionList({
      showSelectionBelowList: true,
      showSelectAll: true,
      maxHeight: "250px",
      data: initFilterArray,
      events: {
        onChange: function (sol) {
          $(".full-loading").show();
          $("#filtered-content").hide();
          $(".no-products").hide();
          var selectedArray = sol.getSelection();
          var fixedArray = [];
          var filteredProductContent = "";
          $.each(selectedArray, function (key, value) {
            fixedArray.push(value.value);
          });
          filteredProductContent = applyFilter(
            fixedArray,
            storeOfferings,
            storeCategories,
          );
          setTimeout(function () {
            $(".full-loading").hide();
            if (!filteredProductContent) {
              $(".no-products").show();
            } else {
              $("#filtered-content").show();
              $("#filtered-content").html(filteredProductContent);
              prepareTiles();
            }
          }, 750);
        },
        onInitialized: function (sol) {
          $(".sol-hold").show();
          $(".no-products").hide();
          var selectedArray = sol.getSelection();
          var fixedArray = [];
          var filteredProductContent = "";
          $.each(selectedArray, function (key, value) {
            fixedArray.push(value.value);
          });
          filteredProductContent = applyFilter(
            fixedArray,
            storeOfferings,
            storeCategories,
          );
          setTimeout(function () {
            $(".full-loading").hide();
            if (!filteredProductContent) {
              $(".no-products").show();
            } else {
              $("#filtered-content").show();
              $("#filtered-content").html(filteredProductContent);
              prepareTiles();
            }
          }, 750);
        },
        onScroll: function () {
          var posY =
              this.$input.offset().top -
              this.config.scrollTarget.scrollTop() +
              this.$input.outerHeight(),
            selectionContainerWidth =
              this.$innerContainer.outerWidth(false) -
              parseInt(this.$selectionContainer.css("border-left-width"), 10) -
              parseInt(this.$selectionContainer.css("border-right-width"), 10);

          if (this.$innerContainer.css("display") !== "block") {
            selectionContainerWidth = Math.ceil(selectionContainerWidth * 1.2);
          } else {
            this.$selectionContainer.css("border-top-right-radius", "initial");

            if (this.$actionButtons) {
              this.$actionButtons.css("border-top-right-radius", "initial");
            }
          }

          this.$selectionContainer
            .css("top", Math.floor(posY))
            .css("left", Math.floor(this.$container.offset().left))
            .css("width", selectionContainerWidth);
        },
      },
      texts: {
        searchplaceholder: translations.Show_more_langs,
        selectAll: translations.Select_all,
        selectNone: translations.Select_none,
      },
    });
  }, [languageCode, translations, storeData]);

  return (
    <>
      {translations && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{translations.Store}</h1>
              <Breadcrumb items={[{ label: translations.Store }]} />
            </div>
          </div>
          <div className="container sol-hold">
            <div className="row margin-top-lg">
              <div className="col-xs-12 sol-wrapper">
                <select
                  id="lang-filter-select"
                  name="langfilter"
                  multiple
                ></select>
              </div>
            </div>
          </div>
          <div className="container no-products fade-block fade-in">
            <div className="row margin-top-lg">
              <div className="col-xs-12">{translations.No_products}</div>
            </div>
          </div>

          <div className="container full-loading fade-block fade-in">
            <div className="row margin-top-lg">
              <div className="col-xs-12">{translations.MYTD_loading_txt}</div>
            </div>
          </div>
          <div id="filtered-content"></div>
        </>
      )}
    </>
  );
};

export default StoreHome;
