import SRLinkComp from "./SRLinkComp";
import React from "react";

const SRDocumentComp = ({ type, links }) => {
  if (type === "Videos")
    return (
      <>
        <h4 id="new-topics-videos">{type}</h4>
        <div className="row">
          {links.map((linkItem) => {
            return (
              <SRLinkComp
                linkItem={linkItem}
                type={type}
                key={linkItem.mainUrl}
              ></SRLinkComp>
            );
          })}
        </div>
      </>
    );
  else if (type === "Guides") {
    return (
      <div className="panel-group">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 id="store-migration-guides">{type}</h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            {links.map((linkItem) => {
              return (
                <SRLinkComp
                  linkItem={linkItem}
                  type={type}
                  key={linkItem.mainUrl}
                ></SRLinkComp>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="row">
          {links.map((linkItem) => {
            return (
              <SRLinkComp
                linkItem={linkItem}
                type={null}
                key={linkItem.mainUrl}
              ></SRLinkComp>
            );
          })}
        </div>
      </>
    );
  }
};

export default SRDocumentComp;
