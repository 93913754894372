import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { cultureService } from "@services";
import { setLanguage } from "../../../../actions";

const useLanguageData = () => {
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState({});

  useEffect(() => {
    const data = cultureService.getCultureOptions();
    setLanguageData(data);
    if (data) {
      dispatch(
        setLanguage({
          languageCode: data.LanguageCode,
          languageName: data.LanguageName,
        }),
      );
    }
  }, [dispatch]);

  const handleLanguageChange = useCallback(
    (languageCode, languageName) => {
      dispatch(setLanguage({ languageCode, languageName }));
      cultureService.setCulture(languageCode);
    },
    [dispatch],
  );

  return { languageData, handleLanguageChange };
};

export default useLanguageData;
