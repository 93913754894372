import React, { useState } from "react";
import Translation from "@common/Translation/Index";
import DetachButton from "./DetachButton";
import DetachModal from "./DetachModal";

const DeviceDetails = ({ device, product, languageCode, detach }) => {
  const [showDetachModal, setShowDetachModal] = useState(false);

  return (
    <div>
      <p className="license-key">
        {product === "snap" ? (
          <>
            <span>
              {device.softwareTitle || ""}{" "}
              <Translation translate="MYTD_forTxt" textonly="true" />{" "}
              {device.deviceTitle}
            </span>
          </>
        ) : (
          <span>{device.serialNumber}</span>
        )}

        {device.companionLicenseKey && (
          <span
            title={
              <Translation translate="MYTD_licenseNumLbl" textonly="true" />
            }
          >
            {device.companionLicenseKey}
          </span>
        )}

        <br />
        <span className="hidden-xs hidden-sm">
          <Translation translate="MYTD_AddedLbl" textonly="true" />{" "}
          {new Date(device.dateAttached).toLocaleDateString(languageCode)}
        </span>

        {device.detachLink && (
          <DetachButton
            onClick={() => setShowDetachModal(true)}
            translationKey="MYTD_detachLbl"
          />
        )}
        {product === "compass" && !device.detachLink && (
          <DetachButton
            onClick={() => setShowDetachModal(true)}
            translationKey="MYTD_deactivateLbl"
          />
        )}
        {product === "snap" && (
          <DetachButton
            onClick={() => setShowDetachModal(true)}
            translationKey="MYTD_detachLbl"
          />
        )}
      </p>
      <DetachModal
        device={device}
        showModal={showDetachModal}
        setShowModal={setShowDetachModal}
        detach={detach}
      />
    </div>
  );
};

export default DeviceDetails;
