import React from "react";
import SystemDownloadButton from "./Common/SystemDownloadButton";

const OneTimePurchases = ({ oneTimePurchases, translations }) => (
  <>
    {oneTimePurchases.applications.map((app, index) => (
      <div className="container text-center" key={`${app.type}-${index}`}>
        <div className="row">
          <div className="col-sm-8 col-sm-push-2">
            <h2>{app.type}</h2>
            <p>{app.description}</p>
            <p className="note">{oneTimePurchases.headerNote}</p>
          </div>
        </div>
        <div className="row jump-links">
          <div className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4">
            <div className="well-product-link">
              <div className="match_desc">
                <div className="hidden-xs icon icon-store icon-68"></div>
                <h3>{app.apps[0].title}</h3>
                <p>{app.apps[0].description}</p>
              </div>
              <div className="match_cta pricing">
                <hr />
                {app.apps.some((d) => d.price !== null) && (
                  <>
                    <h3>{app.apps.find((app) => app.price !== null)?.price}</h3>
                    <h4>{translations.One_Time_Purchase}</h4>
                    {app.apps
                      .filter((d) => d.price !== null)
                      .map((download, index) => (
                        <SystemDownloadButton
                          key={index}
                          url={download.links}
                          system={download.system}
                          translations={translations}
                        />
                      ))}
                  </>
                )}
                <hr />
                {app.apps.some((d) => d.price === null) && (
                  <>
                    {app.apps
                      .filter((d) => d.price === null)
                      .map((download, index) => (
                        <div key={`${app.type}-${download.system}-${index}`}>
                          <SystemDownloadButton
                            url={download.links}
                            system={download.system}
                            translations={translations}
                          />
                          <div className="footnote">
                            {download.priceNoteFooter}
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {oneTimePurchases.footerNote && (
          <div className="row">
            <div className="footnote">
              <small>{oneTimePurchases.footerNote}</small>
            </div>
          </div>
        )}
      </div>
    ))}
  </>
);

export default OneTimePurchases;
