import React from "react";

const CheckboxGroup = ({ filters, onFilterChange, pagesetfilters }) => {
  const optionOne = pagesetfilters[0];
  const optionTwo = pagesetfilters[1];
  return (
    <>
      <span className="checkbox checkbox-link checkbox-inline custom">
        <input
          checked={filters.optionOneChecked}
          name={optionOne}
          id={optionOne}
          type="checkbox"
          onChange={(e) => onFilterChange("optionOneChecked", e.target.checked)}
        />
        <label htmlFor={optionOne}>{` ${optionOne}`}</label>
      </span>
      <span className="checkbox checkbox-link checkbox-inline custom">
        <input
          checked={filters.optionTwoChecked}
          name={optionTwo}
          id={optionTwo}
          type="checkbox"
          onChange={(e) => onFilterChange("optionTwoChecked", e.target.checked)}
        />
        <label htmlFor={optionTwo}>{` ${optionTwo}`}</label>
      </span>
    </>
  );
};

export default CheckboxGroup;
