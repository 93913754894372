import React from "react";
import Translation from "../../../common/Translation/Index";

const SubscriptionDetails = ({ subItem }) => (
  <div className="well well-white clearfix">
    <div className="pull-left">
      <strong>
        {subItem.subscriptionTypeId === 1 ? (
          <Translation translate="MYTD_trialLbl" />
        ) : subItem.subscriptionTerm === "Yearly" ? (
          <Translation translate="yearly" />
        ) : subItem.subscriptionTerm === "Monthly" ? (
          <Translation translate="Monthly_lbl" />
        ) : (
          subItem.subscriptionTerm
        )}
        <Translation translate="MYTD_subscriptionLbl" />
      </strong>
    </div>
    {subItem.subscriptionTypeId !== 1 && (
      <div className="pull-right">
        <Translation translate="MYTD_autoRenewLbl" />
        {subItem.isAutoRenew ? (
          <strong>
            <Translation translate="MYTD_yesTxt" />
          </strong>
        ) : (
          <strong>
            <Translation translate="MYTD_noTxt" />
          </strong>
        )}
      </div>
    )}
  </div>
);

export default SubscriptionDetails;
