import React from "react";
import { Link } from "react-router-dom";
import AddToMyStuffButton from "./AddToMyStuffButton";
/* 
Expects an array of objects with the href and label for each item
The first one that leads to home is always there so you don't have to pass it in
The last one doesn't have an href because it's the page you are currently on
Here is an example: 
[
    {
      label: "Downloads",
      href: '/support/downloads',
    },
    {
      label: pageData.subTitle,
    }
  ]

*/

const Breadcrumb = ({ items, path }) => {
  return (
    <div key="breadcrumb">
      <ol className="breadcrumb pull-left">
        <li key="home">
          <Link to="/">
            <span className="icon icon-home icon-21"></span>
          </Link>
        </li>
        {items &&
          items.map((item, index) => (
            <li key={`${item.label}_${index}_`}>
              {item.href ? (
                <Link to={item.href}>{item.label}</Link>
              ) : (
                <span
                  className="breadcrumb-current-page"
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              )}
            </li>
          ))}
        {path && <AddToMyStuffButton product={path}></AddToMyStuffButton>}
      </ol>
    </div>
  );
};

export default Breadcrumb;
