import React, { useEffect, useMemo } from "react";

const ImageGallery = ({ imagePaths }) => {
  const initializeLightSlider = useMemo(
    () => () => {
      $("#productGallery").lightSlider({
        gallery: true,
        item: 1,
        vertical: true,
        verticalHeight: 298,
        vThumbWidth: 83,
        thumbItem: 5,
        thumbMargin: 3,
        slideMargin: 0,
        loop: true,
        auto: true,
        speed: 550,
        pause: 3000,
        pauseOnHover: true,
        controls: false,
      });
    },
    [],
  );

  useEffect(() => {
    initializeLightSlider();
  }, []);

  return (
    <div className="well well-product">
      <ul id="productGallery" className="product-slider">
        {imagePaths.map((path, index) => (
          <li key={index} data-thumb={`/assets/images${path}`}>
            <img
              src={`/assets/images${path}`}
              alt=""
              className="center-block"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageGallery;
