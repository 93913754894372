import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const StorePage = () => {
  useEffect(() => {
    $(".match_desc").matchHeight();
    $(".match_cta").matchHeight();
  }, []);

  return (
    <div className="product-details">
      <Outlet />
    </div>
  );
};

export default StorePage;
