import { fetchData, postData } from "./base-service";

const USER_API_ENDPOINTS = {
  userProfile: (langCode) => `/v1/user/profile?uic=${langCode}`,
  myStuff: (langCode) => `/v1/products/my-stuff?uic=${langCode}`,
  moreStuff: (langCode) => `/v1/products/more-stuff?uic=${langCode}`,
  notificationCount: (langCode) =>
    `/v1/notifications/active-count?uic=${langCode}`,
  checkData: (langCode) => `/v1/user/has-usage-data?uic=${langCode}`,
  userDevices: () => `/v1/user/user-devices`,
  usageData: () => `/v1/user/usage-data`,
  exportUsageData: () => `/v1/user/export-usage-data`,
};

export const checkForData = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.checkData(langCode));

export const getUserDevices = async () =>
  fetchData(USER_API_ENDPOINTS.userDevices());

export const getUsageData = (payload) => {
  return postData(USER_API_ENDPOINTS.usageData(), payload);
}

export const exportUsageData = (payload) => {
  return postData(USER_API_ENDPOINTS.exportUsageData(), payload);
}

export const getUserProfile = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.userProfile(langCode));

export const getMyStuff = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.myStuff(langCode));

export const getMoreStuff = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.moreStuff(langCode));

export const getUserNotificationCount = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.notificationCount(langCode));

export const getUserAvatar = () => {
  return Promise.resolve({ data: { avatarUrl: "" } }); // Resolve with a placeholder avatar URL
};

export const checkBMODistCode = async () => {
  return {
    message:
      "Code not found, please contact your organization's administration.",
    code: 1,
    isValid: false,
    hasToken: false,
    expiration: null,
  };
};

export const deleteBMODistCode = () => {
  return Promise.resolve({ data: { success: true } });
};

export const resetBmoDistCode = () => {
  return Promise.resolve({ data: { success: true } });
};

export const editProfile = (userData) => {
  return Promise.resolve({ data: { success: true, user: userData } });
};

export const deleteAllData = () => {
  return Promise.resolve({ data: { success: true } });
};
