import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteDataModal from "./DeleteDataModal";
import DataStatusMessage from "./DataStatusMessage";
import Translation from "@common/Translation/Index";
import { checkForData } from "@services/user-service";
import { deleteUsageData } from "../my-account-service";

const UsageReports = () => {
  const dispatch = useDispatch();
  const changesSaved = useSelector((state) => state.translations.Changes_saved);
  const { languageCode } = useSelector((state) => state.languageData);

  const [showModal, setShowModal] = useState(false);
  const [dataStatus, setDataStatus] = useState({
    hasData: false,
    isChecking: true,
    error: null,
  });

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const checkDataStatus = useCallback(async (isAfterDelete = false) => {
    setDataStatus((prev) => ({ ...prev, isChecking: true, error: null }));
    try {
      const res = await checkForData(languageCode);
      setDataStatus({
        hasData: res.hasData,
        isChecking: false,
        error: null,
      });
    } catch (error) {
      setDataStatus({
        hasData: false,
        isChecking: false,
        error: `${error.response?.status}: ${error.response?.statusText}`,
      });
    }
  }, []);

  const handleDeleteData = async () => {
    toggleModal();
    setDataStatus((prev) => ({ ...prev, isChecking: true, error: null }));

    try {
      await deleteUsageData(languageCode);
      await checkDataStatus(true);
    } catch (error) {
      setDataStatus((prev) => ({
        ...prev,
        isChecking: false,
        error: `${error.response?.status}: ${error.response?.statusText}`,
      }));
    }
  };

  useEffect(() => {
    if (languageCode) {
      checkDataStatus();
    }
  }, [checkDataStatus, languageCode]);

  return (
    <>
      <DataStatusMessage
        isChecking={dataStatus.isChecking}
        error={dataStatus.error}
      />
      <div className="container fade-block fade-in usage-block">
        <h2>
          <Translation translate="Manage_Data" textonly="true" />
        </h2>
        {dataStatus.hasData ? (
          <>
            <p>
              <Translation translate="Delete_usage_data_gathered_to_date" />
            </p>
            <div className="margin-top-md">
              <button className="btn btn-lg btn-primary" onClick={toggleModal}>
                <Translation translate="Delete_All_Data" textonly="true" />
              </button>
            </div>
          </>
        ) : (
          <Translation translate="No_data_collected" />
        )}
      </div>
      <DeleteDataModal
        show={showModal}
        onClose={toggleModal}
        onConfirm={handleDeleteData}
      />
    </>
  );
};

export default UsageReports;
