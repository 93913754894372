import React from "react";
import Translation from "@common/Translation/Index";
const LicenseCell = ({ item, translations }) => {
  return (
    <tr>
      <td>{item.productName}</td>
      <td>
        <div>
          <p>
            {item.licenseKey ? (
              item.licenseKey
            ) : item.claimedByDevice ? (
              translations.MYTD_claimedByLbl + " " + item.claimedByDevice
            ) : (
              <Translation translate="MYTD_availableLbl" />
            )}
          </p>
        </div>
        <div>
          <h6>
            {item.issuedFromDevice ? (
              <>
                <Translation translate="MYTD_issuedFromLbl" />{" "}
                {item.issuedFromDevice}
              </>
            ) : (
              ""
            )}
          </h6>
        </div>
      </td>
    </tr>
  );
};

export default LicenseCell;
