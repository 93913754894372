import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment-timezone";

export const localizer = momentLocalizer(moment);

const formatDateLabel = (date, culture, localizer, timezone) => {
  return localizer.format(date, "dddd, MMMM D, YYYY", culture) + timezone;
};

const zone_name = moment.tz.guess();
const timezone = ` (${moment.tz(zone_name).format("Z z")})`;
export const formats = {
  dayFormat: (date, culture, localizer) =>
    formatDateLabel(date, culture, localizer, timezone),
  weekdayFormat: "dd",
  dayRangeHeaderFormat: ({ start, end }, culture, local) =>
    `${local.format(start, "M/D/YYYY", culture)} — ${local.format(
      end,
      "M/D/YYYY",
      culture,
    )}`,
};

export const getHexValue = (color2, color1, ratio) => {
  const hex = function (x) {
    x = x.toString(16);
    return x.length == 1 ? "0" + x : x;
  };

  const r = Math.ceil(
    parseInt(color1.substring(0, 2), 16) * ratio +
      parseInt(color2.substring(0, 2), 16) * (1 - ratio),
  );
  const g = Math.ceil(
    parseInt(color1.substring(2, 4), 16) * ratio +
      parseInt(color2.substring(2, 4), 16) * (1 - ratio),
  );
  const b = Math.ceil(
    parseInt(color1.substring(4, 6), 16) * ratio +
      parseInt(color2.substring(4, 6), 16) * (1 - ratio),
  );

  const newHex = hex(r) + hex(g) + hex(b);

  return newHex;
};

export const addHexToArray = (bigEvents, type) => {
  const usedWords = bigEvents?.map((obj) => obj.count);
  let useMin = Math.min.apply(Math, usedWords);
  const useMax = Math.max.apply(Math, usedWords);
  useMin = useMax === useMin ? 1 : useMin;
  const getRatio = (givenCount) => {
    let ratio = (givenCount - useMin) / (useMax - useMin);
    ratio = Number.isNaN(ratio) ? 1 : ratio;
    return ratio;
  };
  const eventsWithHex = bigEvents?.map((obj) => obj);
  eventsWithHex.forEach((obj) => {
    const holdRatio = getRatio(obj.count).toFixed(2);
    obj.hex = getHexValue("ACE9A6", "008759", holdRatio);
    obj.start =
      type === "month"
        ? new Date(moment(obj.date).format("M/D/YYYY"))
        : new Date(moment(obj.date).format("M/D/YYYY HH:mm"));
    obj.end =
      type === "month"
        ? new Date(moment(obj.start).format("M/D/YYYY"))
        : new Date(
            moment(obj.start).add(14, "minutes").format("M/D/YYYY HH:mm"),
          );
    obj.ratio = holdRatio;
    obj.words = obj.count;
  });
  let formattedEvents = {
    eventsWithHex: eventsWithHex,
    min: useMin,
    max: useMax
  }
  return formattedEvents;
};

export const getTimePeriod = (dateChoice, viewChoice) => {
  let start, end;

  if (viewChoice === "month") {
    start = moment(dateChoice).startOf("month");
    end = moment(dateChoice).endOf("month");
  } else if (viewChoice === "day") {
    start = moment(dateChoice).startOf("day");
    end = moment(dateChoice).endOf("day");
  } else {
    throw new Error(`Invalid viewChoice: ${viewChoice}`);
  }

  return { start, end };
};
