import { fetchData, deleteData } from "@services/base-service";

const MY_ACCOUNT_API_ENDPOINTS = {
  userLicenseKeys: (langCode) => `/v1/licenses/license-keys/?uic=${langCode}`,
  userLicensePremium: (langCode) =>
    `/v1/licenses/premium-content/?uic=${langCode}`,
  userSubscriptions: (langCode) => `/v1/subscriptions?uic=${langCode}`,
  cancelSubscriptions: (langCode, id, product) =>
    `/v1/subscriptions/${product}/${id}?uic=${langCode}`,
  userOrders: (langCode) => `/v1/orders?uic=${langCode}`,
  deleteUsageData: (langCode) => `/v1/user/usage-data?uic=${langCode}`,
};

export const getUserOrders = async (langCode) =>
  fetchData(MY_ACCOUNT_API_ENDPOINTS.userOrders(langCode));
export const getUserLicenseKeys = async (langCode) =>
  fetchData(MY_ACCOUNT_API_ENDPOINTS.userLicenseKeys(langCode));
export const getLicensePremium = async (langCode) =>
  fetchData(MY_ACCOUNT_API_ENDPOINTS.userLicensePremium(langCode));
export const getUserSubscriptions = async (langCode) =>
  fetchData(MY_ACCOUNT_API_ENDPOINTS.userSubscriptions(langCode));
export const cancelSubscription = async (langCode, id, product) =>
  deleteData(
    MY_ACCOUNT_API_ENDPOINTS.cancelSubscriptions(langCode, id, product),
  );
export const deleteUsageData = async (langCode) =>
  deleteData(MY_ACCOUNT_API_ENDPOINTS.deleteUsageData(langCode));
