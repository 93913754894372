import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProductsOverview } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import { pathByName } from "../../../helpers/utilities";

const ProductsOverview = ({ pageKey, path }) => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [productOverviewData, setProductOverviewData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProductsOverview(languageCode);
      const product = data.products.find((product) => product.name === pageKey);
      setProductOverviewData(product);
    };
    fetchData();
  }, [languageCode]);

  return (
    productOverviewData && (
      <>
        <div className="container-full">
          <div className="container myStuff-header">
            <h1>{productOverviewData.title}</h1>
            <Breadcrumb
              items={[{ label: productOverviewData.title }]}
              path={path}
            />
          </div>
        </div>
        <div className="container-full moreStuff">
          <div className="container">
            <div className="row" style={{ marginBottom: "28px" }}>
              <div className="col-xs-12">
                <div className="more-info-container">
                  <div className="row no-gutters">
                    <div className="col-md-push-6 col-md-6 match">
                      <img
                        src={`/assets/images${productOverviewData.image}`}
                        className="img-responsive"
                        alt={`Hero image ${productOverviewData.title}`}
                      />
                    </div>
                    <div
                      className="col-md-pull-5 col-md-4 center-vertically-wrapper more-info-content match fade-block fade-in"
                      style={{ height: "377px" }}
                    >
                      <div className="center-vertically fade-block fade-in">
                        <h2>{productOverviewData.intro}</h2>
                        <h5>{productOverviewData.description}</h5>
                        <h5>
                          <Link
                            to={productOverviewData.moreInfo?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            dangerouslySetInnerHTML={{
                              __html: productOverviewData.moreInfo?.text,
                            }}
                          />
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-12 col-md-6 match"
                style={{ height: "355px" }}
              >
                <div className="get-it-container center-vertically-wrapper">
                  <div className="row no-gutters center-vertically">
                    <div className="col-md-offset-2 col-md-8 get-it-content">
                      <h2>{productOverviewData.purchase?.title}</h2>
                      <h5
                        dangerouslySetInnerHTML={{
                          __html: productOverviewData.purchase?.description,
                        }}
                      ></h5>
                      {productOverviewData.purchase?.options.map(({ text }) => {
                        return (
                          <Link
                            to={`/Store/${pathByName(
                              productOverviewData.name,
                              "store",
                            )}`}
                            className="btn btn-lg btn-white"
                            style={{ marginRight: "5px" }}
                            key={text}
                          >
                            {text}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-6 col-md-3 match"
                style={{ height: "355px" }}
              >
                <div className="myStuff-panel">
                  <div className="myStuff-panel-top">
                    <h4>{translations.Downloads}</h4>
                    <br />
                    <div>
                      <div className="icon-download icon icon-56"></div>
                    </div>
                  </div>
                  <div className="myStuff-panel-btm">
                    <div className="btn-link-list">
                      <Link
                        to={`/Support/${pathByName(
                          productOverviewData.name,
                          "download",
                        )}`}
                        className="btn-link link-download"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {productOverviewData.title}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-6 col-md-3 match"
                style={{ height: "355px" }}
              >
                <div className="myStuff-panel">
                  <div className="myStuff-panel-top">
                    <h4>{translations.Documentation}</h4>
                    <br />
                    <div>
                      <div className="icon-resources icon icon-56"></div>
                    </div>
                  </div>
                  <div className="myStuff-panel-btm">
                    <div className="btn-link-list">
                      <Link
                        to={`/Support/Documentation#${pathByName(
                          productOverviewData.name,
                          "documentation",
                        )}`}
                        className="btn-link link-documentation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {productOverviewData.title}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ProductsOverview;
