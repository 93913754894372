import React from "react";
import Translation from "@common/Translation/Index";

const WarningMessage = ({ product }) => {
  const renderWarningMessage = () => {
    if (product === "compass") {
      return <Translation translate="MYTD_CompassEOS_Warning" />;
    } else if (product === "symbolmate") {
      return <Translation translate="Product_no_longer_supported" />;
    }
    return null;
  };

  {
    return (
      (product === "compass" || product === "symbolmate") && (
        <div className="product-warning">
          <div className="row">
            <div className="col-xs-12">{renderWarningMessage()}</div>
          </div>
        </div>
      )
    );
  }
};

export default WarningMessage;
