import React from "react";

const getProgressStyle = (status) => status === "inProgress" && "active";

const getProgressBarStyle = (status) => {
  switch (status) {
    case "inProgress":
      return "progress-bar-info";
    case "failed":
      return "progress-bar-danger";
    default:
      return "";
  }
};

const UploadProgress = ({ status, progress }) => (
  <div className="form-group upload-progress">
    <div className={`progress progress-striped ${getProgressStyle(status)}`}>
      <div
        role="progressbar"
        className={`progress-bar ${getProgressBarStyle(status)}`}
        style={{ width: `${progress}%` }}
      >
        {`${progress}%`}
      </div>
    </div>
  </div>
);

export default UploadProgress;
