import "../styles/Pageset.css";
import React from "react";
import { Outlet } from "react-router-dom";

const PagesetCentralPage = () => {
  return (
    <div className="psc-details">
      <Outlet />
    </div>
  );
};

export default PagesetCentralPage;
