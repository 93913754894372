import React from "react";
import RawHTML from "@common/RawHTML/Index";

const Professionals = ({ professionals, translations }) => (
  <div className="container text-center">
    <div className="row">
      <div className="col-sm-8 col-sm-push-2">
        <h2>{professionals.title}</h2>
        <RawHTML htmlContent={professionals.description} />
        <a
          href="/Subscription/VerifyProfessional"
          className="btn btn-lg btn-primary"
        >
          {translations.Verify_Membership}
        </a>
      </div>
    </div>
  </div>
);

export default Professionals;
