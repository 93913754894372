import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TrialText = ({
  text,
  trialPeriodText,
  trialPeriod,
  trialPeriodEndedText,
  trialDaysRemainingText,
  trialLink,
}) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [token, setToken] = useState(null);
  const formattedTrialString = trialPeriodText.replace("{0}", trialPeriod);
  const formattedTrialEndedString = trialPeriodEndedText.replace(
    "{0}",
    trialPeriod,
  );

  const isTokenExpired = () => {
    if (token && token.ExpirationDate <= new Date()) {
      return true;
    }
    return false;
  };

  const getTrailLink = () => {
    if (isAuthenticated && token !== null) {
      if (isTokenExpired()) {
        return <p>{formattedTrialEndedString}</p>;
      }

      const daysRemaining = (token.ExpirationDate - new Date()).Days;
      return (
        <Link to={trialLink}>
          {trialDaysRemainingText} - {daysRemaining}
        </Link>
      );
    } else {
      return <Link to={trialLink}>{formattedTrialString}</Link>;
    }
  };

  return (
    <>
      <small>
        {text}
        <br />
        {getTrailLink()}
      </small>
    </>
  );
};

export default TrialText;
