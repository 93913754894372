import React from "react";

const ProductNotesList = ({ productNotes }) => (
  <>
    {productNotes.map((item, index) => (
      <p className="note" key={`${item}-${index}`} data-testid="note">
        {item}
      </p>
    ))}
  </>
);

export default ProductNotesList;
