import React from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation/Index";

const DeleteDataModal = ({ show, onClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={onClose} className={show ? "in" : ""}>
      <Modal.Header closeButton>
        <Modal.Title as="h2">
          <Translation translate="Delete_Data" textonly="true" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="Delete_All_Data_desc" textonly="true" />
          <b>
            <i>
              <Translation
                translate="This_operation_cannot_be_undone"
                textonly="true"
              />
            </i>
          </b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-lg btn-default">
          <Translation translate="MYTD_cancelBtnTxt" textonly="true" />
        </button>
        <button
          aria-label="Close"
          onClick={onConfirm}
          className="btn btn-lg btn-primary"
        >
          <Translation translate="MYTD_deleteLbl" textonly="true" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteDataModal;
