import React from "react";
import RawHTML from "@common/RawHTML/Index";

const OneTimeSinglePurchase = ({ oneTimeSinglePurchase, translations }) => (
  <>
    {oneTimeSinglePurchase.applications.map((app, index) => (
      <div id="shopNow" className="container-full" key={`${app.type}-${index}`}>
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-8 col-sm-push-2">
              <h2>{app.type}</h2>
              <RawHTML htmlContent={app.description} />
            </div>
          </div>
          <div className="row jump-links">
            {app.apps.map((insideApp, index) => (
              <div
                className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4"
                key={index}
              >
                <div className="well-product-link">
                  <div className="match_desc">
                    <div className="hidden-xs icon icon-store icon-68"></div>
                    <h3>{insideApp.title}</h3>
                    <p>{insideApp.description}</p>
                  </div>
                  <div className="match_cta pricing">
                    <hr />
                    <h3>{insideApp.price}</h3>
                    <h4>{insideApp.priceNoteHeader}</h4>
                    <a
                      href="/buy-now/communicator5full"
                      target="_blank"
                      className="btn btn-lg btn-primary"
                    >
                      {translations.Buy_Now}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ))}
  </>
);

export default OneTimeSinglePurchase;
