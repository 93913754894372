import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation/Index";

const emailRegex =
  /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailMessaging = {
  invalidEmail: <Translation translate="MYTD_emailFormatIncorrectErr" />,
  emailNotFound: <Translation translate="MYTD_emailNotFoundErr" />,
  emailAlreadyInList: <Translation translate="MYTD_emailAlreadyUsedErr" />,
};

const ShareModal = ({ showModal, setShowModal, item, translations }) => {
  const closeModal = () => setShowModal(false);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [error, setError] = useState("");

  const addEmail = (newEmail) => {
    // Check if email format is valid
    if (!emailRegex.test(email)) {
      setError(emailMessaging.invalidEmail);
      return;
    }
    // Check if the email is already in the list
    const isAlreadyInList = emailList.some(
      (item) => item.toLowerCase() === email.toLowerCase(),
    );
    if (isAlreadyInList) {
      setError(emailMessaging.emailAlreadyInList);
      return;
    }

    // Check if email exists
    const emailExists = true;
    // TODO:  if email exists (API call)
    if (!emailExists) {
      setError(emailMessaging.emailNotFound);
      return;
    }

    setEmailList((prevEmailList) => [...prevEmailList, newEmail]);
    setEmail("");
  };

  const removeEmail = (emailToRemove) => {
    setEmailList((prevEmailList) =>
      prevEmailList.filter((email) => email !== emailToRemove),
    );
  };

  const handleKeyPress = (event, email) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      addEmail(email);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
      data-testid="share-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <Translation translate="MYTD_shareTxt" textonly="true" />{" "}
          <Translation translate="Book" textonly="true" />{" "}
          <Translation translate="MYTD_copyText" textonly="true" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="copy-form">
            <p>
              <span>
                <Translation translate="MYTD_shareBook" textonly="true" />
              </span>
            </p>
            <div className="form-group">
              <label htmlFor="ShareEmail" className="control-label">
                <b>
                  <span>
                    <Translation translate="MYTD_shareTxt7" textonly="true" />
                  </span>
                </b>
              </label>
              <div className="input-group inherit-width">
                <input
                  type="text"
                  id="ShareEmail"
                  className="form-control"
                  placeholder={translations.Email_Input_Placeholder}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                />
                <span className="input-group-btn">
                  <button
                    className="btn btn-primary"
                    onClick={() => addEmail(email)}
                    onKeyDown={(event) => handleKeyPress(event, email)}
                  >
                    <Translation translate="MYTD_shareTxt8" textonly="true" />
                  </button>
                </span>
              </div>
              {error && (
                <div className="share-form-alert" style={{ color: "red" }}>
                  {error}
                </div>
              )}
              <div className="padding-top-none">
                {!!emailList.length && (
                  <>
                    <strong>
                      <Translation translate="MYTD_shareFileWith" />
                    </strong>
                    <div>
                      <ul className="list-unstyled row">
                        {emailList.map((e, i) => (
                          <li key={`email_${i}`} className="col-md-12">
                            {e} -{" "}
                            <a href="#" onClick={() => removeEmail(e)}>
                              <Translation translate="MYTD_removeLbl" />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            <button
              disabled={!emailList.length}
              className="btn btn-lg btn-primary"
              // TODO: handle share api call
              onClick={() => null}
            >
              <span>
                <Translation translate="Share" textonly="true" />
              </span>
            </button>
            &nbsp;
            <button
              className="btn btn-lg btn-default"
              onClick={() => closeModal()}
            >
              <span>
                <Translation translate="Cancel" textonly="true" />
              </span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
