import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Moment from "react-moment";
import Table from "@common/Table";
import Translation from "@common/Translation/Index";
import DeleteModal from "./Books/DeleteModal";

const Students = () => {
  const { data } = useOutletContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const cols = [
    <Translation translate="MYTD_backupDateLbl" key="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_studentNameLbl" key="MYTD_studentNameLbl" />,
    "",
  ];

  return (
    <div>
      <Table cols={cols}>
        {data.students.map((item, k) => {
          return (
            <tr key={k}>
              <td style={{ width: "125px" }}>
                <Moment format="l">{item.backupDateUtc}</Moment>
              </td>
              <td>{item.name}</td>
              <td>
                <span>
                  <a
                    href="#"
                    onClick={() => {
                      setActiveItem(item);
                      setShowDeleteModal(true);
                    }}
                  >
                    <Translation translate="MYTD_deleteLbl" />
                  </a>
                </span>
              </td>
            </tr>
          );
        })}
      </Table>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        name={activeItem.name}
      />
    </div>
  );
};

export default Students;
