import React from "react";
import { NavLink } from "react-router-dom";

const TabLink = (props) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) => (isActive ? "active" : "")}
    />
  );
};

export default TabLink;
