import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TileSet from "@common/Tile/TileSet";
import Translation from "@common/Translation/Index";
import { getMyStuff, getUserNotificationCount } from "@services";

const MyStuffHome = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);

  const [notificationsCount, setNotificationsCount] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const [showAvatar, setShowAvatar] = useState(true);
  const [showNotifications, setShowNotifications] = useState(true);
  const [myStuff, setMyStuff] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMyStuff(languageCode);
      setMyStuff(data);
    };
    fetchData();
  }, [languageCode]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserNotificationCount(languageCode);
      setNotificationsCount(data);
    };
    fetchData();
  }, [languageCode]);

  return (
    <div className="container mystuff-nav fade-block fade-in">
      <h2>
        <Translation translate="My_Stuff" textonly="true" />
      </h2>
      <TileSet
        notifications={notificationsCount}
        avatar={avatar}
        showAvatar={showAvatar}
        showNotifications={showNotifications}
        tilegroup={myStuff}
        translation={translations}
      />
    </div>
  );
};

export default MyStuffHome;
