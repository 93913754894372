import React from "react";

const PremiumLicenseCell = ({ premItem }) => {
  return (
    <tr>
      <td>{premItem.name}</td>
    </tr>
  );
};

export default PremiumLicenseCell;
