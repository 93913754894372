import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Translation from "@common/Translation/Index";
import {
  getImageSrc,
  determineImagePath,
  formatBytes,
  handleDownload,
} from "@helpers/utilities";
import { deletePagesetById, deleteBackupById } from "@services";
import DeleteModal from "./DeleteModal";
import toast from "react-hot-toast";

const PagesetBox = ({
  data,
  product,
  type,
  languageCode,
  col = 12,
  fetchPagesets,
}) => {
  const { translations } = useSelector((state) => state.translations);
  const imagePath = determineImagePath(data.imageUrl, data.software);
  const imageSource = getImageSrc(data.software, imagePath);
  const size = "sizeInBytes" in data ? data.sizeInBytes : data.fileSize;
  const created =
    "dateCreatedUtc" in data ? data.dateCreatedUtc : data.backupDateUtc;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isSyncPageset = (data) => {
    return data.tags?.includes("sync");
  };

  const showDelete = (data) => {
    if (!data.tags?.includes("Tobii DynaVox Pagesets")) {
      if (isSyncPageset(data)) {
        return data.isOwner;
      }
      return true;
    }
    return false;
  };

  const handleDelete = async () => {
    const isBackup = type === "backups";
    const deleteFunction = isBackup ? deleteBackupById : deletePagesetById;
    const successMessage = isBackup
      ? translations.Successfully_Deleted
      : translations.Pageset_successfully_deleted;

    try {
      await deleteFunction(data.id, product);
      await new Promise((resolve) => setTimeout(resolve, 200));
      await fetchPagesets();
      setShowDeleteModal(false);
      toast.success(successMessage);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <div className={`col-xs-${col} pageset-block-col fade-block fade-in`}>
      <div className="pageset-block well">
        {data.isOwner && (
          <div className="pageset-icon">
            <span className="icon icon-pageset-sync icon-34"></span>
          </div>
        )}
        <div className="row">
          <div className={"col-xs-12 col-md-4"}>
            <div className="well well-white myStuff-pageset-thumbnail-container">
              <img
                className="img-responsive pageset-thumbnail"
                src={imageSource}
                alt="asset thumbnail"
              />
            </div>
          </div>

          <div className={"col-xs-12 col-md-8"}>
            <h4>{data.title}</h4>
            <div className="clearfix">
              <span className="data pull-left">
                {new Date(created).toLocaleDateString(languageCode)}
              </span>
              {size && (
                <span className="data pull-left">{formatBytes(size)}</span>
              )}
              {data.tags?.includes("shared_sync") && (
                <span className="icon icon-pageset-share-sync icon-34 pull-left data-icon"></span>
              )}
              {data.isPublic && (
                <span className="icon icon-pageset-central icon-34 pull-left data-icon"></span>
              )}
            </div>
            {data.tags?.includes("sync") && !data.isOwner && (
              <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                <Translation translate="MYTD_OwnerLbl" /> -{" "}
                <strong>{data.owner}</strong>
              </h5>
            )}
            <div className="pageset-actions">
              <Link
                to={`/mystuff/${product}/${type}/details/${data.id}`}
                className="btn btn-link"
              >
                <Translation translate="Manage" textonly="true" />
              </Link>
              <a
                href="#"
                className="btn btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(data.id, data.software, type);
                }}
              >
                <Translation translate="MYTD_downloadLbl" />
              </a>

              {showDelete(data) && (
                <button
                  className="btn btn-link"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <Translation translate="MYTD_deleteLbl" textonly="true" />
                </button>
              )}
              <DeleteModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                name={data.title}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagesetBox;
