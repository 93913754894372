import React from "react";

const SRNoteComp = ({ note, details }) => {
  return (
    <p className="note">
      {note}
      {details.map((detailItem, index) => (
        <span key={index}>{detailItem}</span>
      ))}
    </p>
  );
};
export default SRNoteComp;
